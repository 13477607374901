/*
 * @Author: Elon-Ysuhan
 * @LastEditors: DESKTOP-ALIM4BJ
 * @description: 创建实体
 * @Date: 2022-05-05 17:01:20
 * @LastEditTime: 2023-03-22 20:52:09
 */
import CesiumInit from "./Cesium";
import { Lett } from "./lottie"

/**
 * Name	          Description
 * id	          此对象的唯一标识符。如果未提供，则将生成GUID。
 * name	          要显示给用户的可读名称。它不必是唯一的。
 * availability	  与此对象相关联的可用性（如果有）。
 * show	          一个布尔值，指示是否显示该实体及其子代。
 * description	  一个字符串属性，用于为此实体指定HTML描述。
 * position	      指定实体位置的属性。
 * orientation	  指定实体方向的属性。
 * viewFrom	      用于查看该对象的建议初始偏移量。
 * parent	      要与此实体相关联的父实体。
 * billboard	  与此实体相关联的广告牌。
 * box	          与该实体关联的框。
 * corridor	      与此实体相关联的走廊。
 * cylinder	      要与此实体相关联的圆柱体。
 * ellipse	      与此实体相关联的椭圆。
 * ellipsoid	  与此实体相关联的椭球。
 * label	      与此实体相关联的options.label。
 * model	      与此实体相关联的模型。
 * tileset	      要与此实体相关联的3D Tiles图块。
 * path	          与此实体相关联的路径。
 * plane	      与此实体相关联的平面。
 * point	      与该实体关联的点。
 * polygon	      要与此实体相关联的多边形。
 * polyline	      与此实体相关联的折线。
 * properties	  与此实体相关联的任意属性。
 * polylineVolume 与此实体相关联的polylineVolume。
 * rectangle	  与此实体相关联的矩形。
 * wall	          与该实体关联的墙。

 */

import {
    Cartesian3,
    NearFarScalar,
    Color,
    LabelStyle,
    Cartesian2,
    VerticalOrigin,
    CustomMaterialProperty

} from "cesium";
import moment from "moment";
import green from "../../assets/icon/green.png";
import Matrix4 from "cesium/Source/Core/Matrix4";
const Option: any = {
    billboard: {},
    id: moment().valueOf(),
    name: "",
    type: "",
    position: [],
    data: {},
    label: {},
    polygon: {},
    lettie: {}

}
export default class entities extends CesiumInit {
    viewer: any;
    option = Option;
    constructor(viewer: any) {
        super()
        this.viewer = viewer;
    }
    /**
     * @description: 创建实例所用到的参数
     * @param {*  billboard} 广告牌参数，没有可以不传 
     * @param {*  } data
     * @param {* } data
     * @param {* } data
     * @return {*}
     */
    options: any = (option) => {
        const {
            billboard,
            position,
            data,
            id,
            type,
            name,
            label,
            polygon,
            polyline,
            lettie

        }: any = option;

        /**
         * id赋值 此对象的唯一标识符。如果未提供，则将生成GUID
         */
        id ? (this.option.id = id) : (delete this.option.id);
        /**
         * name赋值 要显示给用户的可读名称。它不必是唯一的.
         */
        name && (this.option.name = name);
        /**
         * id赋值 唯一ID 重复则会报错
         */

        type && (this.option.type = type);
        /**
         * 给实体经纬度
         */
        position && (this.option.position = this.positions(position));
        /**
         * 给广告牌赋值
         */
        billboard ? (
            this.option.billboard = this.optionBillboards(billboard)
        ) : (
            delete this.option.billboard
        );
        /**
         * 给广告牌赋值
         */
        polygon ? (
            this.option.polygon = this.optionPolygons(polygon)
        ) : (
            delete this.option.polygon
        );
        /**
         * 给线段赋值
         */
        polyline ? (
            this.option.polyline = this.optionPolylines(polyline)
        ) : (
            delete this.option.polyline
        );
        /**
         * 添加文字
         */
        label ? (
            this.option.label = this.optionLabels(label)
        ) : (
            delete this.option.label
        );
        /**
         * 添加lettie
         */
        lettie ? (
            this.option.lettie = this.optionLetties(lettie)
        ) : (
            delete this.option.lettie
        );

        /**
         * 传入的数据赋值
         */
        data && (this.option.data = { ...data });

    }
    /**
     * @description: 初始化中心点坐标参数
     * @param {*} p 经纬度参数 [119, 23]
     * @return {*}
     */
    positions = (p) => {
        return Cartesian3.fromDegrees(p[0], p[1])
    }
    /**
     * @description: 实例化广告牌参数
     * @param {*} billboard
     * @return {*}
     */
    optionBillboards = (billboard) => {
        const { url, Zindex, scale, color, eyeOffsetZ, distanceDisplayCondition } = billboard;
        const color_ = color ? { color: this.CssColor(color) } : {};
        return {
            image: url,
            sizeInMaters: true,
            scale: scale ? scale : 1.2,
            scaleByDistance: new NearFarScalar(Zindex, 1.0, Zindex + 1, 0.001),
            eyeOffset: new Cartesian3(0, 0, eyeOffsetZ ? eyeOffsetZ : -10),
            distanceDisplayCondition,
            ...color_
        }
    }
    /**
     * @description: 实例化标签参数
     * @param {*} billboard
     * @return {*}
     */
    optionLabels = (label) => {

        const {
            text, Zindex, fillColor, eyeOffsetZ, pixelOffsetX, pixelOffsetY, backgroundColor, scale = 0.125, distanceDisplayCondition
        } = label;

        return {
            text: text,
            font: '112px roboto,微软雅黑',
            style: LabelStyle.FILL_AND_OUTLINE,
            scale,
            outlineWidth: 4,
            scaleByDistance: new NearFarScalar(Zindex ? Zindex : 1000000, 1.0, (Zindex ? Zindex : 1000000) + 1, 0.001),
            fillColor: this.CssColor(fillColor ? fillColor : '#FFF'),
            verticalOrigin: VerticalOrigin.BOTTOM,
            pixelOffset: new Cartesian2(pixelOffsetX ? pixelOffsetX : 0, pixelOffsetY ? pixelOffsetY : -4),
            eyeOffset: new Cartesian3(0, 0, eyeOffsetZ ? eyeOffsetZ : -10),
            //设置背景颜色透明  
            backgroundColor: this.CssColor(backgroundColor ? backgroundColor : '#0002'),
            distanceDisplayCondition,
            // showBackground: true,
        }
    }
    /**
     * @description: 创建线--普通的
     * @param {*}
     * @return {*}
     */
    optionPolylines = (polyline) => {
        const {
            positions,
            flow,
            speed,
            flowColor,
            zIndex,
            staticColor,
            lineWidth,
            material,
            isDynamic,
            clampToGround,
            distanceDisplayCondition
        } = polyline;
        return {
            positions: flow ? Cartesian3.fromDegreesArray(positions).map((item: Cartesian3) => {
                item.z += 0.3;
                return item;
            }) : Cartesian3.fromDegreesArray(positions),
            width: lineWidth,
            material: flow ? (
                new CustomMaterialProperty({
                    image: green,
                    color: this.CssColor(flowColor),
                    duration: speed
                })
            ) : material ? material : this.CssColor(staticColor),
            // clampToGround,
            zIndex: zIndex ? zIndex : -3,
            distanceDisplayCondition,
        };
    }

    /**
     * @description: 创建线--普通的
     * @param {*}
     * @return {*}
     */
    optionPolygons = (polygon) => {
        const { positions, color, outlineColor, material, stRotation, distanceDisplayCondition, zIndex, height, outlineWidth } = polygon;
        let position = Cartesian3.fromDegreesArray(
            [].concat.apply([], positions)
        );

        return {
            hierarchy: {
                positions: position
            },
            height,
            outline: true,
            outlineWidth,
            outlineColor,
            // arcType: this.Cesium.ArcType.RHUMB,
            material: material ? material : this.CssColor(color),
            stRotation,
            distanceDisplayCondition,
            zIndex
        }

    }
    /**
     * @description: 创建动态图标
     * @param {*}
     * @return {*}
     */
    optionLetties = (lettie) => {

        const { position, src, size, event, id } = lettie;
        let sds: any;

        var ulEle = document.createElement('div');
        //为div添加样式
        var style = document.createAttribute("style");
        ulEle.setAttributeNode(style);

        ulEle.style.width = `${size}px`;
        ulEle.style.height = `${size}px`;
        ulEle.style.position = 'fixed';
        ulEle.style.zIndex = '10';
        ulEle.id = id;

        // 创建div标签添加点击事件，点击删除当前点位
        let onclick = () => {
            if (id.toString().indexOf('se') !== -1) {
                this.viewer.scene.postRender.removeEventListener(sds);

                // ulEle.style.opacity = '0';
                ulEle.style.display = "none";
            }
            event()
        };

        Lett(ulEle, size, src, id, onclick)
        sds = () => {
            const vmInstance = ulEle;
            // this.viewer.cesiumWidget.container.appendChild(vmInstance);
            if (!vmInstance || !vmInstance.style) return;

            if (this.viewer.camera.positionCartographic.height > 8000) {
                vmInstance.style.display = "none";
            } else {
                vmInstance.style.display = "block";
            }
            if (position) {
                var px = this.Cesium.SceneTransforms.wgs84ToWindowCoordinates(this.viewer.scene, position, new this.Cesium.Cartesian2());
                if (px) {
                    vmInstance.style.left = px.x + (Math.ceil(-vmInstance.offsetWidth / 2)) + "px";
                    vmInstance.style.top = px.y + (- vmInstance.offsetHeight) + (size / 2) + "px";
                }
            }
        };
        this.viewer.scene.postRender.addEventListener(sds);
    }
    /**
     * @description: 创建并且添加实体到viewer中
     * @param {*}
     * @return {* Entity} 返回实体对象
     */
    add = () => {
        return this.viewer.entities.add({ ...this.option })
    }
    /**
     * @description: 根据ID获取实体
     * @param {*} id
     * @return {*}
     */
    getById = (id) => {
        return this.viewer.entities.getById(id)
    }
    //场景渲染事件 实时更新标签的位置 使其与笛卡尔坐标一致
    postRender(sub: any) {
        let sds: any;
        const { position, size, src, event } = sub;

        var thisNode = document.getElementById("ulEleID");
        thisNode && thisNode.parentElement.removeChild(thisNode);

        var ulEle = document.createElement('div');
        var style = document.createAttribute("style");
        ulEle.setAttributeNode(style);
        ulEle.style.width = `${size}px`;
        ulEle.style.height = `${size}px`;
        ulEle.style.position = 'fixed';
        ulEle.style.zIndex = '10';
        ulEle.id = "ulEleID";

        // 创建div标签添加点击事件
        ulEle.onclick = () => {
            this.viewer.scene.postRender.removeEventListener(window['ads']);
            // ulEle.style.opacity = '0';
            ulEle.style.display = "none";
            event()
        };

        Lett(ulEle, size, src, 'ulEleID')
        window['ads'] = () => {
            const vmInstance = ulEle;
            // this.viewer.cesiumWidget.container.appendChild(vmInstance);
            if (!vmInstance || !vmInstance.style) return;
            if (this.viewer.camera.positionCartographic.height > 4000) {
                vmInstance.style.display = "none";
            } else {
                vmInstance.style.display = "block";
            }
            if (position) {
                var px = this.Cesium.SceneTransforms.wgs84ToWindowCoordinates(this.viewer.scene, position, new this.Cesium.Cartesian2());
                if (px) {
                    vmInstance.style.left = px.x + (Math.ceil(-vmInstance.offsetWidth / 2)) + "px";
                    vmInstance.style.top = px.y + (- vmInstance.offsetHeight) + (size / 2) + "px";
                }
            }
        };

        this.viewer.scene.postRender.addEventListener(window['ads']);
    }

    /**
     * @description: 根据ID 移除实体
     * @param {*}
     * @return {*}
     */
    accordIdRemove = (Id) => {
        /**
        * 根据id获取实体
        */
        var radar = this.viewer.entities.getById(Id);

        /**
         * 当实体存在时删除实体
         */
        if (radar) {
            this.viewer.entities.remove(radar)
        }
    }
    /**
     * @description: 移除全部实体
     * @param {*}
     * @return {*}
     */

    removeEntitieAll = (radarId: any) => {
        /**
         * 根据id获取实体
         */
        var radar = this.viewer.entities.getById(radarId);

        /**
         * 当实体存在时删除实体
         */
        if (radar) {
            this.viewer.entities.remove(radar)
        }
    }
    /**
     * @description: 飞到实体上
     * @param {*}
     * @param postiton 经纬度
     */
    flyToEntities = (entities: any) => {
        if (entities instanceof Object) {
            this.viewer.flyTo(entities);
        }
    }
}


