/*
 * @Author: Elon-Ysuhan
 * @LastEditors: DESKTOP-K8H00JV
 * @description: cesium 工具
 * @Date: 2022-05-05 15:33:03
 * @LastEditTime: 2022-10-14 11:53:58
 */


//84坐标转墨卡托投影坐标
export function wgs84mercatorto(L, B, degree) {
    var xy = [0, 0];
    var a = 6378137.0;
    var b = 6356752.314245179;
    var e = 0.081819190842621;
    var eC = 0.0820944379496957;
    var L0 = 0.0;

    var n;
    if (degree == 6.0) {
        n = Math.round((L + degree / 2.0) / degree);
        L0 = degree * n - degree / 2.0;
    } else {
        n = Math.round(L / degree);
        L0 = degree * n;
    }

    var radB = B * 3.141592653589793 / 180;
    var radL = L * 3.141592653589793 / 180;
    var deltaL = (L - L0) * 3.141592653589793 / 180;
    var N = a * a / b / Math.sqrt(1 + eC * eC * Math.cos(radB) * Math.cos(radB));
    var C1 = 1.0 + 0.75 * e * e + 0.703125 * Math.pow(e, 4.0) + 0.68359375 * Math.pow(e, 6.0) + 0.67291259765625 * Math.pow(e, 8.0);
    var C2 = 0.75 * e * e + 0.9375 * Math.pow(e, 4.0) + 1.025390625 * Math.pow(e, 6.0) + 1.07666015625 * Math.pow(e, 8.0);
    var C3 = 0.234375 * Math.pow(e, 4.0) + 0.41015625 * Math.pow(e, 6.0) + 0.538330078125 * Math.pow(e, 8.0);
    var C4 = 0.068359375 * Math.pow(e, 6.0) + 0.15380859375 * Math.pow(e, 8.0);
    var C5 = 0.00240325927734375 * Math.pow(e, 8.0);
    var t = Math.tan(radB);
    var eta = eC * Math.cos(radB);
    var X = a * (1 - e * e) * (C1 * radB - C2 * Math.sin(2 * radB) / 2 + C3 * Math.sin(4 * radB) / 4 - C4 * Math.sin(6 * radB) / 6 + C5 * Math.sin(8 * radB));
    xy[0] = X + N * Math.sin(radB) * Math.cos(radB) * Math.pow(deltaL, 2.0) * (1 + Math.pow(deltaL * Math.cos(radB), 2.0) * (5 - t * t + 9 * eta * eta + 4 * Math.pow(eta, 4.0)) / 12 + Math.pow(deltaL * Math.cos(radB), 4.0) * (61 - 58 * t * t + Math.pow(t, 4.0)) / 360) / 2;
    xy[1] = N * deltaL * Math.cos(radB) * (1 + Math.pow(deltaL * Math.cos(radB), 2.0) * (1 - t * t + eta * eta) / 6 + Math.pow(deltaL * Math.cos(radB), 4.0) * (5 - 18 * t * t + Math.pow(t, 4.0) - 14 * eta * eta - 58 * eta * eta * t * t) / 120) + 500000;

    return xy;

}

//墨卡托投影转84坐标
export function mercator2towgs84(e) {
    let X, Y, L0;
    X = e[0];
    Y = e[1];
    L0 = 1;
    let lon = X / 20037508.34 * 180;
    let lat = Y / 20037508.34 * 180;
    lat = 180 / Math.PI * (2 * Math.atan(Math.exp(lat * Math.PI / 180)) - Math.PI / 2);

    return [lon, lat];
}

export function level2height(level) {
    const A = 40487.57;
    const B = 0.00007096758;
    const C = 91610.74;
    const D = -40467.74;
    const height = D + (A - D) / (1 + Math.pow(level / C, B));
    console.log(height)
    return height;
}


