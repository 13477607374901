import axios from "axios";

const getToken = () => {
  return localStorage.getItem("access_token");
};

// const token = localStorage.getItem("token");
// if (token) {
//     axios.defaults.headers.common["Authorization"] = "bearer " + token;
// }

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API : window.location.origin + '/api';
if (getToken()) {
  axios.defaults.headers.common["Authorization"] = "bearer " + getToken();
}
axios.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    localStorage.removeItem("token");
    window.location.href = "/";
  }
);
