import React, { useEffect, useState } from "react";
import { PtTab, PtTable } from "../../../../components";
import { DatePicker, Table } from "antd";
import "./index.scss";
import axios from "axios";
import { Chart, Legend, Line, Point, Tooltip } from "bizcharts";
import moment from "moment";
import {
  detailsTimePicker,
  detailsTimeTab,
  detailsTypeTab,
} from "../../../data";

const { RangePicker } = DatePicker;

export const StationsDetails = ({ data }) => {
  const [timeKey, setTimeKey] = useState(0);
  const [typeKey, setTypeKey] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [chartList1, setChartList1] = useState([]);
  const [chartList2, setChartList2] = useState([]);

  const [excelList, setExcelList] = useState([]);

  const [time, setTime] = useState("" as string);

  useEffect(() => {
    if (time) {
      let newTime = dealWithTime(time, timeKey);
      const params = {
        code: data.code,
        type: timeKey + 1,
        startTime:
          timeKey === 4 ? moment(time[0]).format("YYYY-MM-DD") : newTime[0],
        endTime:
          timeKey === 4 ? moment(time[1]).format("YYYY-MM-DD") : newTime[1],
      };
      getList(params);
      getExcelList(params);
    } else {
      const a = new Date();
      const newTime = dealWithTime(a, timeKey);
      const params = {
        code: data.code,
        type: timeKey + 1,
        startTime: newTime[0],
        endTime: newTime[1],
      };
      getList(params);
      getExcelList(params);
    }
  }, [data.code]);

  /**
   * @description 处理时间
   * @param value
   * @param key
   */
  const dealWithTime = (value, key) => {
    let startTime;
    let endTime;
    switch (key) {
      case 0:
        startTime = moment(value).format("YYYY-MM-DD");
        endTime = moment(value).format("YYYY-MM-DD");
        return [startTime, endTime];
      case 1:
        startTime = moment(value).startOf("week").format("YYYY-MM-DD");
        endTime = moment(value).endOf("week").format("YYYY-MM-DD");
        return [startTime, endTime];
      case 2:
        startTime = moment(value).startOf("month").format("YYYY-MM-DD");
        endTime = moment(value).endOf("month").format("YYYY-MM-DD");
        return [startTime, endTime];
      case 3:
        startTime = moment(value).startOf("year").format("YYYY-MM-DD");
        endTime = moment(value).endOf("year").format("YYYY-MM-DD");
        return [startTime, endTime];
    }
  };

  const getExcelList = (params) => {
    axios
      .get("/monitor_station_yard_process/query_info_by_code", { params })
      .then((res: any) => {
        if (res.code === 200) {
          setExcelList(res.data);
        }
      });
  };

  const getList = (params) => {
    axios
      .get("/monitor_station_yard_process/query_by_code", {
        params,
      })
      .then((res: any) => {
        if (res.code === 200) {
          const newArr1 = [];
          const newArr2 = [];
          res.data.forEach((item) => {
            if (item.aloneFlag) {
              newArr1.push(item);
            } else {
              newArr2.push(item);
            }
          });
          console.log(newArr1 , newArr2);
          newArr1.forEach((item: any) =>{
            if(item?.in?.length) {
              const len = item.in.filter(element => element.value === 0).length;
              len === item.in.length && (item.in = [])
            }
            if(item?.out?.length) {
              const len = item.out.filter(element => element.value === 0).length;
              len === item.out.length && (item.out = [])
            }
          })

          newArr2.forEach((item: any) =>{
            if(item?.in?.length) {
              const len = item.in.filter(element => element.value === 0).length;
              len === item.in.length && (item.in = [])
            }
            if(item?.out?.length) {
              const len = item.out.filter(element => element.value === 0).length;
              len === item.out.length && (item.out = [])
            }
          })
          
          setChartList1(newArr1);
          setChartList2(newArr2);
        }
      });
  };

  const timeChange = (date, dateString) => {
    if (dateString.constructor === Array) {
      const params = {
        code: data.code,
        type: timeKey + 1,
        startTime: dateString[0],
        endTime: dateString[1],
      };
      getList(params);
      getExcelList(params);
    } else {
      const newTime = dealWithTime(date, timeKey);
      const params = {
        code: data.code,
        type: timeKey + 1,
        startTime: newTime[0],
        endTime: newTime[1],
      };
      getList(params);
      getExcelList(params);
    }
    setTime(date);
  };

  const timeKeyChange = (date) => {
    setTimeKey(date);
    setTime("");
    setSelectedRowKeys([]);
    const a = new Date();
    const newTime = dealWithTime(a, date);
    const params = {
      code: data.code,
      type: date + 1,
      startTime: date === 4 ? moment(time[0]).format("YYYY-MM-DD") : newTime[0],
      endTime: date === 4 ? moment(time[1]).format("YYYY-MM-DD") : newTime[1],
    };
    getList(params);
    getExcelList(params);
  };

  return (
    <>
      <div className={"expand__content__head"}>
        {data.title || data.name} - 数据详情
      </div>
      <div className={"expand__content__select"}>
        <div></div>
        <div className={"select--right"}>
          <PtTab
            data={detailsTimeTab}
            value={timeKey}
            onChange={timeKeyChange}
            auto={false}
          />
          {timeKey === 4 ? (
            <RangePicker
              allowClear={false}
              size={"small"}
              onChange={timeChange}
            />
          ) : (
            <DatePicker
              allowClear={false}
              picker={detailsTimePicker[timeKey]}
              size={"small"}
              onChange={timeChange}
            />
          )}
          <PtTab
            data={detailsTypeTab}
            value={typeKey}
            onChange={(value) => {
              setTypeKey(value);
            }}
          />
        </div>
      </div>
      <div className={"expand__content__background"}>
        {typeKey === 0 ? (
          <>
            <div className="chart_list">
              {chartList1.map((item) => {
                return (
                  <>
                  {
                    item.out.length ? <div className="chart_box">
                      <StationsCharts data={item.out} height="180px" />
                      <div className="chart_text">{item.type}</div>
                    </div> : <></>
                  }
                  </>
                );
              })}
              {chartList2.map((item) => {
                return (
                  <>
                    {
                      item.in.length  ? <div className="chart_box">
                      <StationsCharts data={item.in} height="180px" />
                      <div className="chart_text">{item.type}-进水</div>
                    </div> : <></>
                    }
                    {
                      item.out.length  ? <div className="chart_box">
                      <StationsCharts data={item.out} height="180px" />
                      <div className="chart_text">{item.type}-出水</div>
                    </div> : <></>
                    }
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className="details_table">
              <Table
                dataSource={excelList}
                pagination={false}
                scroll={{ y: 550, x: 400 }}
                columns={[
                  {
                    title: "记录时间",
                    dataIndex: "recordTime",
                    width: 160,
                    align: "center",
                    fixed: "left",
                  },
                  {
                    title: "环境温度",
                    dataIndex: "temperature",
                    align: "center",
                    width: 100,
                  },
                  {
                    title: "累计流量-进水",
                    dataIndex: "totalFlowIn",
                    align: "center",
                    width: 130,
                  },
                  {
                    title: "累计流量-进水",
                    dataIndex: "totalFlowOut",
                    align: "center",
                    width: 130,
                  },
                  {
                    title: "COD-进水",
                    dataIndex: "codIn",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "COD-出水",
                    dataIndex: "codOut",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "浊度-进水",
                    dataIndex: "turbidityIn",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "浊度-出水",
                    dataIndex: "turbidityOut",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "PH-进水",
                    dataIndex: "phIn",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "PH-出水",
                    dataIndex: "phOut",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "总磷-进水",
                    dataIndex: "phosphorusIn",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "总磷-出水",
                    dataIndex: "phosphorusOut",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "瞬时流量-进水",
                    dataIndex: "inletFlowIn",
                    align: "center",
                    width: 140,
                  },
                  {
                    title: "瞬时流量-出水",
                    dataIndex: "inletFlowOut",
                    align: "center",
                    width: 140,
                  },
                  {
                    title: "氨氮-进水",
                    dataIndex: "ammoniaNitrogenIn",
                    align: "center",
                    width: 110,
                  },
                  {
                    title: "氨氮-出水",
                    dataIndex: "ammoniaNitrogenOut",
                    align: "center",
                    width: 110,
                  },
                ]}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "24px",
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "24px",
                    lineHeight: "24px",
                    background: "#02D281",
                    borderRadius: "2px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let params;
                    if (time) {
                      let newTime = dealWithTime(time, timeKey);
                      params = {
                        code: data.code,
                        type: timeKey + 1,
                        startTime:
                          timeKey === 4
                            ? moment(time[0]).format("YYYY-MM-DD")
                            : newTime[0],
                        endTime:
                          timeKey === 4
                            ? moment(time[1]).format("YYYY-MM-DD")
                            : newTime[1],
                      };
                    } else {
                      const a = new Date();
                      const newTime = dealWithTime(a, timeKey);
                      params = {
                        code: data.code,
                        type: timeKey + 1,
                        startTime: newTime[0],
                        endTime: newTime[1],
                      };
                    }

                    const token = localStorage.getItem("access_token");
                    let xhr = new XMLHttpRequest();
                    const url = axios.defaults.baseURL
                    xhr.open(
                      "get",
                      `${url}/monitor_station_yard_process/export_excel?code=${params.code}&type=${params.type}&startTime=${params.startTime}&endTime=${params.endTime}`,
                      true
                    ); // 也可以使用POST方式，根据接口
                    xhr.responseType = "blob"; // 返回类型blob
                    xhr.setRequestHeader("Authorization", "bearer " + token);
                    xhr.setRequestHeader("content-type", "application/json");
                    xhr.onreadystatechange = () => {};
                    xhr.onload = function () {
                      if (this.status === 200) {
                        var blob = this.response;
                        var reader = new FileReader();
                        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                        reader.onload = function (e: any) {
                          var a: any = document.createElement("a");
                          a.download = `${data.name}-${data.code}-${params.startTime}-${params.endTime}`; //下载文件名
                          a.href = e.target.result;
                          a.click();
                          window.URL.revokeObjectURL(e.target.result);
                        };
                      }
                    };
                    xhr.send(JSON.stringify(selectedRowKeys));
                  }}
                >
                  下载
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

//图表
export const StationsCharts = ({ data = [], height = "650px", scale = {} }) => {
  return (
    <Chart
      padding={[20, 20, 20, 50]}
      autoFit
      height={height}
      data={data}
      scale={scale}
    >
      <Line shape="smooth" position="time*value" />
      <Point position="time*value" />
      <Tooltip shared showCrosshairs />
    </Chart>
  );
};
