import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle5 from "../../general/excelStyle5";
import { RiverExcel3Data } from "../data";

interface Props {
  dataSource: any;
}

/**
 * @description         河道巡检记录
 */

const RiverExcel3 = ({ dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "站点名称",
              value: dataSource.title,
            },
            {
              title: "巡查日期",
              value: dataSource.time,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "巡检人员",
              value: "闫固咏",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "序号", span: 2 },
            { title: "巡查项", span: 6 },
            { title: "巡查情况", span: 8 },
            { title: "处理意见", span: 8 },
          ]}
        />
        <ExcelStyle5
          columns={[
            { title: "序号", span: 2, dataIndex: "index" },
            { title: "巡查项", span: 6, dataIndex: "project" },
            { title: "巡查情况", span: 8, dataIndex: "patrol" },
            { title: "处理意见", span: 8, dataIndex: "results" },
          ]}
          dataSource={RiverExcel3Data}
        />
      </div>
    </>
  );
};

export default RiverExcel3;
