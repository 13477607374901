import { useEffect } from "react";

interface Props {
  dataSource: any;
  id: number;
  outRadius: number;
}

const GeneralChart = ({ dataSource, id, outRadius }: Props) => {
  useEffect(() => {
    init();
  }, []);

  const init = () => {
    let drawing: any = document.getElementById(`general_chart_${id}`);
    let ctx = drawing.getContext("2d");
    let ox = (drawing.width - outRadius * 2) / 2 + outRadius;
    let oy = (drawing.height - outRadius * 2) / 2 + outRadius;
    let startAngle = 0;
    let endAngle = 0;
    for (var i = 0, len = dataSource.length; i < len; i++) {
      ctx.beginPath();
      endAngle += dataSource[i].percent * 2 * Math.PI;
      ctx.lineWidth = 10;
      ctx.strokeStyle = dataSource[i].color;
      ctx.arc(ox, oy, outRadius, startAngle, endAngle);
      ctx.stroke();
      startAngle = endAngle; //更新起始弧度
    }
  };

  return <canvas id={`general_chart_${id}`} width={130} height={130} />;
};

export default GeneralChart;
