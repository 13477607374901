import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle5 from "../../general/excelStyle5";
import { RiverExcel5Data } from "../data";

interface Props {}

/**
 * @description         河道养护
 */

const RiverExcel5 = ({}: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "序号", span: 2 },
            { title: "养护内容", span: 5 },
            { title: "地点", span: 4 },
            { title: "养护情况描述", span: 6 },
            { title: "养护人", span: 4 },
            { title: "备注", span: 3 },
          ]}
        />

        <ExcelStyle5
          columns={[
            { title: "序号", span: 2, dataIndex: "index" },
            { title: "养护内容", span: 5, dataIndex: "content" },
            { title: "地点", span: 4, dataIndex: "position" },
            { title: "养护情况描述", span: 6, dataIndex: "describe" },
            { title: "养护人", span: 4, dataIndex: "personnel" },
            { title: "备注", span: 3, dataIndex: "remark" },
          ]}
          dataSource={RiverExcel5Data}
        />
      </div>
    </>
  );
};

export default RiverExcel5;
