import { useState } from "react";
import { useSelector } from "react-redux";
import { PtCard, PtDrawer } from "../../../../components";
import CloseButton from "../general/colseButton";
import Emergency3Left from "./components/emergency3left";
import Emergency3Right from "./components/emergency3right";
import GeneralModal from "../general/generalModal";
import "./index.scss";

interface Props {}

/**
 * @description           应急纪录
 */

const Emergency3 = ({}: Props) => {
  //两侧内容显示状态
  const drawerVisible = useSelector((value: any) =>
    value.getIn(["home", "drawerVisible"])
  );
  const [activeData, setActiveData] = useState({});

  const [activeImage, setActiveImage] = useState("");

  return (
    <div className="emergency3_box">
      <PtDrawer
        visible={drawerVisible}
        left={
          <PtCard title={"应急记录"}>
            <Emergency3Left
              activeData={activeData}
              setActiveData={(value) => {
                setActiveData(value);
                setActiveImage("");
              }}
            />
          </PtCard>
        }
        right={
          (activeData as any).id ? (
            <PtCard
              title={"应急描述"}
              suffix={<CloseButton onClick={() => setActiveData({})} />}
            >
              <Emergency3Right
                activeData={activeData}
                setActiveImage={setActiveImage}
              />
            </PtCard>
          ) : (
            <></>
          )
        }
      />

      {activeImage && (
        <GeneralModal
          title="现场照片"
          downloadVisible={false}
          onClose={() => setActiveImage("")}
        >
          <div className="modal_image_box">
            <img src={activeImage} alt="" />
          </div>
        </GeneralModal>
      )}
    </div>
  );
};

export default Emergency3;
