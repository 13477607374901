export const CardTitle = {
  "1-1": "应急预案",
  "1-2": "应急人员",
  "1-3": "应急记录",
  "2": "统计分析",
  "3-2": "运维管理",
  "4": "专家库",
};

/**
 * @description         专家表格 每列内容
 */

export const ExpertsColumns = [
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "专业",
    dataIndex: "major",
    width: 194,
  },
  {
    title: "处理数量",
    dataIndex: "num",
    width: 78,
  },
];

/**
 * @description         应急人员列表
 */

export const Emergency2DataSource = [
  {
    id: 1,
    name: "宋瑶茗",
    phone: "15725025834",
    headImg: require("../../assets/img/1.png").default,
    state: 1,
  },
  {
    id: 2,
    name: "吕建林",
    phone: "13588615676",
    headImg: require("../../assets/img/2.png").default,
    state: 1,
  },
  {
    id: 3,
    name: "步巧毅",
    phone: "13857237426",
    headImg: require("../../assets/img/3.png").default,
    state: 1,
  },
  {
    id: 4,
    name: "练裕荷",
    phone: "17659364881",
    headImg: require("../../assets/img/4.png").default,
    state: 1,
  },
  {
    id: 5,
    name: "戚玲",
    phone: "15723204284",
    headImg: require("../../assets/img/5.png").default,
    state: 1,
  },
  {
    id: 6,
    name: "蔡固",
    phone: "17809310347",
    headImg: require("../../assets/img/6.png").default,
    state: 1,
  },
  {
    id: 7,
    name: "蒋云舞",
    phone: "13666555230",
    headImg: require("../../assets/img/7.png").default,
    state: 1,
  },
  {
    id: 8,
    name: "黄夏芳",
    phone: "13735946840",
    headImg: require("../../assets/img/8.png").default,
    state: 1,
  },
  {
    id: 9,
    name: "陈建成",
    phone: "13587199431",
    headImg: require("../../assets/img/9.png").default,
    state: 1,
  },
  {
    id: 10,
    name: "叶金荣",
    phone: "15989554847",
    headImg: require("../../assets/img/10.png").default,
    state: 1,
  },
  {
    id: 11,
    name: "雷锦琨",
    phone: "13555886199",
    headImg: require("../../assets/img/11.png").default,
    state: 1,
  },
];

/**
 * @description         专家库列表
 */

export const ExpertsDataSource = [
  {
    id: 1,
    name: "彭克俭",
    major: "生态环境",
    phone: "139****9292",
    headImg: require("../../assets/img/12.png").default,
    num: 1,
    introduction:
      "博士研究生，研究员，负责承担国家水体污染控制与治理重大科技专项湘江水专项子课题任务、环保公益性行业科研专项子任务等各类科研项目20余项。获得环境科学技术奖二等奖一项（排名第一）。发表国内外论文30余篇，授权发明专利3项（排名第一），获得实用新型发明专利1项（排名第一），完成成果鉴定(或评价）7项（排名第一的3项），主持编制地方标准1项（排名第一）。",
  },
  {
    id: 2,
    name: "成应向",
    major: "生态水利",
    phone: "139****9292",
    headImg: require("../../assets/img/13.png").default,
    num: 3,
    introduction:
      "硕士研究生，研究员，院水污染控制技术研发团队首席科学家，院学术委员会委员，主要从事水污染控制技术与有色工业固废资源化技术研发工作。主持和参与科研及横向项目42项。先后荣获省部级科技进步二等奖2项，市级环保科技进步一等奖2项，获得科技成果13项，发明专利7项（4项授权）、发表论文40篇（其中第一作者13篇）、合编论著2部。",
  },
  {
    id: 3,
    name: " 陈才丽",
    major: "生态环境",
    phone: "139****9292",
    headImg: require("../../assets/img/14.png").default,
    num: 3,
    introduction:
      "主要从事环境影响评价、环境科研、上市核查等工作，负责主持、参与完成的国际国内纵向科研项目、环评项目、上市核查、实施方案150余项，获得省部级科技二等奖1项，取得科技成果鉴定3项，获市州厅级优秀环评报告书二等奖1项、三等奖1项，发表论文8篇（第一作者5篇）。",
  },
  {
    id: 4,
    name: "姜苹红",
    major: "生态水利",
    phone: "139****9292",
    headImg: require("../../assets/img/15.png").default,
    num: 5,
    introduction:
      "博士，研究员，主要从事水环境污染治理与生态修复、环境生物监测、土壤污染治理修复领域的研究工作。近年来，负责承担国际合作、国家级及省级科研项目20余项，获市级生态环境技术进步奖一等奖1项，授权专利4项，取得科技成果鉴定6项，发表论文20余篇，合编生态环境类科技专著4部。",
  },
  {
    id: 5,
    name: "李二平",
    major: "环境工程",
    phone: "139****9292",
    headImg: require("../../assets/img/16.png").default,
    num: 1,
    introduction:
      "工学博士，研究员，院学术委员会副主任，湖南省生态环境保护专家委员会委员。主要从事固体废物处理和重金属污染防治技术研究。主持承担湖南省重点研发计划、湖南省高新技术产业科技创新引领计划等项目21项，以第一作者和通讯作者发表SCI、EI收录论文13篇，国际先进科技成果2项，授权发明专利7项。",
  },
  {
    id: 6,
    name: "刘湛",
    major: "环境工程",
    phone: "139****9292",
    headImg: require("../../assets/img/17.png").default,
    num: 5,
    introduction:
      "主要从事大气环境科学研究、大气污染防治技术研发与应用，作为负责人和技术骨干承担省部级以上科研课题 40 余项，获省部级科技进步二等奖 1 项，地市级科技进步一等奖 1 项，科技成果鉴定12 项，申请专利 3 项，软件著作权 2 项，发表论文 30 余篇。",
  },
  {
    id: 7,
    name: "石广明",
    major: "生态水利",
    phone: "139****9292",
    headImg: require("../../assets/img/18.png").default,
    num: 1,
    introduction:
      "主要从事环境政策分析、环境管理及能源与环境经济学方面研究。目前公开发表SCI、EI检索论文20余篇，出版专著一部。主持和承担国家自然科学基金项目、国家科技支撑计划项目、湖南省科技计划以及各地咨询项目20余项。担任中国环境科学学会环境经济学分会、气候变化分会、环境审计与核算分会委员，此外，还担任国家自然科学基金委、湖南省科技厅、江西省科技厅项目评审专家。",
  },
  {
    id: 8,
    name: "梁恒",
    major: "生态水利",
    phone: "139****9292",
    headImg: require("../../assets/img/19.png").default,
    num: 2,
    introduction:
      "高级工程师，注册公用设备工程师（给水排水），注册环保工程师。",
  },
  {
    id: 9,
    name: "刘道行",
    major: "生态水利",
    phone: "139****9292",
    headImg: require("../../assets/img/20.png").default,
    num: 4,
    introduction:
      "主要致力于水环境生态治理及修复项目的科学研究、咨询设计、项目建设及运营等工作。",
  },
];

/**
 * @description           应急预案列表
 */

export const PlanList = [
  {
    title: "应急预案",
    total: 17,
    type: "",
    list: [
      {
        id: 5,
        title: "触电事故应急预案",
        url: "https://pt.cthd.cc/files/触电事故应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/5.pdf",
        state: true,
      },
      {
        id: 15,
        title: "触电事故应急预案【村污】",
        url: "https://pt.cthd.cc/files/触电应急预案【村污】.doc",
        pdfUrl: "https://pt.cthd.cc/files/55.pdf",
        state: true,
      },
      {
        id: 6,
        title: "防台防汛应急预案",
        url: "https://pt.cthd.cc/files/防台防汛应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/6.pdf",
        state: true,
      },
      {
        id: 16,
        title: "防台防汛应急预案【村污】",
        url: "https://pt.cthd.cc/files/防台防汛应急预案【村污】.doc",
        pdfUrl: "https://pt.cthd.cc/files/16.pdf",
        state: true,
      },
      {
        id: 7,
        title: "高空作业事故应急预案",
        url: "https://pt.cthd.cc/files/高空作业事故应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/7.pdf",
        state: true,
      },
      {
        id: 8,
        title: "火灾事故应急预案",
        url: "https://pt.cthd.cc/files/火灾事故应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/8.pdf",
        state: true,
      },
      {
        id: 18,
        title: "火灾爆炸应急预案【村污】",
        url: "https://pt.cthd.cc/files/火灾爆炸应急预案【村污】.doc",
        pdfUrl: "https://pt.cthd.cc/files/18.pdf",
        state: true,
      },
      {
        id: 9,
        title: "交通事故应急预案",
        url: "https://pt.cthd.cc/files/交通事故应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/9.pdf",
        state: true,
      },
      {
        id: 10,
        title: "紧急疫情应急预案",
        url: "https://pt.cthd.cc/files/紧急疫情应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/10.pdf",
        state: true,
      },
      {
        id: 11,
        title: "设备事故应急预案",
        url: "https://pt.cthd.cc/files/设备事故应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/11.pdf",
        state: true,
      },
      {
        id: 12,
        title: "突发性管网爆管应急预案",
        url: "https://pt.cthd.cc/files/突发性管网爆管应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/12.pdf",
        state: true,
      },
      {
        id: 13,
        title: "有毒气体中毒应急预案",
        url: "https://pt.cthd.cc/files/有毒气体中毒应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/13.pdf",
        state: true,
      },
      {
        id: 14,
        title: "中暑应急预案",
        url: "https://pt.cthd.cc/files/中暑应急预案.docx",
        pdfUrl: "https://pt.cthd.cc/files/14.pdf",
        state: true,
      },
      {
        id: 24,
        title: "中暑应急预案【村污】",
        url: "https://pt.cthd.cc/files/中暑应急预案【村污】.doc",
        pdfUrl: "https://pt.cthd.cc/files/24.pdf",
        state: true,
      },
      {
        id: 25,
        title: "机械伤害事故应急预案【村污】",
        url: "https://pt.cthd.cc/files/机械伤害事故应急预案【村污】.doc",
        pdfUrl: "https://pt.cthd.cc/files/25.pdf",
        state: true,
      },
      {
        id: 26,
        title: "突发公共事件应急预案【村污】",
        url: "https://pt.cthd.cc/files/突发公共事件应急预案【村污】.doc",
        pdfUrl: "https://pt.cthd.cc/files/26.pdf",
        state: true,
      },
      {
        id: 27,
        title: "重大环境污染事故应急预案【村污】",
        url: "https://pt.cthd.cc/files/重大环境污染事故应急预案【村污】.doc",
        pdfUrl: "https://pt.cthd.cc/files/27.pdf",
        state: true,
      },
    ],
  },
  {
    title: "故障处理",
    total: 4,
    type: "",
    list: [
      {
        id: 1,
        title: "超磁设备常见故障及解决办法",
        url: "https://pt.cthd.cc/files/超磁设备常见故障及解决办法.docx",
        pdfUrl: "https://pt.cthd.cc/files/1.pdf",
        state: true,
      },
      {
        id: 2,
        title: "负压设备常见故障及解决办法",
        url: "https://pt.cthd.cc/files/负压设备常见故障及解决办法.docx",
        pdfUrl: "https://pt.cthd.cc/files/2.pdf",
        state: true,
      },
      {
        id: 3,
        title: "污水处理场站设备常见故障及解决办法",
        url: "https://pt.cthd.cc/files/污水处理场站设备常见故障及解决办法.docx",
        pdfUrl: "https://pt.cthd.cc/files/3.pdf",
        state: true,
      },
      {
        id: 4,
        title: "一体化提升泵站设备常见故障及解决办法",
        url: "https://pt.cthd.cc/files/一体化提升泵站设备常见故障及解决办法.docx",
        pdfUrl: "https://pt.cthd.cc/files/4.pdf",
        state: true,
      },
    ],
  },
];

/**
 * @description           应急记录负责人列表
 */

export const HeadList = [
  {
    label: "全部负责人",
    value: 1,
  },
];

/**
 * @description          应急记录状态列表
 */

export const TypeList = [
  {
    label: "全部状态",
    value: 1,
  },
  {
    label: "已处理",
    value: 2,
  },
  {
    label: "待处理",
    value: 3,
  },
];

/**
 * @description           通用时间列表
 */

export const TimeList = [
  {
    label: "近一年",
    value: 1,
  },
  {
    label: "近一月",
    value: 2,
  },
  {
    label: "近一周",
    value: 3,
  },
];

/**
 * @description       统计分析设备列表
 */

export const statisticalEquipment = [
  {
    id: 1,
    name: "苏塘村4号泵",
    state: 1,
  },
  {
    id: 2,
    name: "苏塘村1号泵",
    state: 1,
  },
  {
    id: 3,
    name: "苏塘村5号泵",
    state: 1,
  },
  {
    id: 4,
    name: "苏塘村7号泵",
    state: 1,
  },
  {
    id: 5,
    name: "苏塘村8号泵",
    state: 1,
  },
  {
    id: 6,
    name: "苏塘村9号泵",
    state: 1,
  },
];

/**
 * @description       统计分析事件处理列表
 */

export const statisticalEvent = [
  {
    id: 1,
    name: "水质氮含量指标过高",
    time: "2023.02.09",
    state: 1,
  },
  {
    id: 2,
    name: "水质磷含量指标过高",
    time: "2023.02.09",
    state: 1,
  },
  {
    id: 3,
    name: "水质磷含量指标过高",
    time: "2023.01.02",
    state: 1,
  },
  {
    id: 4,
    name: "水质磷含量指标过高",
    time: "2022.12.29",
    state: 1,
  },
  {
    id: 5,
    name: "水质氮含量指标过高",
    time: "2022.11.19",
    state: 1,
  },
  {
    id: 6,
    name: "水质磷含量指标过高",
    time: "2022.11.01",
    state: 1,
  },
  {
    id: 7,
    name: "水质磷含量指标过高",
    time: "2022.10.14",
    state: 1,
  },
  {
    id: 8,
    name: "水质磷含量指标过高",
    time: "2022.10.13",
    state: 1,
  },
  {
    id: 9,
    name: "水质氮含量指标过高",
    time: "2022.08.23",
    state: 1,
  },
];

export const statisticalPatrol = [
  {
    id: 1,
    name: "叶克",
    time: "2023.02.02 13:23:34",
    state: 1,
  },
  {
    id: 2,
    name: "叶克",
    time: "2022.11.27 12:35:34",
    state: 1,
  },
];

/**
 * @description       运维管理左侧tab
 */

export const operationsLeftTab = [
  {
    title: "设备",
    value: "01",
  },
  // {
  //   title: "管网",
  //   value: 2,
  // },
  // {
  //   title: "监控",
  //   value: 3,
  // },
  {
    title: "绿化",
    value: "02",
  },
  {
    title: "河道",
    value: "03",
  },
];

/**
 * @description       运维管理  设备列表
 */

export const operationsList1 = [
  {
    title: "泵站设备",
    total: 0,
    type: "泵站",
    list: [
      {
        id: "1",
        title: "苏塘村1#",
        state: 1,
      },
    ],
  },
  {
    title: "场站设备",
    total: 4,
    type: "场站",
    list: [
      {
        id: "2",
        title: "珠坑B",
        state: 1,
      },
      {
        id: "3",
        title: "西徐B",
        state: 1,
      },
      {
        id: "4",
        title: "西徐C",
        state: 1,
      },
      {
        id: "5",
        title: "西徐D",
        state: 1,
      },
    ],
  },
  {
    title: "检测井",
    total: 0,
    type: "管井",
    list: [
      {
        id: "6",
        title: "苏塘村-检#10",
        state: 1,
      },
    ],
  },
];

/**
 * @description       运维管理  管网列表
 */

export const operationsList2 = [];

/**
 * @description       运维管理  监控列表
 */

export const operationsList3 = [
  {
    title: "监控设备",
    total: 1,
    type: "监控",
    list: [
      {
        id: 4,
        title: "苏塘村监控#021",
        state: 1,
      },
    ],
  },
];

/**
 * @description       运维管理  绿化列表
 */

export const operationsList4 = [
  {
    title: "乔木",
    total: 1,
    type: "绿化",
    list: [
      {
        id: "7",
        title: "苏塘村植被#021",
        state: 1,
      },
    ],
  },
  {
    title: "灌木/花卉",
    total: 0,
    type: "绿化",
    list: [],
  },
  {
    title: "草坪",
    total: 0,
    type: "绿化",
    list: [],
  },
  {
    title: "沉水植物",
    total: 0,
    type: "绿化",
    list: [],
  },
  {
    title: "浮叶植物",
    total: 0,
    type: "绿化",
    list: [],
  },
  {
    title: "水生植物",
    total: 0,
    type: "绿化",
    list: [],
  },
  {
    title: "挺水植物",
    total: 0,
    type: "绿化",
    list: [],
  },
];

/**
 * @description       运维管理  河道列表
 */

export const operationsList5 = [
  {
    title: "河道列表",
    total: 1,
    type: "河道",
    list: [
      {
        id: "8",
        title: "笏石溪",
        state: 1,
      },
    ],
  },
];

/**
 * @description       运维管理  右侧tab
 */

export const operationsTabs = {
  泵站: [
    {
      title: "巡检记录",
      value: 1,
    },
    {
      title: "维修记录",
      value: 2,
    },
    {
      title: "运行记录",
      value: 3,
    },
    {
      title: "基本信息",
      value: 4,
    },
  ],
  场站: [
    {
      title: "巡检记录",
      value: 1,
    },
    {
      title: "维修记录",
      value: 2,
    },
    {
      title: "运行记录",
      value: 3,
    },
    {
      title: "基本信息",
      value: 4,
    },
  ],
  管井: [
    {
      title: "巡检记录",
      value: 1,
    },
    {
      title: "维修记录",
      value: 2,
    },
    {
      title: "基本信息",
      value: 4,
    },
  ],
  监控: [
    {
      title: "监控记录",
      value: 1,
    },
    {
      title: "维修记录",
      value: 2,
    },
  ],
  绿化: [
    {
      title: "巡检记录",
      value: 1,
    },
    {
      title: "维养记录",
      value: 2,
    },
  ],
  河道: [
    {
      title: "巡检",
      value: 1,
    },
    {
      title: "河床",
      value: 2,
    },
    {
      title: "安全",
      value: 3,
    },
    {
      title: "防汛",
      value: 4,
    },
    {
      title: "养护",
      value: 5,
    },
    {
      title: "违法",
      value: 6,
    },
  ],
};

/**
 * @description     运维管理右侧记录 模拟数据
 */

export const SimulatedData = {
  泵站: {
    1: [
      {
        id: "1",
        createTime: "2023.03.24",
        status: 1,
      },
    ],
    2: [
      {
        id: "1",
        createTime: "2023.03.24",
        status: 1,
      },
    ],
    3: [
      {
        id: "1",
        createTime: "2023.02.18 18:30:54",
        status: 1,
      },
    ],
  },
  场站: {
    1: [
      {
        id: "1",
        createTime: "2023.03.13",
        status: 1,
      },
    ],
    2: [
      {
        id: "1",
        createTime: "2022.12.01 15:00:36",
        status: 1,
      },
    ],
    3: [
      {
        id: "1",
        createTime: "2023.02.18 18:30:54",
        status: 1,
      },
    ],
  },
  管井: {
    1: [
      {
        id: "1",
        createTime: "2023.03.24",
        status: 1,
      },
    ],
    2: [
      {
        id: "1",
        createTime: "2023.2.16",
        status: 1,
      },
    ],
  },
  监控: {
    1: [
      {
        id: "1",
        createTime: "2023.02.15",
        status: 1,
      },
    ],
    2: [
      {
        id: "1",
        createTime: "2023.02.02 13:23:34",
        status: 1,
      },
    ],
  },
  绿化: {
    1: [
      {
        id: "1",
        createTime: "2022.12.03 13:22:36",
        status: 1,
      },
    ],
    2: [
      {
        id: "1",
        createTime: "2022.11.27 12:12:33",
        status: 1,
      },
    ],
  },
  河道: {
    1: [
      {
        id: "1",
        createTime: "2023.03.24",
        status: 1,
      },
    ],
    2: [
      {
        id: "1",
        createTime: "2023.01.25 10:14:26",
        status: 1,
      },
    ],
    3: [
      {
        id: "1",
        createTime: "2022.12.26 09:22:22",
        status: 1,
      },
    ],
    4: [
      {
        id: "1",
        createTime: "2022.12.03 13:22:36",
        status: 1,
      },
    ],
    5: [
      {
        id: "1",
        createTime: "2023.01.08 16:12:33",
        status: 1,
      },
    ],
    6: [
      {
        id: "1",
        createTime: "2022.09.29 15:23:44",
        status: 1,
      },
    ],
  },
};

/**
 * @description     泵站巡检记录
 */

export const stationsData = {
  2: {
    data1: [
      {
        index: 1,
        project: "进水口",
        patrol: "色度:正常,气味:正常,水量:正常",
        results: "",
        remark: "",
      },
      {
        index: 2,
        project: "格栅",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 3,
        project: "水泵（提升泵、回流 泵、抽吸泵等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 4,
        project: "风机（设备工况、机 油油脂等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 5,
        project: "电控系统（各电器元件、控制线路、运行 参数等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 6,
        project: "加药系统（加药泵、 管路、药量情况等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 7,
        project: "MBR膜系统（膜通 量、负压）",
        patrol: "过滤系统:正常 , 压力表数值:-0.65",
        results: "",
        remark: "调试中",
      },
      {
        index: 8,
        project: "生化系统",
        patrol: "生物膜情况:正常",
        results: "",
        remark: "调试中",
      },
      {
        index: 9,
        project: "环境卫生",
        patrol: "一般",
        results: "",
        remark: "",
      },
      {
        index: 10,
        project: "安全措施（盖板、护栏、指示牌）",
        patrol: "缺失",
        results: "",
        remark: "无指示牌",
      },
      {
        index: 11,
        project: "排放口（水量、颜色、卫生、气味等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
    ],
    data2: [
      {
        index: 12,
        project: "在线数据记录",
        patrol: `累计流量:11689.23m³,COD: 进水 / mg/L 出水 39.04 mg/L,氨氮: 进水 / mg/L 出水 15.06 mg/L,总磷：进水 	/	mg/L 出水 1.1 mg/L,PH: 进水 /	 出水 /`,
        remark: "调试中",
      },
    ],
    data3: [
      {
        index: 13,
        project: "其他",
        today: `1、生化活性污泥浓度检查，液位调整。,2、碳源补充投放，除磷药剂补充。,3、清水池污垢打扫清洗。,4、电控设备运行参数检查，风机滴油 嘴油路油量检查维护。`,
        follow: `1、加强生化碳氮比控制调节。,2、生化活性污泥浓度增殖。,3、及时观察生化活性污泥微生物增殖 情况及微食比。`,
      },
    ],
    image: [
      {
        title: "巡查照片",
        value: [
          require("../../assets/img/excel1_1.png").default,
          require("../../assets/img/excel1_2.png").default,
          require("../../assets/img/excel1_3.png").default,
        ],
        type: 2,
      },
    ],
  },
  3: {
    data1: [
      {
        index: 1,
        project: "进水口",
        patrol: "色度:正常,气味:正常,水量:正常",
        results: "",
        remark: "",
      },
      {
        index: 2,
        project: "格栅",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 3,
        project: "水泵（提升泵、回流 泵、抽吸泵等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 4,
        project: "风机（设备工况、机 油油脂等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 5,
        project: "电控系统（各电器元件、控制线路、运行 参数等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 6,
        project: "加药系统（加药泵、 管路、药量情况等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 7,
        project: "MBR膜系统（膜通 量、负压）",
        patrol: "过滤系统:正常 , 压力表数值:12.3",
        results: "",
        remark: "调试中",
      },
      {
        index: 8,
        project: "生化系统",
        patrol: "生物膜情况:正常",
        results: "",
        remark: "调试中",
      },
      {
        index: 9,
        project: "环境卫生",
        patrol: "一般",
        results: "",
        remark: "",
      },
      {
        index: 10,
        project: "安全措施（盖板、护栏、指示牌）",
        patrol: "缺失",
        results: "",
        remark: "无指示牌",
      },
      {
        index: 11,
        project: "排放口（水量、颜色、卫生、气味等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
    ],
    data2: [
      {
        index: 12,
        project: "在线数据记录",
        patrol: `累计流量:16053.86m³,COD: 进水 / mg/L 出水 42.94 mg/L,氨氮: 进水 / mg/L 出水 43.46 mg/L,总磷：进水 	/	mg/L 出水 1.38 mg/L,PH: 进水 /	 出水 /`,
        remark: "调试中",
      },
    ],
    data3: [
      {
        index: 13,
        project: "其他",
        today: `1、进水提升泵运行检查，检查进水阀门及流量控制。,2、生化污泥回流，污泥回流泵运行检查，生化调节。,3、产水泵水封电机管道阀门及压力表检查。清水池污垢打扫清洗。,4、电控设备运行参数巡检。`,
        follow: `1、生化活性污泥浓度增殖，加强生化污泥控制及碳氮比。,2、药剂补充投放，污泥回流调节。,3、设施设备运行巡检及时控制调整。,4、及时观察水质变化情况及时调整。`,
      },
    ],
    image: [
      {
        title: "巡查照片",
        value: [
          require("../../assets/img/excel2_1.png").default,
          require("../../assets/img/excel2_2.png").default,
          require("../../assets/img/excel2_3.png").default,
        ],
        type: 2,
      },
    ],
  },
  4: {
    data1: [
      {
        index: 1,
        project: "进水口",
        patrol: "色度:正常,气味:正常,水量:正常",
        results: "",
        remark: "",
      },
      {
        index: 2,
        project: "格栅",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 3,
        project: "水泵（提升泵、回流 泵、抽吸泵等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 4,
        project: "风机（设备工况、机 油油脂等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 5,
        project: "电控系统（各电器元件、控制线路、运行 参数等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 6,
        project: "加药系统（加药泵、 管路、药量情况等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 7,
        project: "MBR膜系统（膜通 量、负压）",
        patrol: "过滤系统:正常 , 压力表数值:-0.18",
        results: "",
        remark: "调试中",
      },
      {
        index: 8,
        project: "生化系统",
        patrol: "生物膜情况:正常",
        results: "",
        remark: "调试中",
      },
      {
        index: 9,
        project: "环境卫生",
        patrol: "一般",
        results: "",
        remark: "",
      },
      {
        index: 10,
        project: "安全措施（盖板、护栏、指示牌）",
        patrol: "缺失",
        results: "",
        remark: "无指示牌",
      },
      {
        index: 11,
        project: "排放口（水量、颜色、卫生、气味等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
    ],
    data2: [
      {
        index: 12,
        project: "在线数据记录",
        patrol: `累计流量:13035.05m³,COD: 进水 / mg/L 出水 7.29 mg/L,氨氮: 进水 / mg/L 出水 10.63 mg/L,总磷：进水 	/	mg/L 出水 4.12 mg/L,PH: 进水 /	 出水 /`,
        remark: "调试中",
      },
    ],
    data3: [
      {
        index: 13,
        project: "其他",
        today: `1、MBR 膜池污泥浓污泥回流。,2、进水流量及液位检查调整维护。,3、产水泵水封电机管道阀门巡检。,4、生化曝气及风机滴油嘴油量检查。,5、除磷药剂补充投放。`,
        follow: `1、生化活性污泥浓度增殖，污泥回流比例控制。,2、碳源补充投放，MBR 膜药剂反洗。,3、格栅垃圾打捞清理疏通维护。,4、设施设备运行参数巡检维护。`,
      },
    ],
    image: [
      {
        title: "巡查照片",
        value: [
          require("../../assets/img/excel3_1.png").default,
          require("../../assets/img/excel3_2.png").default,
          require("../../assets/img/excel3_3.png").default,
          require("../../assets/img/excel3_4.png").default,
        ],
        type: 2,
      },
    ],
  },
  5: {
    data1: [
      {
        index: 1,
        project: "进水口",
        patrol: "色度:正常,气味:正常,水量:正常",
        results: "",
        remark: "",
      },
      {
        index: 2,
        project: "格栅",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 3,
        project: "水泵（提升泵、回流 泵、抽吸泵等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 4,
        project: "风机（设备工况、机 油油脂等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 5,
        project: "电控系统（各电器元件、控制线路、运行 参数等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 6,
        project: "加药系统（加药泵、 管路、药量情况等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
      {
        index: 7,
        project: "MBR膜系统（膜通 量、负压）",
        patrol: "过滤系统:正常 , 压力表数值:-9.34",
        results: "",
        remark: "调试中",
      },
      {
        index: 8,
        project: "生化系统",
        patrol: "生物膜情况:正常",
        results: "",
        remark: "调试中",
      },
      {
        index: 9,
        project: "环境卫生",
        patrol: "一般",
        results: "",
        remark: "",
      },
      {
        index: 10,
        project: "安全措施（盖板、护栏、指示牌）",
        patrol: "缺失",
        results: "",
        remark: "无指示牌",
      },
      {
        index: 11,
        project: "排放口（水量、颜色、卫生、气味等）",
        patrol: "正常",
        results: "",
        remark: "",
      },
    ],
    data2: [
      {
        index: 12,
        project: "在线数据记录",
        patrol: `累计流量:4995m³,COD: 进水 241.38 mg/L 出水 25.79 mg/L,氨氮: 进水 / mg/L 出水 40.51 mg/L,总磷：进水 	/	mg/L 出水 1.77 mg/L,PH: 进水 7.73	 出水 7.73`,
        remark: "调试中",
      },
    ],
    data3: [
      {
        index: 13,
        project: "其他",
        today: `1、液位控制浮球开关更换调试。,2、MBR膜药剂反洗。,3、产水泵水封电机管道阀门检查维护。,4、生化曝气及活性污泥浓度检查调节，污泥回流。`,
        follow: `1、加强进水流量控制及生化停留时间。,2、生化活性污泥浓度增殖。,3、液位控制检查。`,
      },
    ],
    image: [
      {
        title: "巡查照片",
        value: [
          require("../../assets/img/excel4_1.png").default,
          require("../../assets/img/excel4_2.png").default,
          require("../../assets/img/excel4_3.png").default,
        ],
        type: 2,
      },
    ],
  },
};
