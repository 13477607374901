
/**
 * @description           表单
 */

/**
 * @description 泵站巡检
 */

export const PumpStationExcel1Data = [
  {
    title: "整体外观",
    list: [
      {
        patrol_name: "围栏",
        detail: "围栏是否坚固、完整",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "绿化",
        detail: "草坪成活率、修剪及杂草清理情况",
        status_txt: "泵井周边有杂草",
        results: "已清理",
        remark: "-",
      },
      {
        patrol_name: "控制柜",
        detail: "控制柜门、柜体是否倾斜、裂缝、破损变形等现象",
        status_txt: "控制柜门缝隙大",
        results: "已进行调整",
        remark: "-",
      },
      {
        patrol_name: "安全标识",
        detail: "安全标识是否齐全且张贴位置正确",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "溢流口",
        detail: "是否堵塞、是否倒灌",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "环境卫生",
        detail: "现场、格栅、池内垃圾及周围环境干净",
        status_txt: "格栅垃圾",
        results: "清理",
        remark: "-",
      },
    ]
  },
  {
    title: "泵井筒体",
    list: [
      {
        patrol_name: "提升泵",
        detail: "水泵是否空抽、是否脱落、渗漏",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "提升泵",
        detail: "水泵运行电流、电压、振动、出水量是否正常",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "电机",
        detail: "电机的绝缘电阻是否符合要求",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "电机",
        detail: "电机连接的电缆线是否破损、断裂等",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "耦合",
        detail: "耦合、导轨是否牢固、松动等现象",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "提篮格栅",
        detail: "提篮式格栅是否变形、生锈",
        status_txt: "提篮格栅生锈",
        results: "已通知厂家处理",
        remark: "-",
      },
      {
        patrol_name: "浮球/液位",
        detail: "浮球、液位计是否损坏，显示不准确",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "阀门/单向阀",
        detail: "各阀门是否生锈、损坏、松动、堵塞等",
        status_txt: "阀门/单向阀生锈",
        results: "生锈部分已处理",
        remark: "-",
      },
      {
        patrol_name: "风机",
        detail: "风机运行正常无损坏",
        status_txt: "未安装风机",
        results: "配件申购中",
        remark: "-",
      },
      {
        patrol_name: "电缆线",
        detail: "设备连接的电缆线是否破损、断裂等",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "叶轮",
        detail: "水泵叶轮是否有异物， 能否灵活地转动",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
    ]
  },
  {
    title: "电气系统",
    list: [
      {
        patrol_name: "电线/端子排",
        detail: "控制柜内的电线及接线端子，是否存在发热变色等情况",
        status_txt: "接线端子排松动",
        results: "加固",
        remark: "-",
      },
      {
        patrol_name: "显示屏",
        detail: "屏幕是否正常、各参数设置是否正确",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "指示灯/按钮",
        detail: "控制柜面板指示灯、按钮、转换开关是否正常",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "PLC",
        detail: "PLC运行正常，指示灯无报警闪烁",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "控制开关",
        detail: "断路器、接触器、热继是否有烧焦、损坏、跳闸等现象",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "元器件",
        detail: "元器件是否损坏，显示参数准确，运行正常",
        status_txt: "通信故障",
        results: "已恢复",
        remark: "-",
      },
      {
        patrol_name: "电缆/表箱",
        detail: "外观、套管无破损、无褶皱；电表箱无损坏，各开关可正常使用",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "内部构件",
        detail: "干净整洁，保证控制柜密闭性符合要求",
        status_txt: "控制柜内有蚂蚁窝",
        results: "已清理",
        remark: "-",
      },
    ]
  },
  {
    title: "截留井",
    list: [
      {
        patrol_name: "截留井",
        detail: "截留井格栅无破损、变形",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "截留井",
        detail: "池底、管道、护坝无损坏、堵塞水流通畅",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
    ]
  },
  {
    title: "压力管",
    list: [
      {
        patrol_name: "压力管",
        detail: "压力管无堵塞、渗漏、破损等，正常排水",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
    ]
  },
]

/**
 * @description         泵站维修
 */

export const PumpStationExcel2Data = [
  {
    title: "整体外观",
    list: [
      {
        name: "围栏",
        details: "围栏是否坚固、完整",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "绿化",
        details: "草坪成活率、修剪及杂草清理情况",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "控制柜",
        details: "控制柜门、柜体是否倾斜、裂缝、破损变形等现象",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "安全标识",
        details: "安全标识是否齐全且张贴位置正确",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "溢流口",
        details: "是否堵塞、是否倒灌",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "环境卫生",
        details: "现场、格栅、池内垃圾及周围环境干净",
        state: 1,
        results: "-",
        remark: "-",
      },
    ]
  },
  {
    title: "泵井筒体",
    list: [
      {
        name: "提升泵",
        details: "水泵是否空抽、是否脱落、渗漏",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "提升泵",
        details: "水泵运行电流、电压、振动、出水量是否正常",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "电机",
        details: "电机的绝缘电阻是否符合要求",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "电机",
        details: "电机连接的电缆线是否破损、断裂等",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "耦合",
        details: "耦合、导轨是否牢固、松动等现象",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "提篮格栅",
        details: "提篮式格栅是否变形、生锈",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "浮球/液位",
        details: "浮球、液位计是否损坏，显示不准确",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "阀门/单向阀",
        details: "各阀门是否生锈、损坏、松动、堵塞等",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "风机",
        details: "风机运行正常无损坏",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "电缆线",
        details: "设备连接的电缆线是否破损、断裂等",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "叶轮",
        details: "水泵叶轮是否有异物， 能否灵活地转动",
        state: 1,
        results: "-",
        remark: "-",
      },
    ]
  },
  {
    title: "电气系统",
    list: [
      {
        name: "电线/端子排",
        details: "控制柜内的电线及接线端子，是否存在发热变色等情况",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "显示屏",
        details: "屏幕是否正常、各参数设置是否正确",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "指示灯/按钮",
        details: "控制柜面板指示灯、按钮、转换开关是否正常",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "PLC",
        details: "PLC运行正常，指示灯无报警闪烁",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "控制开关",
        details: "断路器、接触器、热继是否有烧焦、损坏、跳闸等现象",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "元器件",
        details: "元器件是否损坏，显示参数准确，运行正常",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "电缆/表箱",
        details: "外观、套管无破损、无褶皱；电表箱无损坏，各开关可正常使用",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "内部构件",
        details: "干净整洁，保证控制柜密闭性符合要求",
        state: 1,
        results: "-",
        remark: "-",
      },
    ]
  },
  {
    title: "截留井",
    list: [
      {
        name: "截留井",
        details: "截留井格栅无破损、变形",
        state: 1,
        results: "-",
        remark: "-",
      },
      {
        name: "截留井",
        details: "池底、管道、护坝无损坏、堵塞水流通畅",
        state: 1,
        results: "-",
        remark: "-",
      },
    ]
  },
  {
    title: "压力管",
    list: [
      {
        name: "压力管",
        details: "压力管无堵塞、渗漏、破损等，正常排水",
        state: 1,
        results: "-",
        remark: "-",
      },
    ]
  },
]

/**
 * @description         管网巡检
 */

export const NetworkExcel1Data = [
  {
    title: "路面管道",
    list: [
      {
        patrol_name: "路面",
        detail: "混凝土路面是否出现错台、沉降、开裂等情况",
        status_txt: "苏塘村公交站边路面塌陷",
        results: "已通知项目部处理",
        remark: "-"

      },
      {
        patrol_name: "管道",
        detail: "管道是否塌陷、破损、下沉等",
        status_txt: "正常",
        results: "-",
        remark: "-"

      },
      {
        patrol_name: "管道内部",
        detail: "管道内水流是否正常流动、无堵塞、无淤积等",
        status_txt: "正常",
        results: "-",
        remark: "-"

      },
      {
        patrol_name: "管道内部",
        detail: "管道内是否出现爆管、开裂等",
        status_txt: "正常",
        results: "-",
        remark: "-"

      },
    ]
  },
  {
    title: "检查井外部",
    list: [
      {
        patrol_name: "检查井",
        detail: "污水是否冒溢",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {
        patrol_name: "检查井",
        detail: "检查井是否无复合内盖或内盖破损等情况",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {
        patrol_name: "井盖和井框",
        detail: "井框盖是否变形、破损或被埋没",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {
        patrol_name: "井盖和井框",
        detail: "井盖和井框之间高差和间隙是否超限",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {
        patrol_name: "井盖和井框",
        detail: "检查井井周混凝土是否出现爆模、塌陷、开裂等情况",
        status_txt: "泵井5#前端检查井开裂，农田水倒灌；",
        results: "已处理恢复正常",
        remark: "未修复"
      },
      {
        patrol_name: "井盖和井框",
        detail: "井盖标识是否错误",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {
        patrol_name: "井盖和井框",
        detail: "井盖和井框之间是否突出、凹陷、跳动或有声响",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
    ]
  },
  {
    title: "检查井内部",
    list: [
      {

        patrol_name: "井壁",
        detail: "井壁是否存在泥垢、裂缝、渗漏或抹面脱落等",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {

        patrol_name: "井底",
        detail: "井底是否存在积泥",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {

        patrol_name: "井座和井筒",
        detail: "井座及井筒是否受压出现破裂等情况",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {
        patrol_name: "井内",
        detail: "水位和流向是否正常，是否存在雨污混接",
        status_txt: "正常",
        results: "-",
        remark: "-"
      },
      {
        patrol_name: "井内",
        detail: "是否存在违章排放、私自接管等",
        status_txt: "正常",
        results: "-",
        remark: "-"

      },
      {
        patrol_name: "防坠设施",
        detail: "是否缺失、破损，是否存有垃圾、杂物",
        status_txt: "正常",
        results: "-",
        remark: "-"

      },
    ]
  },
  {
    title: "入户管",
    list: [
      {

        patrol_name: "入户管",
        detail: "入户管明露部分是否存在出现破损",
        status_txt: "泵井7#边上接户管破损",
        results: "已通知项目部处理",
        remark: "未修复",
      },
      {
        patrol_name: "入户管",
        detail: "入户管是否存在连接不到位，出现接口与管段脱离情况",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "入户管",
        detail: "入户管混凝土路面恢复是否出现沉降、开裂等情况",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "入户管",
        detail: "入户管内水流是否正常流动、出水口是否出现堵塞情况",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "入户管",
        detail: "埋地入户管是否出现管体外露等情况",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "入户管",
        detail: "入户管混凝土包封部分是否存在，部分段落外露包封不到位情况",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
      {
        patrol_name: "入户管",
        detail: "入户管外挂部分卡箍是否固定牢固，坡度是否出现异常",
        status_txt: "正常",
        results: "-",
        remark: "-",
      },
    ]
  },
]
/**
 * @description         管网维修
 */

export const NetworkExcel2Data = [
  {
    title: "路面管道",
    list: [
      {
        name: "路面",
        children: [
          {
            content: "混凝土路面是否出现错台、沉降、开裂等情况",
            state: "正常",
            results: "-",
            remark: "-"
          }
        ],
      },
      {
        name: "管道",
        children: [
          {
            content: "管道是否塌陷、破损、下沉等",
            state: "正常",
            results: "-",
            remark: "-"
          }
        ],
      },
      {
        name: "管道内部",
        children: [
          {
            content: "管道内水流是否正常流动、无堵塞、无淤积等",
            state: "正常",
            results: "-",
            remark: "-"
          },
          {
            content: "管道内是否出现爆管、开裂等",
            state: "正常",
            results: "-",
            remark: "-"
          }
        ],
      },
    ]
  },
  {
    title: "检查井外部",
    list: [
      {
        name: "检查井",
        children: [
          {
            content: "污水是否冒溢",
            state: "正常",
            results: "-",
            remark: "-"
          },
          {
            content: "检查井是否无复合内盖或内盖破损等情况",
            state: "正常",
            results: "-",
            remark: "-"
          },
        ],
      },
      {
        name: "井盖和井框",
        children: [
          {
            content: "井框盖是否变形、破损或被埋没",
            state: "正常",
            results: "-",
            remark: "-"
          },
          {
            content: "井盖和井框之间高差和间隙是否超限",
            state: "正常",
            results: "-",
            remark: "-"
          },
          {
            content: "检查井井周混凝土是否出现爆模、塌陷、开裂等情况",
            state: "正常",
            results: "-",
            remark: "-"
          },
          {
            content: "井盖标识是否错误",
            state: "正常",
            results: "-",
            remark: "-"
          },
          {
            content: "井盖和井框之间是否突出、凹陷、跳动或有声响",
            state: "正常",
            results: "-",
            remark: "-"
          },
        ],
      }
    ]
  },
  {
    title: "检查井内部",
    list: [
      {
        name: "井壁",
        children: [
          {
            content: "井壁是否存在泥垢、裂缝、渗漏或抹面脱落等",
            state: "正常",
            results: "-",
            remark: "-"
          }
        ],
      },
      {
        name: "井底",
        children: [
          {
            content: "井底是否存在积泥",
            state: "正常",
            results: "-",
            remark: "-"
          }
        ],
      },
      {
        name: "井座和井筒",
        children: [
          {
            content: "井座及井筒是否受压出现破裂等情况",
            state: "正常",
            results: "-",
            remark: "-"
          }
        ],
      },
      {
        name: "井内",
        children: [
          {
            content: "水位和流向是否正常，是否存在雨污混接",
            state: "正常",
            results: "-",
            remark: "-"
          },
          {
            content: "是否存在违章排放、私自接管等",
            state: "正常",
            results: "-",
            remark: "-"
          },
        ],
      },
      {
        name: "防坠设施",
        children: [
          {
            content: "是否缺失、破损，是否存有垃圾、杂物",
            state: "正常",
            results: "-",
            remark: "-"
          }
        ],
      },
    ]
  },
  {
    title: "入户管",
    list: [
      {
        name: "入户管",
        children: [
          {
            content: "入户管明露部分是否存在出现破损",
            state: "正常",
            results: "-",
            remark: "-",
          },
          {
            content: "入户管是否存在连接不到位，出现接口与管段脱离情况",
            state: "正常",
            results: "-",
            remark: "-",
          },
          {
            content: "入户管混凝土路面恢复是否出现沉降、开裂等情况",
            state: "正常",
            results: "-",
            remark: "-",
          },
          {
            content: "入户管内水流是否正常流动、出水口是否出现堵塞情况",
            state: "正常",
            results: "-",
            remark: "-",
          },
          {
            content: "埋地入户管是否出现管体外露等情况",
            state: "正常",
            results: "-",
            remark: "-",
          },
          {
            content: "入户管混凝土包封部分是否存在，部分段落外露包封不到位情况",
            state: "正常",
            results: "-",
            remark: "-",
          },
          {
            content: "入户管外挂部分卡箍是否固定牢固，坡度是否出现异常",
            state: "正常",
            results: "-",
            remark: "-",
          },
        ]
      }
    ]
  },
]

/**
 * @description       河道巡检
 */

export const RiverExcel1Data = [
  {
    index: 1,
    project: "水质保护巡查（无排水口，无未经批准擅自新建、改建、扩大排污口等情况；饮用水源地无新增污染源及其他违法行为。）",
    patrol: "巡查发现笏石溪九龙寺边排水管破损（已通知项目部处理）",
    results: "已更换破损的管件，恢复正常。",
    remark: "-"
  },
  {
    index: 2,
    project: "岸线巡查（重点巡查无侵占河道、围垦湖泊、非法采砂、乱搭乱建以及乱占滥用、多占少用、占而不用岸线等突出问题。）",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 3,
    project: "水污染防治巡查（重点巡查无新增入河湖（库）污染源、工矿企业污染、城镇生活污染、畜禽养殖污染、水产投肥养殖、船舶污染等情况。）",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 4,
    project: "水环境保护巡查（重点巡查无新增黑臭水体，无饮用水源地安全隐患，河湖（库）沿岸无垃圾乱堆乱弃，污水乱排乱放，河湖（库）水面无成片漂浮物浮萍、水草、垃圾）等情况。）",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 5,
    project: "水生态安全巡查（重点巡查无网箱养殖、矮围网围、电毒炸鱼及侵占自然河湖、湿地等水源涵养空间等情况。）",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 6,
    project: "其他有关污染河湖水质（破坏水环境、影响防洪、通航、供水、生态安全等情况）",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 7,
    project: "工程：护岸、防洪通道、人行道、护坡等",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 8,
    project: "保洁（绿化、硬化、背水坡、水面卫生）",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 9,
    project: "电气设备（曝气机、照明灯、电缆电线等）",
    patrol: "曝气机电源线未接（已通知）",
    results: "暂未修复",
    remark: "-"
  },
  {
    index: 10,
    project: "倾倒垃圾",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
  {
    index: 11,
    project: "护栏、警示牌（安装、更换、缺失、损坏）",
    patrol: "正常",
    results: "-",
    remark: "-"
  },
]

/**
 * @description       河道安全
 */

export const RiverExcel3Data = [
  {
    index: 1,
    project: "站点标识标牌完整",
    patrol: "-",
    results: "-"
  },
  {
    index: 2,
    project: "绿化苗木无倒塌",
    patrol: "-",
    results: "-"
  },
  {
    index: 3,
    project: "设备盖板有挂锁",
    patrol: "-",
    results: "-"
  },
  {
    index: 4,
    project: "河道无塌陷、断裂",
    patrol: "-",
    results: "-"
  },
  {
    index: 5,
    project: "巡查井无沉降破损",
    patrol: "-",
    results: "-"
  },
  {
    index: 6,
    project: "园路面无严重破损",
    patrol: "-",
    results: "-"
  },
  {
    index: 7,
    project: "站点电线不裸露",
    patrol: "-",
    results: "-"
  },
  {
    index: 8,
    project: "无私拉乱接用电现象",
    patrol: "-",
    results: "-"
  },
]

/**
 * @description       河道防汛
 */

export const RiverExcel4Data = [
  {
    index: 1,
    project: "设备柜、电柜与底座固定牢固；顶部及侧面，无破损变形及其他可能引起漏水、漏雨的情况；",
    patrol: "-",
  },
  {
    index: 2,
    project: "中控箱、电柜箱的门已关闭，上锁。",
    patrol: "-",
  },
  {
    index: 3,
    project: "巡查井盖安装牢固，井盖周围是无溢水的痕迹。",
    patrol: "-",
  },
  {
    index: 4,
    project: "水闸开启",
    patrol: "-",
  },
  {
    index: 5,
    project: "巡查排涝泵，以人工点动的形式进行故障排除。",
    patrol: "-",
  },
  {
    index: 6,
    project: "现场有条件设置重力流沟渠，使溢流的污水引流至附近河道水系。",
    patrol: "-",
  },
  {
    index: 7,
    project: "现场有条件设置防倒灌围堰。",
    patrol: "-",
  },
  {
    index: 8,
    project: "在以往的洪涝年里，站点附近曾出现过雨水漫灌的现象?",
    patrol: "-",
  },
]

/**
 * @description       河道养护
 */

export const RiverExcel5Data = [
  {
    index: 1,
    content: "绿化养护",
    position: "笏石溪桥头",
    describe: "现场杂草的清理、浇水、修剪等",
    personnel: "-",
    remark: "-"
  }
]