import GeneralChart from "../../general/generalChart";

interface Props {
  dataSource: {
    chartData: any[];
    chartId: number;
    totalData: {
      count: number;
      title: string;
    };
    detailsData: {
      total: number;
      processing: number;
      processingTitle: string;
      processingUnit: string;
    };
  };
}

const ChartContent = ({ dataSource }: Props) => {
  const {
    chartData,
    chartId,
    totalData: { count, title },
    detailsData: { total, processing, processingTitle, processingUnit },
  } = dataSource;
  return (
    <div className="chart_content">
      {chartData.length > 0 && (
        <GeneralChart dataSource={chartData} id={chartId} outRadius={60} />
      )}
      <div className="total_box">
        <div className="number">{count}</div>
        <div className="title">{title}</div>
        <div className="unit">个</div>
      </div>
      <div className="details_box">
        <div>
          <div>{total}</div>
          <div>在线数量 (个)</div>
        </div>
        <div>
          <div>{processing}</div>
          <div>
            {processingTitle} ({processingUnit})
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartContent;
