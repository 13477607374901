import { Col, Row } from "antd";

interface Props {
  dataSource: any[];
}

const ExcelStyle4 = ({ dataSource }: Props) => {
  return (
    <>
      {dataSource.map((item, i) => {
        return (
          <Row key={i}>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div className="excel_title">{item.title}</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {
                    //判断是否为图片内容(1：文本内容，2：图片内容)
                    item.type === 1 ? (
                      <div className="excel_text2">{item.value}</div>
                    ) : (
                      <div className="excel_image">
                        {item.value.length > 0
                          ? item.value.map((item) => <img src={item} />)
                          : "暂无照片"}
                      </div>
                    )
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default ExcelStyle4;
