import { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import "./index.scss";

interface Props {
  data: any;
}

const ThreeModel = ({ data }: Props) => {
  const Ref = useRef(null);
  let Scene = useRef(null);

  const [inCOD, setIn] = useState("");
  const [outCOD, setOut] = useState("");

  const init = () => {
    Scene.current = new THREE.Scene();
    const light = new THREE.DirectionalLight();
    light.position.set(3, 3, 3);
    const ambient = new THREE.AmbientLight(0xffffff, 0.5);
    Scene.current.add(light);
    Scene.current.add(ambient);

    const loader = new GLTFLoader(); //创建一个GLTF加载器
    loader.load("gltf/pipeline.gltf", (date) => {
      Scene.current.add(date.scene);
      date.scene.translateX(-6);
    });

    const width = Ref.current.offsetWidth; //窗口宽度
    const height = Ref.current.offsetHeight; //窗口高度
    const k = width / height; //窗口宽高比
    const camera = new THREE.OrthographicCamera(
      -11,
      11,
      11 / k,
      -11 / k,
      1,
      100
    );
    camera.position.set(-1.5, 7, 15); //设置相机位置
    camera.lookAt(Scene.current.position);

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    const controller = new OrbitControls(camera, renderer.domElement);

    renderer.setSize(width, height); //设置渲染区域尺寸
    renderer.setClearColor(0x708091, 1); //设置背景颜色
    renderer.setPixelRatio(2);
    renderer.setAnimationLoop(() => {
      //执行渲染操作   指定场景、相机作为参数
      renderer.render(Scene.current, camera);
      controller.update();
    });
    Ref.current.appendChild(renderer.domElement); //body元素中插入canvas对象
  };

  const addText = () => {
    const fontLoad = new FontLoader();
    fontLoad.load("config/helvetiker_regular.typeface.json", (font) => {
      const matLite = new THREE.MeshBasicMaterial({
        color: 0x000000,
        side: THREE.DoubleSide,
      });
      const shapes1 = font.generateShapes(inCOD, 10);
      const geometry1 = new THREE.ShapeGeometry(shapes1);
      const text1 = new THREE.Mesh(geometry1, matLite);
      text1.scale.set(0.02, 0.02, 0.02);
      text1.position.set(-10.1, 1.2, -0.1);
      const shapes2 = font.generateShapes(outCOD, 10);
      const geometry2 = new THREE.ShapeGeometry(shapes2);
      const text2 = new THREE.Mesh(geometry2, matLite);
      text2.scale.set(0.02, 0.02, 0.02);
      text2.position.set(10.1, 1.6, -0.1);
      Scene.current.add(text1, text2);
    });
  };

  const removeText = () => {
    Scene.current.children.forEach((item) => {
      if (item.type === "Mesh") {
        Scene.current.remove(item);
      }
    });
    addText();
  };

  useEffect(() => {
    if (Ref.current) {
      init();
    }
  }, []);

  useEffect(() => {
    const webSocketUrl = `${process.env.REACT_APP_WSS_API}/msg/${data.code}`;
    const ws = new WebSocket(webSocketUrl);
    ws.onopen = (e) => {
      console.log("连接成功2");
    };
    ws.onmessage = (e) => {
      const newData = JSON.parse(e.data);
      if (data.yardType === 1) {
        newData.forEach((element) => {
          if (element.name === "COD") {
            setIn(element.in.value + element.in.unit);
            setOut(element.out.value + element.out.unit);
          }
        });
      }
    };
    return () => {
      ws.close();
    };
  }, [data.code]);

  useEffect(() => {
    if (inCOD || outCOD) {
      removeText();
    } else {
      addText();
    }
  }, [inCOD, outCOD]);

  return <div className="three_model" ref={Ref} />;
};

export default ThreeModel;
