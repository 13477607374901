import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle4 from "../../general/excelStyle4";

interface Props {
  dataSource: any;
}

/**
 * @description         河道河床检查记录
 */

const RiverExcel2 = ({ dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "河道名称",
              value: dataSource.title,
            },
            {
              title: "巡查日期",
              value: dataSource.time,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "桩号",
              value: "***",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle4
          dataSource={[
            {
              title: "冲刷情况",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "河道障碍物情况",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "废弃物情况",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "淤积情况",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "其他",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "巡查结论",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "审核（处理）意见",
              value: "暂未填写",
              type: 1,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "巡查人",
              value: "",
            },
            {
              title: "审核人",
              value: "",
            },
          ]}
        />
      </div>
    </>
  );
};

export default RiverExcel2;
