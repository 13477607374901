import { PtChildTitle, PtSearch } from "../../../../../components";
import { PlanList } from "../../../data";
import GeneralCollapse from "../../general/generalCollapse";

interface Props {
  activeData: any;
  setActiveData: (value) => void;
}

const Emergency1Left = ({ activeData, setActiveData }: Props) => {
  return (
    <div>
      <PtChildTitle title={"预案列表"} />
      {/* <PtSearch onChange={(value) => {}} /> */}
      <div className="emergency1_left_list">
        {PlanList.map((item, i) => (
          <GeneralCollapse
            key={i}
            dataSource={item}
            rightVisible={false}
            activeData={activeData}
            onClick={(value, type) => setActiveData(value)}
          />
        ))}
      </div>
    </div>
  );
};

export default Emergency1Left;
