import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./page/home";
import Login from "./page/login";
class Routers extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/home" component={HomePage} />
        </Switch>
      </Router>
    );
  }
}

export default Routers;
