import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle4 from "../../general/excelStyle4";

interface Props {
  dataSource: any;
}

/**
 * @description           绿化巡检记录
 */

const GreeningExcel1 = ({ dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "站点名称",
              value: dataSource.title,
            },
            {
              title: "巡检日期",
              value: dataSource.time,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "植被种类",
              value: "***",
            },
            {
              title: "巡检人员",
              value: "闫固咏",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle4
          dataSource={[
            {
              title: "问题描述",
              value: "植被巡检无任何异常情况。",
              type: 1,
            },
            {
              title: "现场照片",
              value: [],
              type: 2,
            },
          ]}
        />
      </div>
    </>
  );
};

export default GreeningExcel1;
