import { useEffect } from "react";
import { Select, Tree } from "antd";
import { useState } from "react";
import { PtIcon, PtTab } from "../../../../../components";
import { operationsTabs, SimulatedData, TimeList } from "../../../data";
import GeneralList from "../../general/generalList";
import Information from "./information";
import axios from "axios";

interface Props {
  leftType: string;
  leftData: any;
  leftTabValue: string;
  leftActiveData: any;
  activeData: any;
  setActiveData: (value, type) => void;
}

const a = ["泵站", "场站", "管井"];
const { TreeNode } = Tree;

const OperationsRight = ({
  leftType,
  leftData,
  leftTabValue,
  leftActiveData,
  activeData,
  setActiveData,
}: Props) => {
  const [tabValue, setTabValue] = useState(1);

  const [activeTime, setActiveTime] = useState(1);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  
  const getData = () => {
    switch (tabValue) {
      case 1:
        axios
        .get(
          `/work_order/show_by_area/${leftTabValue}`,
          {params:{areaCode: leftActiveData.areaCode , dateScope:activeTime}}
        )
        .then((res: any) => {
          if (res.code === 200) {
            const {data} = res
            const ids = []
            data.forEach((item , index) =>{
              item.id = item.name + index
              ids.push(item.id)
              item.children && item.children.forEach(child =>{
                child.id = child.name + '—' + index 
                ids.push(child.id)
              })
            })
            setExpandedKeys([...ids])
            setDataSource(data);
          }
        });
        break;

      default:
        setDataSource([]);
        break;
    }
  };

  const treeSelect = (selectedKeys, e) => {
    const value = e.node.data;
    console.log(e.node);
    
    if (value) {
      console.log(value)
      setActiveData(value, tabValue)
    } else {
      const newKeys = [...expandedKeys];
      const index = newKeys.indexOf(e.node.key);
      if (index !== -1) {
        newKeys.splice(index, 1);
      } else {
        newKeys.push(e.node.key);
      }
      setExpandedKeys(newKeys);
    }
  };

  const renderTreeNodes = (data, parent?) => {
    return data.map((item: any) => {
      if (item.children || item.list) {
        return (
          <TreeNode
            title={() => renderTreeTitle(item)}
            key={item.id}
            isLeaf={false}
          >
            {renderTreeNodes(
              item.list ? item.list : item.children,
              item
            )}
          </TreeNode>
        );
      }
      if (parent) {
        return (
          <TreeNode
            title={() => renderTreeTitle(item)}
            key={item.id}
            data-parentKey={parent.id}
            data={item}
            isLeaf={true}
          />
        );
      }
    });
  };

  const getLen = (item: any) => {
    let count = 0;

    if (item.list && item.list.length) {
      count += item.list.length;
    }
    if (item.children && item.children.length) {
      item.children.forEach(child => {
        count += getLen(child);
      });
    }
    return count;
  }

  const renderTreeTitle = (item: any) => {
		let count = getLen(item)
		return item.children || item.list ? (
			<span
				style={{
					color: "#fff"
				}}
			>
				{item.name}({count})
			</span>
		) : (
			<div
				className={`tree_title  hover_success`}
			>
				<div className="item_l">
          {/* {item.deviceName} */}
          <span style={{marginLeft:'10px'}}>{item.patrolStartTime}</span>
        </div>
				<div className="item_r">
          
          <div className={`state_box ${item.status ? "success" : "warning"}`}>
            {
              item.status
              ? "正常"
              : "异常"
            }
          </div>
				</div>
			</div>
		)
	}

  useEffect(() => {
    getData();
  }, [activeTime]);

  useEffect(() => {
    getData();
  }, [leftActiveData.id, tabValue]);

  useEffect(() => {
    setActiveData({}, tabValue);
  }, [tabValue]);

  return (
    <div className="operations_right">
      <PtTab
        data={operationsTabs[leftType]}
        value={tabValue}
        onChange={setTabValue}
      />
      <div className="right_content">
        {tabValue === 4 && a.includes(leftType) ? (
          <Information dataSource={leftActiveData} type={leftType} />
        ) : (
          <>
            <GeneralList className="content_head">
              <div className="select_box">
                <Select
                  size="small"
                  bordered={false}
                  getPopupContainer={(triggerNode) => triggerNode}
                  options={TimeList}
                  value={activeTime}
                  onChange={(value) => setActiveTime(value)}
                />
              </div>
              <PtIcon className="icon-xiazaiicon1" onClick={() => {}} />
            </GeneralList>
            <div className="content_body customScrollBars">
              <Tree
                blockNode
                onSelect={treeSelect}
                onExpand={treeSelect}
                expandedKeys={expandedKeys}
              >
                {renderTreeNodes(dataSource)}
              </Tree>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OperationsRight;
