import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle5 from "../../general/excelStyle5";
import { RiverExcel1Data } from "../data";

interface Props {
  dataSource: any;
}

/**
 * @description         河道巡检记录
 */

const RiverExcel1 = ({ dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "河道名称",
              value: dataSource.title,
            },
            {
              title: "巡检日期",
              value: dataSource.time,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "巡查范围",
              value: "笏石溪河道",
            },
            {
              title: "巡检人员",
              value: "刘昌武",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "序号", span: 2 },
            { title: "巡查项目", span: 8 },
            { title: "巡查情况", span: 6 },
            { title: "处理情况", span: 5 },
            { title: "备注", span: 3 },
          ]}
        />
        <ExcelStyle5
          columns={[
            { title: "序号", span: 2, dataIndex: "index" },
            { title: "巡查项目", span: 8, dataIndex: "project" },
            { title: "巡查情况", span: 6, dataIndex: "patrol" },
            { title: "处理情况", span: 5, dataIndex: "results" },
            { title: "备注", span: 3, dataIndex: "remark" },
          ]}
          dataSource={RiverExcel1Data}
        />
      </div>
    </>
  );
};

export default RiverExcel1;
