/**
 * 此文件用于地图初始化文件配置，
 * 包括初始化图层、连廓线、道路（对应的点位坐标）
 */
const CLOUD = (window as any)["env"];


/**
 * wmts 图层信息，默认黑色风格
 */
export const mapLsyer = [
  {
    name: "云图(黑)",
    type: "YUNTUHEI",
    zIndex: 3,
    url: CLOUD.wmsLocal,
    format: "image/png",
    style: "",
    matrixSet: "GoogleCRS84Quad-22",
    layer: "putian:putian_dtz",
    resolutions: [
      1.4062499999999998, 0.7031249999999999, 0.3515624999999999,
      0.17578124999999997, 0.08789062499999999, 0.04394531249999999,
      0.021972656249999997, 0.010986328124999997, 0.0054931640625,
      0.0027465820312499987, 0.0013732910156249998, 6.866455078124998e-4,
      3.4332275390625e-4, 1.7166137695312497e-4, 8.583068847656249e-5,
      4.291534423828124e-5, 2.145767211914062e-5, 1.072883605957031e-5,
      5.364418029785155e-6,
    ],
    matrixIds: [
      "GoogleCRS84Quad:0",
      "GoogleCRS84Quad:1",
      "GoogleCRS84Quad:2",
      "GoogleCRS84Quad:3",
      "GoogleCRS84Quad:4",
      "GoogleCRS84Quad:5",
      "GoogleCRS84Quad:6",
      "GoogleCRS84Quad:7",
      "GoogleCRS84Quad:8",
      "GoogleCRS84Quad:9",
      "GoogleCRS84Quad:10",
      "GoogleCRS84Quad:11",
      "GoogleCRS84Quad:12",
      "GoogleCRS84Quad:13",
      "GoogleCRS84Quad:14",
      "GoogleCRS84Quad:15",
      "GoogleCRS84Quad:16",
      "GoogleCRS84Quad:17",
      "GoogleCRS84Quad:18",
    ],
    imgUrl: "./images/skin/blackCloud.png",
  },
  {
    url: CLOUD.gaodeMap,
  },
  {
    url: CLOUD.zhengshe,
  },
  {
    name: "天地图(影像)",
    type: "TIANDITU",
    zIndex: [2, 3],
    url: CLOUD.tdt,
    format: "tiles",
    style: "default",
    matrixSet: "c",
    Zoom: [[], [14, 18]],
    layer: "img",
    resolutions: [
      1.4062499999999998, 0.7031249999999999, 0.3515624999999999,
      0.17578124999999997, 0.08789062499999999, 0.04394531249999999,
      0.021972656249999997, 0.010986328124999997, 0.0054931640625,
      0.0027465820312499987, 0.0013732910156249998, 6.866455078124998e-4,
      3.4332275390625e-4, 1.7166137695312497e-4, 8.583068847656249e-5,
      4.291534423828124e-5, 2.145767211914062e-5, 1.072883605957031e-5,
      5.364418029785155e-6,
    ],
    matrixIds: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
    ],
    imgUrl: "./images/skin/tianditu.png",
  },
  {
    url: "http://192.168.1.82:9000/geoserver/ldysf/wms",
    version: "1.1.1",
    layers: "ldysf:town_boundary",
    transparent: true,
    styles: "",
    format: "image/png",
    viewparams: "townCode:001",
    CRS: "EPSG:4549",
  },
];
/**
 * 用91位图工具绘制点位信息，然后将所有的点位信息数据及逆行处理，
 * 处理完之后的点位信息进行对象你能封装
 */
export const lunkuo = [
  [119.901588117127, 28.4803204293844],
  [119.902961408143, 28.4776596780416],
  [119.903047238831, 28.4695057626364],
  [119.903562222962, 28.4574036355612],
  [119.904506360535, 28.452339624941],
  [119.907596265321, 28.447103952944],
  [119.910514508729, 28.4438423867819],
  [119.915321027283, 28.443327402651],
  [119.919526731019, 28.4422116037008],
  [119.926736508851, 28.4428982492086],
  [119.932572995667, 28.4443573709127],
  [119.937894498353, 28.4489922280905],
  [119.941585217957, 28.4566311593649],
  [119.946220075135, 28.4649567361471],
  [119.950597440247, 28.4696774240133],
  [119.951884900574, 28.470707392275],
  [119.9464775672, 28.4768013711569],
  [119.944074307923, 28.4797196145651],
  [119.941070233826, 28.480062937319],
  [119.933259641175, 28.4784321542379],
  [119.927165662293, 28.4775738473532],
  [119.922444974427, 28.4776596780416],
  [119.922530805115, 28.4777455087301],
  [119.901588117127, 28.4803204293844],
];
/**
 * 三条道路的经纬度
 */

export const IconAnm = {
  黄色报警: {
    name: "黄色报警",
    utl: require("../assets/json/黄色圆点闪烁告警.json"),
  },
  黄色报警选中: {
    name: "黄色报警选中",
    utl: require("../assets/json/选中黄色告警.json"),
  },
  红色报警: {
    name: "选中",
    utl: require("../assets/json/红色圆点闪烁告警.json"),
  },
  红色报警选中: {
    name: "报警",
    utl: require("../assets/json/选中红色告警.json"),
  },
  选中: {
    name: "选中",
    utl: require("../assets/json/selectedIcon.json"),
  },
  普通选中: {
    name: "普通选中",
    utl: require("../assets/json/selectedIconCom.json"),
  },
};

/**
 * XIUYUQU: 秀屿区
 */
export const ABorder = {
  XIUYUQU: {
    id: "XIUYUQU001",
    data: require("../assets/json/town.json"),
    center: [119.27208338, 25.23224544],
  },
};
/**
 * 检车井
 *
 */
export const CheckWell = {
  icon: require("../assets/icon/jianchajing.svg").default,
};
/**
 * 所有已经渲染到场景中的实体
 * 包括{场站、泵站点位}
 */
window["ASSETSFEATURE"] = [];
/**
 * ，管线线段
 */
window["ASSETS_LINE_FEATURE"] = [];
/**
 * 所有已经渲染到场景中的实体
 * 包括{特殊点位}
 */
window["ASSETSOVERLAY"] = [];

/**
 * 选中状态只能有一个
 */
window["ASSETSOVERLAYSELECT"] = null;
/**
 * 选中的线是能有一个
 */
window["ASSET_SOVERLAY_LINE"] = null;
/**
 * 移动的线
 */
window["ASSETS_MOVE_LINE_LIST"] = [];

/**
 * 首页显示点位信息
 */
export const townshipLevel = {
  imgurl: require("../assets/icon/labelImage1.svg").default,
  imgurl1: require("../assets/icon/labelImage2.svg").default,
  point: [
    {
      name: "湄洲镇",
      postiton: [119.123682119, 25.066281081, 150],
      status: true,
    },
    {
      name: "山亭镇",
      postiton: [119.103511908, 25.141211272, 150],
      status: true,
    },
    {
      name: "东埔镇",
      postiton: [119.043430426, 25.1521976, 150],
      status: true,
    },
    {
      name: "忠门镇",
      postiton: [119.08634577, 25.193053008, 150],
      status: true,
    },
    {
      name: "平海镇",
      postiton: [119.247020819, 25.212965728, 150],
      status: false,
    },
    {
      name: "埭头镇",
      postiton: [119.278606512, 25.281630279, 150],
      status: false,
    },
    {
      name: "东峤镇",
      postiton: [119.164966681, 25.265150786, 150],
      status: false,
    },
    {
      name: "月塘镇",
      postiton: [119.101451971, 25.244551421, 150],
      status: false,
    },
    {
      name: "东庄镇",
      postiton: [119.037593939, 25.252791167, 150],
      status: false,
    },
    {
      name: "笏石镇",
      postiton: [119.080509283, 25.318709136, 150],
      status: false,
    },
    {
      name: "南曰镇",
      postiton: [119.495929815, 25.210219146, 150],
      status: false,
    },
  ],
};

export const areas = {
  areas: require("../assets/kml/xiuyuqu.kml").default,
  tesgy: require("../assets/kml/teshu.kml").default,
};
