import { useState } from "react";
import { PtIcon } from "../../../../components";
import GeneralList from "./generalList";

interface Props {
  dataSource: any;
  activeData: any;
  rightVisible?: boolean;
  onClick: (value, type, data?) => void;
}

/**
 * @description           折叠面板
 */

const GeneralCollapse = ({
  dataSource,
  activeData,
  rightVisible = true,
  onClick,
}: Props) => {
  //body显示状态
  const [isOpen, setIsOpen] = useState(false);

  const { title, total, type, list } = dataSource;

  return (
    <div className="general_collapse">
      <div className="collapse_head" onClick={() => setIsOpen(!isOpen)}>
        <div className="head_l">
          {title} ({total})
        </div>
        <div className="head_r">
          <PtIcon
            className={`${isOpen ? "icon-shousuoicon" : "icon-zhankaiicon"}`}
          />
        </div>
      </div>
      {isOpen && (
        <div className={`collapse_body customScrollBars`}>
          {list.map((item) => {
            return (
              <GeneralList
                key={item.id}
                className={`
                collapse_list 
                hover_${item.state ? "success" : "warning"}
                ${
                  item.id === activeData.id &&
                  `active_${item.state ? "success" : "warning"}`
                }
                
                `}
                onClick={() =>
                  onClick(
                    item.id === activeData.id ? {} : item,
                    item.id === activeData.id ? "" : type,
                    dataSource
                  )
                }
              >
                <div className="list_l">{item.title || item.name}</div>
                {rightVisible ? (
                  <div
                    className={`state_box ${
                      Boolean(item.state) ? "success" : "warning"
                    }`}
                  >
                    {item.state ? "正常" : "异常"}
                  </div>
                ) : null}
              </GeneralList>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GeneralCollapse;
