import { Col, Row } from "antd";

interface Props {
  dataSource: any[];
  columns: any[];
}

const ExcelStyle5 = ({ dataSource, columns }: Props) => {
  return (
    <>
      {dataSource.map((item, i) => {
        return (
          <Row key={i}>
            {columns.map((it, index) => {
              return (
                <Col key={index} span={it.span}>
                  <div className="excel_text4" style={it.style ? it.style : {}}>
                    {it.render
                      ? it.render(item[it.dataIndex], i + 1)
                      : item[it.dataIndex]}
                  </div>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </>
  );
};

export default ExcelStyle5;
