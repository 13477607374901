import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle4 from "../../general/excelStyle4";

interface Props {
  leftData: any;
  dataSource: any;
}

const PumpStationExcel3 = ({ leftData, dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "巡查人员",
              value: "刘昌武",
            },
            {
              title: "巡查日期",
              value: dataSource.createTime,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "养护单位",
              value: "***",
            },
            {
              title: "任务类型",
              value: "其它项目",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle4
          dataSource={[
            {
              title: "问题描述",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "现场照片",
              value: [],
              type: 2,
            },
            {
              title: "运维要求",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "运维工作时限",
              value: "暂未填写",
              type: 1,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "运维负责人",
              value: "潘幸",
            },
            {
              title: "签收人",
              value: "刘昌武",
            },
          ]}
        />
      </div>
    </>
  );
};

export default PumpStationExcel3;
