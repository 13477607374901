import React, { Component, useState } from "react";
import { Popover, Select } from "antd";
import { PtDrawer, PtCard, PtPop, PtProgress } from "../../components";
import { connect } from "react-redux";
import {
  WaterData,
  legendList,
  pumplegendList,
  progressbarData,
} from "../../utils/strConfig";
import {
  PtOperationStatus,
  PtRealTimeEvent,
  PtVillageWaterPurification,
} from "../components/components";
import "./index.scss";
import axios from "axios";
import MainModal from "./components/mainModal";
import ProcessInfo from "./components/processInfo";

const list = ["忠门镇", "山亭镇", "湄洲镇", "东埔镇"];


// 1:场站,2:泵站,3:管线,4:负压
const typeList:any = [
  {
    label: '场站',
    value: 1,
  },
  {
    label: '泵站',
    value:2,
  },
  {
    label: '负压',
    value:4,
  },
]

interface Props {
  drawerVisible: boolean;
}

interface State {
  operaStatusList: any[];
  waterData: any[];
  modalVisible: boolean;
  //场站数据
  stationState: any[];
  //泵站数据
  pumpStationState: any[];
  //负压数据
  pressureState: any[];
  //类型
  type: number;

}

class MainSkin extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      operaStatusList: [
        {
          title: "笏石镇",
          total: 100,
          data: [
            { type: "完成", value: "complete", number: 60, color: "#00FFCB" },
            { type: "告警", value: "warn", number: 30, color: "#E34438" },
            { type: "处理", value: "handle", number: 10, color: "#FFC96F" },
          ],
        },
        {
          title: "东庄镇",
          total: 80,
          data: [
            { type: "完成", value: "complete", number: 40, color: "#00FFCB" },
            { type: "告警", value: "warn", number: 20, color: "#E34438" },
            { type: "处理", value: "handle", number: 20, color: "#FFC96F" },
          ],
        },
        {
          title: "平海镇",
          total: 80,
          data: [
            { type: "完成", value: "complete", number: 40, color: "#00FFCB" },
            { type: "告警", value: "warn", number: 20, color: "#E34438" },
            { type: "处理", value: "handle", number: 20, color: "#FFC96F" },
          ],
        },
        {
          title: "东峤镇",
          total: 80,
          data: [
            { type: "完成", value: "complete", number: 40, color: "#00FFCB" },
            { type: "告警", value: "warn", number: 20, color: "#E34438" },
            { type: "处理", value: "handle", number: 20, color: "#FFC96F" },
          ],
        },
        {
          title: "月塘镇",
          total: 80,
          data: [
            { type: "完成", value: "complete", number: 40, color: "#00FFCB" },
            { type: "告警", value: "warn", number: 20, color: "#E34438" },
            { type: "处理", value: "handle", number: 20, color: "#FFC96F" },
          ],
        },
        {
          title: "南日镇",
          total: 80,
          data: [
            { type: "完成", value: "complete", number: 40, color: "#00FFCB" },
            { type: "告警", value: "warn", number: 20, color: "#E34438" },
            { type: "处理", value: "handle", number: 20, color: "#FFC96F" },
          ],
        },
        {
          title: "埭头镇",
          total: 80,
          data: [
            { type: "完成", value: "complete", number: 40, color: "#00FFCB" },
            { type: "告警", value: "warn", number: 20, color: "#E34438" },
            { type: "处理", value: "handle", number: 20, color: "#FFC96F" },
          ],
        },
      ],
      waterData: [],
      modalVisible: false,
      stationState: [],
      pumpStationState: [],
      pressureState: [],
      type: 1,
    };
  }

  

  componentDidMount(): void {
    axios.get(`/device/get_num_by_area_code/350305000000`).then((res: any) => {
      if (res.code === 200) {
        this.setState({
          waterData: res.data,
        });
      }
    });
    this.getState();
  }

  getState = () => {
    //场站
    axios.get(`/device/get_device_num/7`).then((res: any) => {
      if (res.code === 200) {
        this.setState({
          stationState: res.data,
        });
      }
    });
    //泵站
    axios.get(`/device/get_device_num/6`).then((res: any) => {
      if (res.code === 200) {
        this.setState({
          pumpStationState: res.data,
        });
      }
    });
    //负压
    axios.get(`/device/get_device_num/14`).then((res: any) => {
      if (res.code === 200) {
        this.setState({
          pressureState: res.data,
        });
      }
    });
  };

  render() {
    const { drawerVisible } = this.props;
    const {
      operaStatusList,
      waterData,
      modalVisible,
      stationState,
      pumpStationState,
      pressureState,
      type,
    } = this.state;
    return (
      <div className={"pt-main-skin"}>
        <PtDrawer
          visible={drawerVisible}
          left={
            <>
              <PtCard title="项目简介">
                <div
                  className="project_profile simple_text"
                  onClick={() => {
                    this.setState({
                      modalVisible: true,
                    });
                  }}
                >
                  <div>
                    本项目主要分别为“莆田市秀屿区农村生活污水收集与处理工程”（子项一）、“莆田市秀屿区笏石溪河道综合整治工程”（子项二），项目总投资14.8亿，合作期
                    20年。
                  </div>
                  <div>
                    “子项一”工程范围包括笏石镇、埭头镇、平海镇、东峤镇、南日镇、东庄镇和月塘镇等7个镇，共计147个行政村（社区），涉及66万人。
                  </div>
                </div>
              </PtCard>
              <PtVillageWaterPurification
                title={"秀屿区污净水"}
                enTitle="Village Sewage Purification"
                staticTypeNumber={4}
                data={[...WaterData, ...waterData]}
                percentList={
                  waterData.length > 0
                    ? waterData.map((it) => it.rate * 100)
                    : []
                }
              />
              {/* <PtCard title={"管线指数"}>
                <PtProgress text={"完整率"} percent={100} />
              </PtCard> */}
              <PtRealTimeEvent scrollY={180} />
            </>
          }
          right={
            <>
              <PtOperationStatus
                timelyrate={97}
                operaStatusList={operaStatusList}
                progressbarData={progressbarData}
              />
              
              <ProcessInfo/>

              <PtCard
                title={"设备运行状态"}
                entitle={"Equipment operating status"}
                suffix={
                  <>
                  <div></div>
                  <div className="select_box">
                    <Select
                      size="small"
                      bordered={false}
                      options={typeList}
                      getPopupContainer={(triggerNode) => triggerNode}
                      value={type}
                      onChange={(value) => this.setState({
                        type: value,
                      })}
                    />
                  </div>
                  </>
                }
              >
                
                {type === 1 ? (
									<div className={"pt-station-state"}>
                    <div className="pt-station-state__header">
                      总计
                      <div>
                        <span style={{ color: "#02D281" }}>
                        {
                          stationState.reduce((acc, cur) => {
                            return acc + cur.olineCount
                          }, 0)
                        }
                        </span>
                        <span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
                        <span style={{ color: "#F76600" }}>
                        {
                          stationState.reduce((acc, cur) => {
                            return acc + cur.waringCount
                          }, 0)
                        }
                        </span>
                        <span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
                        <span style={{ color: "#FFFFFF" }}>
                        {
                          stationState.reduce((acc, cur) => {
                            return acc + cur.offlineCount
                          }, 0)
                        }
                        </span>                        
                      </div>
                    </div>
										<div className={"pt-station-state__body"}> 
											{stationState.map((r, i) => {
												const total = r.olineCount + r.waringCount + r.offlineCount
												return (
													!list.includes(r.areaName) && (
														<div className={"townInfo"} key={i}>
															<div className={"townName"}>{r.areaName}</div>
															<Popover
																placement="bottomRight"
																title={""}
																className={"custom-pop"}
																content={
																	<PtPop
																		data={r}
																		index={`sStatePopup${i}`}
																		type={"sStatePopup"}
																	/>
																}
																trigger="click"
															>
																<div className={"stateInfo"}>
																	<div
																		style={{
																			width: `${(r.olineCount / total) * 100}%`,
																			height: "5px",
																			background: "#02D281"
																		}}
																	/>
																	<div
																		style={{
																			width: `${(r.waringCount / total) * 100}%`,
																			height: "5px",
																			background: "#F76600"
																		}}
																	/>
																	<div
																		style={{
																			width: `${(r.offlineCount / total) * 100}%`,
																			height: "5px",
																			background: "#FFFFFF"
																		}}
																	/>
																</div>
															</Popover>
															<div className="text_box">
																<div style={{ color: "#02D281" }}>{r.olineCount}</div>
																<span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
																<div style={{ color: "#F76600" }}>{r.waringCount}</div>
																<span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
																<div style={{ color: "#FFFFFF" }}>{r.offlineCount}</div>
															</div>
														</div>
													)
												)
											})}
										</div>
										<div className={"legend"}>
											{legendList.map((r, i) => {
												return (
													<div key={i} className={"legendInfo"}>
														<div className={"legendTitle"}>{r.title}：</div>
														{r.list.map((l_item, l_i) => {
															return (
																<div key={l_i} className={"legendBox"}>
																	<div
																		style={{ backgroundColor: l_item.tagColor }}
																		className={"legendTag"}
																	/>
																	<div className={"legendTagName"}>
																		{l_item.tagName}
																	</div>
																</div>
															)
														})}
													</div>
												)
											})}
										</div>
                    
									</div>
								) : type === 2 ? (
									<div className={"pt-pumpstation"}>
                    <div className="pt-station-state__header">
                      总计 
                      <div>
                        <span style={{ color: "#02D281" }}>
                        {
                          pumpStationState.reduce((acc, cur) => {
                            return acc + cur.olineCount
                          }, 0)
                        }
                        </span>
                        <span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
                        <span style={{ color: "#F76600" }}>
                        {
                          pumpStationState.reduce((acc, cur) => {
                            return acc + cur.waringCount
                          }, 0)
                        }
                        </span>
                        <span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
                        <span style={{ color: "#FFFFFF" }}>
                        {
                          pumpStationState.reduce((acc, cur) => {
                            return acc + cur.offlineCount
                          }, 0)
                        }
                        </span>                        
                      </div>
                    </div>
										<div className={"pt-pumpstation__body"}>
											{pumpStationState.map((r, i) => {
												const total = r.olineCount + r.waringCount + r.offlineCount
												return (
													!list.includes(r.areaName) && (
														<div className={"townInfo"} key={i}>
															<div className={"townName"}>{r.areaName}</div>
															<Popover
																placement="bottomRight"
																title={""}
																className={"custom-pop"}
																content={
																	<PtPop
																		data={r}
																		index={`pStatePopup${i}`}
																		type={"pStatePopup"}
																	/>
																}
																trigger="click"
															>
																<div className={"stateInfo"}>
																	<div
																		style={{
																			width: `${(r.olineCount / total) * 100}%`,
																			height: "5px",
																			background: "#02D281"
																		}}
																	/>
																	<div
																		style={{
																			width: `${(r.waringCount / total) * 100}%`,
																			height: "5px",
																			background: "#F76600"
																		}}
																	/>
																	<div
																		style={{
																			width: `${(r.offlineCount / total) * 100}%`,
																			height: "5px",
																			background: "#FFFFFF"
																		}}
																	/>
																</div>
															</Popover>
															<div className="text_box">
																<div style={{ color: "#02D281" }}>{r.olineCount}</div>
																<span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
																<div style={{ color: "#F76600" }}>{r.waringCount}</div>
																<span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
																<div style={{ color: "#FFFFFF" }}>{r.offlineCount}</div>
															</div>
														</div>
													)
												)
											})}
										</div>
										<div className={"legend"}>
											{pumplegendList.map((r, i) => {
												return (
													<div key={i} className={"legendInfo"}>
														<div className={"legendTitle"}>{r.title}：</div>
														{r.list.map((l_item, l_i) => {
															return (
																<div key={l_i} className={"legendBox"}>
																	<div
																		style={{ backgroundColor: l_item.tagColor }}
																		className={"legendTag"}
																	/>
																	<div className={"legendTagName"}>
																		{l_item.tagName}
																	</div>
																</div>
															)
														})}
													</div>
												)
											})}
										</div>
									</div>
								) : (
									<div className={"pt-station-state"}>
                    <div className="pt-station-state__header">
                      总计
                      <div>
                        <span style={{ color: "#02D281" }}>
                        {
                          pressureState.reduce((acc, cur) => {
                            return acc + cur.olineCount
                          }, 0)
                        }
                        </span>
                        <span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
                        <span style={{ color: "#F76600" }}>
                        {
                          pressureState.reduce((acc, cur) => {
                            return acc + cur.waringCount
                          }, 0)
                        }
                        </span>
                        <span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
                        <span style={{ color: "#FFFFFF" }}>
                        {
                          pressureState.reduce((acc, cur) => {
                            return acc + cur.offlineCount
                          }, 0)
                        }
                        </span>                        
                      </div>
                    </div>
										<div className={"pt-station-state__body"}>
                      {pressureState.map((r, i) => {
												const total = r.olineCount + r.waringCount + r.offlineCount
												return (
													!list.includes(r.areaName) && (
														<div className={"townInfo"} key={i}>
															<div className={"townName"}>{r.areaName}</div>
															<Popover
																placement="bottomRight"
																title={""}
																className={"custom-pop"}
																content={
																	<PtPop
																		data={r}
																		index={`sStatePopup${i}`}
																		type={"sStatePopup"}
																	/>
																}
																trigger="click"
															>
																<div className={"stateInfo"}>
																	<div
																		style={{
																			width: `${(r.olineCount / total) * 100}%`,
																			height: "5px",
																			background: "#02D281"
																		}}
																	/>
																	<div
																		style={{
																			width: `${(r.waringCount / total) * 100}%`,
																			height: "5px",
																			background: "#F76600"
																		}}
																	/>
																	<div
																		style={{
																			width: `${(r.offlineCount / total) * 100}%`,
																			height: "5px",
																			background: "#FFFFFF"
																		}}
																	/>
																</div>
															</Popover>
															<div className="text_box">
																<div style={{ color: "#02D281" }}>{r.olineCount}</div>
																<span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
																<div style={{ color: "#F76600" }}>{r.waringCount}</div>
																<span style={{ color: "#fff" }}>&nbsp;/&nbsp;</span>
																<div style={{ color: "#FFFFFF" }}>{r.offlineCount}</div>
															</div>
														</div>
													)
												)
											})}
										</div>
										<div className={"legend"}>
											{legendList.map((r, i) => {
												return (
													<div key={i} className={"legendInfo"}>
														<div className={"legendTitle"}>{r.title}：</div>
														{r.list.map((l_item, l_i) => {
															return (
																<div key={l_i} className={"legendBox"}>
																	<div
																		style={{ backgroundColor: l_item.tagColor }}
																		className={"legendTag"}
																	/>
																	<div className={"legendTagName"}>
																		{l_item.tagName}
																	</div>
																</div>
															)
														})}
													</div>
												)
											})}
										</div>
									</div>
								)}

                
              </PtCard>
            </>
          }
        />
        {modalVisible && (
          <MainModal
            onClose={() => {
              this.setState({
                modalVisible: false,
              });
            }}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  drawerVisible: state.getIn(["home", "drawerVisible"]),
});

export default connect(mapState)(MainSkin);
