import { Select } from "antd";
import { useEffect, useState } from "react";
import { PtChildTitle } from "../../../../../components";
import { HeadList, TimeList, TypeList } from "../../../data";
import GeneralTable from "../../general/generalTable";

interface Props {
  activeData: any;
  setActiveData: (value) => void;
}

const Emergency3Left = ({ activeData, setActiveData }: Props) => {
  //负责人
  const [head, setHead] = useState(1);
  //状态
  const [type, setType] = useState(1);
  //时间
  const [time, setTime] = useState(1);

  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: "应急描述",
      dataIndex: "describe",
      render: (data) => (
        <div className="describe_text" title={data}>
          {data}
        </div>
      ),
    },
    {
      title: "记录时间",
      dataIndex: "time",
    },
    {
      title: "状态",
      dataIndex: "state",
      width: 74,
      render: (data) => (
        <div className={`state_box`}>{data ? "已处理" : "待处理"}</div>
      ),
    },
  ];

  useEffect(() => {
    setDataSource([
      {
        id: 1,
        describe: "苏塘村5号泵污水泄漏",
        time: "2023.02.02 13:23",
        state: 1,
      },
      {
        id: 2,
        describe: "苏塘村4号泵设备停电",
        time: "2023.01.25 10:14",
        state: 0,
      },
    ]);
  }, []);

  /**
   * @description         监听type改变
   */

  useEffect(() => {
    switch (type) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
    }
  }, [type]);

  return (
    <div className="emergency3_left">
      <PtChildTitle title={"记录列表"} />
      <div className="type_box">
        <div className="select_box">
          <Select
            size="small"
            bordered={false}
            options={TimeList}
            getPopupContainer={(triggerNode) => triggerNode}
            value={time}
            onChange={(value) => setTime(value)}
          />
        </div>
        <div className="select_box">
          <Select
            size="small"
            bordered={false}
            options={HeadList}
            getPopupContainer={(triggerNode) => triggerNode}
            value={head}
            onChange={(value) => setHead(value)}
          />
        </div>
        <div className="select_box">
          <Select
            size="small"
            bordered={false}
            options={TypeList}
            getPopupContainer={(triggerNode) => triggerNode}
            value={type}
            onChange={(value) => setType(value)}
          />
        </div>
      </div>
      <GeneralTable
        columns={columns}
        dataSource={dataSource}
        activeData={activeData}
        lineClick={(value) => {
          setActiveData(value.id === activeData.id ? {} : value);
        }}
      />
    </div>
  );
};

export default Emergency3Left;
