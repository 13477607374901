import React, { Component } from "react";
import { PtTitleSuffix, PtMenu } from "../simple";
import { connect } from "react-redux";
import { headActionCreators } from "../../store/head";
import { homeActionCreators } from "../../store/home";
import "./index.scss";
import { Divider } from "antd";
import axios from "axios";
import Weather from "./components/weather";

interface Props {
  CesMaps: any;
  suffixKey: number;
  changeSuffixKey: (value) => void;
  changeDrawerKey: (value) => void;
  changeModuleKey: (value) => void;
  changeMultipleData: (value) => void;
  changeDrawerVisible: () => void;
  changeActiveData: (data) => void;
}

interface State { }

class Header extends Component<Props, State> {
  FeatureList: any[];
  constructor(props) {
    super(props);
    this.FeatureList = [];
  }

  /**
   * 更新乡镇列表的时候，清空绘制的所有实体
   */
  setMenuKey = (k) => {
    const { flyTo } = this.props.CesMaps;
    if (k.value === 0) {
      flyTo([119.142105796, 25.211389225, 115000]);
    } else {
      axios.get(`/admin_area/getInfoById/${k.value}`).then((res: any) => {
        if (res.code === 200) {
          if (res.data.lon && res.data.lat) {
            flyTo([res.data.lon, res.data.lat, k.value < 12 ? 30000 : 3000]);
          }
        }
      });
    }
  };

  render() {
    const {
      suffixKey,
      changeSuffixKey,
      changeDrawerKey,
      changeModuleKey,
      changeMultipleData,
      changeDrawerVisible,
      changeActiveData,
    } = this.props;
    return (
      <div className={"pt-header"}>
        <div>
          <div className={"header__left"}>
            <PtTitleSuffix
              suffixKey={suffixKey}
              onMenuChange={(value, name) => {
                new Promise((r, e) => {
                  this.setMenuKey(value);
                  r(true);
                }).then(() => {
                  changeMultipleData({
                    suffixKey: value.value,
                    areaName: name,
                  });
                  changeDrawerKey(0);
                  changeModuleKey("0");
                });
              }}
            />
            <Divider type="vertical" />
            <Weather />
          </div>
          <div className={"header__content"}>农村生活污水收集与处理工程</div>
          <div className={"header__right"}>
            <PtMenu
              onChangeDrawer={(value) => {
                changeDrawerKey(value);
                changeModuleKey("0");
                changeSuffixKey(0);
                changeDrawerVisible();
                changeActiveData({});
              }}
              onChangeModule={(value) => {
                changeModuleKey(value);
                changeDrawerKey(0);
                // changeSuffixKey("0");
                changeDrawerVisible();
                changeActiveData({});
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  suffixKey: state.getIn(["header", "suffixKey"]),
});

const mapDispatch = (dispatch) => ({
  changeSuffixKey(value) {
    dispatch(headActionCreators.setSuffixKey(value));
  },
  changeDrawerKey(value) {
    dispatch(headActionCreators.setDrawerKey(value));
  },
  changeModuleKey(value) {
    dispatch(headActionCreators.setModuleKey(value));
  },
  changeMultipleData(value) {
    dispatch(headActionCreators.setMultipleData(value));
  },
  changeDrawerVisible() {
    dispatch(homeActionCreators.setDrawerVisible(true));
  },
  changeActiveData(data) {
    dispatch(homeActionCreators.setAssetsSkinActiveData(data));
  },
});

export default connect(mapState, mapDispatch)(Header);
