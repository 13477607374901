import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle4 from "../../general/excelStyle4";

interface Props {
  dataSource: any;
}

/**
 * @description           绿化维养记录
 */

const GreeningExcel2 = ({ dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "站点名称",
              value: dataSource.title,
            },
            {
              title: "维养日期",
              value: dataSource.time,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "植被种类",
              value: "***",
            },
            {
              title: "维养人员",
              value: "闫固咏",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle4
          dataSource={[
            {
              title: "维养措施",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "现场照片",
              value: [],
              type: 2,
            },
          ]}
        />
      </div>
    </>
  );
};

export default GreeningExcel2;
