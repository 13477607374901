import { Col, Row } from "antd";

interface Props {
  columns: any[];
  dataSource: any[];
}

const ExcelStyle3 = ({ columns, dataSource }: Props) => {
  return (
    <>
      {dataSource.map((res, i) => {
        const { title, list } = res;
        return (
          <Row key={i}>
            <Col span={3}>
              <div className="excel_text3">{title}</div>
            </Col>
            <Col span={21}>
              {list.map((item, i) => (
                <Row key={i}>
                  {columns.map((it, index) => (
                    <Col key={index}>
                      <div className="excel_text4" style={{ width: it.span }}>
                        {item[it.dataIndex]}
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default ExcelStyle3;
