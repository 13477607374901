import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PtCard, PtDrawer } from "../../../../components";
import * as Cesium from "cesium";
import PatrolLeft from "./components/patrolLeft";
import PatrolRight from "./components/patrolRight";

import "./index.scss";

const Patrol = () => {
  //两侧内容显示状态
  const drawerVisible = useSelector((value: any) =>
    value.getIn(["home", "drawerVisible"])
  );

  const [activeData, setActiveData] = useState({} as any);

  useEffect(() => {
    const { entities, viewer } = window["CesMaps"];
    var source = new EventSource(
      "https://app.cthd.cc/public/locus/list/sse"
    );
    source.onmessage = function (event) {
      let newArr = JSON.parse(event.data);
      newArr.forEach((item) => {
        if (viewer.entities.getById(`${item.id}ren`)) {
          viewer.entities.getById(`${item.id}ren`).position =
            Cesium.Cartesian3.fromDegrees(Number(item.x), Number(item.y));
        } else {
          const entities_ = new entities(viewer);
          entities_.options({
            name: item.name,
            id: `${item.id}ren`,
            data: { ...item },
            position: [Number(item.x), Number(item.y)],
            type: "RENYUAN",
            billboard: {
              url: require(`../../../../assets/icon/legendImage9.svg`).default,
              Zindex: 80000,
              scale: 1.5,
            },
            label: {
              text: item.name,
              Zindex: 10000,
              backgroundColor: "#02010007",
              pixelOffsetY: -15,
            },
          });
          entities_.add();
        }
      });
    };
    return () => {
      source.close();
    };
  }, []);

  return (
    <div>
      <PtDrawer
        visible={drawerVisible}
        left={
          <PtCard title="巡检人员">
            <PatrolLeft activeData={activeData} setActiveData={setActiveData} />
          </PtCard>
        }
        right={
          activeData.id ? (
            <PtCard title={activeData.name}>
              <PatrolRight activeData={activeData} />
            </PtCard>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default Patrol;
