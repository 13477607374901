import { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { Chart, Interval, Tooltip } from "bizcharts";
import { PtContentDisplay, PtTab, PtTableLine } from "../../../../components";
import { RecordContentBox } from "../../../components/components";

const isDev = process.env.NODE_ENV === "development";

/**
 * @param type                类型(1:场站,2:泵站,4:负压)
 */


const PumpStationMonitoring = ({ data, renewState, openKey, setOpenKey , type }) => {
  const [list, setList] = useState([]);
  const [spec, setSpec] = useState(0);
  const [specName, setSpecName] = useState("");
  const [dealWithKey, setDealWithKey] = useState(1);
  const [stateList, setStateList] = useState([]);
  const [dealWithData, setDealWithData] = useState([]);
  const [recordList1, setRecordList1] = useState([]); //未读列表
  const [recordList2, setRecordList2] = useState([]); //已读列表
  const [recordKey, setRecordKey] = useState(0); //报警记录展示内容
  const [subOpenKey, setSubOpenKey] = useState(0)
  const dealWith = [
    {
      value: 1,
      text: "周",
    },
    {
      value: 2,
      text: "月",
    },
    {
      value: 3,
      text: "年",
    },
  ];
  useEffect(() => {
    // 获取流量
    axios
      .get("/monitor_station_process/statistics", {
        params: {
          code: data.code,
        },
      })
      .then((res: any) => {
        if (res.code === 200)
          setSpec(res.data.day);
      });
    //获取泵站所有泵的列表
    // axios
    //   .get("/monitor_station_info/pump_state", {
    //     params: {
    //       code: data.code,
    //     },
    //   })
    //   .then((res: any) => {
    //     if (res.code === 200) setStateList(res.data);
    //   });
    getList(dealWithKey);
    getRecordList();
    let webSocketUrl = `${process.env.REACT_APP_WSS_API}/msg/${data.code}`;
    let ws = new WebSocket(webSocketUrl);

    ws.onopen = (e) => {
      console.log("连接成功2");
    };

    ws.onmessage = (e) => {
      const newData = JSON.parse(e.data);   
      let curList = []
      newData.forEach((device: any ,index:number) =>{
        const obj = {
          devName: device.device,
          code: device.code,
          groupList : [],
          devState: device.state === 1 ? "box_success" : (device.state === 4 ? "box_warning" : "box_error"),
          state: device.state === 1 ? "正常" : (device.state === 4 ? "离线" : device.state === 0 ? '未安装' : "异常")
        }
        // console.log(openKey === null && index === 0 , index , device.device , openKey);
        
        if(!openKey) setOpenKey((openKey === null && index === 0 ? device.device : openKey))
        device.groupList.forEach(deviceData => {
            const titleObject = {
              devName: deviceData.showType === 1 ? deviceData.name : " ",
              list:[]
            }
            console.log(deviceData);
            
            if (deviceData.showType === 1) {
              deviceData.dataList.forEach((device) => {
                if (device.devName === "当日流量" || device.devName === "当日排污量"  || device.devName === "当日处理量") {
                  if (device.devName === "当日流量"  || device.devName === "当日处理量") {
                    if(type === 2) {
                      setSpecName("当日收集量")
                    }else {
                      setSpecName("当日处理量")
                    }
                  } else {
                    setSpecName("当日排污量")
                  }
                  setSpec(device.devValue);
                }else {
                  titleObject.list.push({
                    devName: device.devName,
                    display: device.display,
                    devValue: "",
                    singleVale: device.devValue,
                    devUnit:device.unit,
                  })
                } 
              })             
            } else {
              titleObject.list.push({
                devName:"",
                display: 1,
                devValue: "出水",
                singleVale: "进水",
              })
              const result = {}
              deviceData.dataList.forEach((device) => {
                const [name, type] = device.devName.split("-")
                if (!result[name]) {
                  result[name] = {
                    devName: name,
                    display: device.display,
                    devUnit:device.unit,
                    devValue: null, // 对应出水的isDeal
                    singleVale: null // 对应进水的isDeal
                  }
                }
                result[name][type === "进水" ? "singleVale" : "devValue"] = device.devValue || null
              })
              titleObject.list = titleObject.list.concat(Object.values(result))
            }
            obj.groupList.push(titleObject)

        })
        curList.push(obj)
      })
      console.log(curList);
      
      setList(curList);      
    };

    return () => {
      ws.close();
      setList([]);
      setSpec(0);
    };
  }, [data.code]);
  const getList = (key) => {
    axios
      .get("/device_latest/week_day_month", {
        params: {
          type: key,
          code: data.code,
        },
      })
      .then((res: any) => {
        if (res.code === 200) setDealWithData(res.data);
      });
  };
  /**
   * @description     获取报警记录列表
   */

  const getRecordList = () => {
    axios
      .get("/device_alarm_record/query_all", {
        params: {
          code: data.code,
          state: 0,
        },
      })
      .then((res: any) => {
        if (res.code === 200) setRecordList1(res.data);
      });
    axios
      .get("/device_alarm_record/query_all", {
        params: {
          code: data.code,
          state: 1,
        },
      })
      .then((res: any) => {
        if (res.code === 200) setRecordList2(res.data);
      });
  };

  /**
   * @description     改变报警记录状态
   * @param   id
   */

  const changeState = (id) => {
    axios
      .get("/device_alarm_record/read_msg", { params: { id } })
      .then((res: any) => {
        if (res.code === 200) {
          message.success(res.data);
          getRecordList();
          renewState();
        }
      });
  };
  return (
    <div>
      {list.map((item) => {
				return (
					<PtContentDisplay
						key={item.devName}
						title={`设备监测 (${item.code})`}
						visible={Boolean(openKey === item.devName)}
						onClick={() => {
							setOpenKey(openKey === item.devName ? "" : item.devName)
						}}
					>
            {
              item.groupList.length === 1 &&  <PtTableLine
              boxStyle={{
                alignItems: "center",
              }}
              leftComponent={
                <div className={"stations--left"}>
                  <div className={"style3"}>{specName}</div>
                </div>
              }
              rightComponent={
                <div
                  className={"pumpStation--right"}
                  style={{ alignItems: "flex-end" }}
                >
                  <div style={{ height: "29px", fontSize: "20px" }}>{spec}</div>
                  <div className={"right__unit"}> m³</div>
                </div>
              }
              bottomComponent={
                <>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "17px",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "rgba(255,255,255,0.8)",
                      fontSize: "14px",
                    }}
                  >
                    <span>
                      {type === 2 ? '收集量统计' : '处理量统计'}
                    </span>
                    <PtTab
                      data={dealWith}
                      value={dealWithKey}
                      onChange={(value) => {
                        setDealWithKey(value);
                        getList(value);
                      }}
                    />
                  </div>
                  <MonitorCharts data={dealWithData} />
                </>
              }
            />
            }
						{item.groupList.length === 1
							? item.groupList[0].list.map((it, i) => {
									return (
										Boolean(it.display) && (
											<PtTableLine
												key={i}
												leftText={it.devName}
												boxStyle={{ marginTop: it.isTitle ? "4px" : "0" }}
												rightComponent={
													<div className={"stations-right"}>
														{it.devValue ? (
															<>
																<div className={"right_content"}>{it.devValue}</div>
																<div className={"right_unit"}>
																	{it.singleVale}
																	<span>{it.devUnit ? it.devUnit : ""}</span>
																</div>
															</>
														) : (
															<div className={"right_unit"}>
																{it.singleVale || "-"}
																{it.singleVale && (
																	<span>{it.devUnit ? it.devUnit : ""}</span>
																)}
															</div>
														)}
													</div>
												}
											/>
										)
									)
							  })
							: item.groupList.map((group: any , groupIndex: number) => {
									return <div className="sub_content_title">
                    <PtContentDisplay
										key={group.devName}
										title={group.devName}
										visible={Boolean(groupIndex === subOpenKey)}
										onClick={() => {
											setSubOpenKey(cur =>{
											  return cur === groupIndex ? null : groupIndex
											})
										}}
									>
										{group.list.map((it, i) => {
											return (
												(
													<PtTableLine
														key={i}
														leftText={it.devName}
														boxStyle={{ marginTop: it.isTitle ? "4px" : "0" }}
														rightComponent={
															<div className={"stations-right"}>
																{it.devValue ? (
																	<>
																		<div className={"right_content"}>
																			{it.devValue}
																		</div>
																		<div className={"right_unit"}>
																			{it.singleVale}
																			<span>{it.devUnit ? it.devUnit : ""}</span>
																		</div>
																	</>
																) : (
																	<div className={"right_unit"}>
																		{it.singleVale || "-"}
																		{it.singleVale && (
																			<span>{it.devUnit ? it.devUnit : ""}</span>
																		)}
																	</div>
																)}
															</div>
														}
													/>
												)
											)
										})}
									</PtContentDisplay>
                  </div>
							  })}
					</PtContentDisplay>
				)
			})}

      <PtContentDisplay
        title={"报警记录"}
        visible={Boolean(openKey === '报警记录')}
        onClick={() => {
          setOpenKey(openKey === '报警记录' ? '' : '报警记录')
        }}
      >
        <div className={"record__head"}>
          <div>
            <div
              className={`record__tag ${!Boolean(recordKey) && "record__active__tag"
                }`}
              onClick={() => setRecordKey(0)}
            >
              未读 ({recordList1.length})
            </div>
            <div
              className={`record__tag ${Boolean(recordKey) && "record__active__tag"
                }`}
              onClick={() => setRecordKey(1)}
            >
              已读 ({recordList2.length})
            </div>
          </div>
        </div>
        <div className={"record__content"}>
          {(!Boolean(recordKey) ? recordList1 : recordList2).map((item, i) => {
            return (
              <RecordContentBox key={i} data={item} changeState={changeState} />
            );
          })}
        </div>
      </PtContentDisplay>
    </div>
  );
};

export default PumpStationMonitoring;

/**
 * @description 实时监测-统计图
 */

const MonitorCharts = ({ data = [] }) => {
  const [max, setMax] = useState(0);
  useEffect(() => {
    let newMax = 0;
    Promise.all(
      data.map((it) => {
        if (it.proc > newMax) {
          newMax = it.proc;
        }
      })
    ).then((res) => {
      setMax(newMax + 20);
    });
  }, [data]);
  return (
    <Chart
      width={data.length < 7 ? "60%" : "100%"}
      height={68}
      autoFit
      data={data}
      interactions={["active-region"]}
      padding={0}
      scale={{
        proc: {
          max,
        },
      }}
      pure={true}
    >
      <Interval
        position="date*proc"
        color={"rgba(178, 179, 181, 1)"}
        size={5}
      />
      <Tooltip>
        {(title, items) => {
          return (
            <div style={{ padding: "8px 0", minWidth: "120px" }}>
              <div
                style={{
                  fontSize: "14px",
                  marginBottom: "12px",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: "18px", width: "60px" }}>处理量</div>
                <span style={{ fontSize: "18px" }}>
                  {items[0].value}
                  <span style={{ fontSize: "12px", color: "rgba(0,0,0,0.8)" }}>
                    m³
                  </span>
                </span>
              </div>
            </div>
          );
        }}
      </Tooltip>
    </Chart>
  );
};
