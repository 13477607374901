import { Form, Input, Button, Row, Col, message, Switch, Radio } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./index.scss";

interface Props {
  data: any
}

/**
 * @description         远程控制
 * @param
 */

export const PtRemotely = ({ data }: Props) => {
  const [phone, setPhone] = useState("");
  const [isCheck, setCheck] = useState(true);
  const [deviceCode, setCode] = useState('');

  useEffect(() => {
    setCode(data.code)
  }, [data])

  const onFinish = (value) => {
    axios
      .post(`/device/check/control/code/${value.phone}`, {
        code: value.code,
      })
      .then((res: any) => {
        if (res.code === 200) {
          message.success("验证成功");
          localStorage.setItem('checkTime', Date.now().toString());
          setCheck(true);
        } else {
          message.error(res.msg);
        }
      });
  };

  return (
    <div className="pt_remotely">
      <div className="permissions_box">
        <div className="permissions_head">{isCheck ? "远程控制" : "权限认证"}</div>
        <div className="permissions_body">
          {
            isCheck ?
              <>
              </>
              :
              <>
                <Form onFinish={onFinish}>
                  <Form.Item name="phone">
                    <div className="permissions_input">
                      <Input
                        placeholder="请输入手机号"
                        bordered={false}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Row>
                      <Col span={16}>
                        <Form.Item
                          name="code"
                          noStyle
                          rules={[{ required: true, message: "请输入验证码" }]}
                        >
                          <div className="permissions_input">
                            <Input placeholder="输入验证码" bordered={false} />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <VerificationCodeButton phone={phone} />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      验 证
                    </Button>
                  </Form.Item>
                </Form>
              </>
          }

          <ControlTabel code={deviceCode} setCheck={(check: boolean)=>{setCheck(check)}} />

        </div>
      </div>
    </div>
  );
};

/**
 * @description                 获取验证码按钮
 */

const VerificationCodeButton = ({ phone }) => {
  const [isClick, setIsClick] = useState(true);

  const [time, setTime] = useState(30);

  useEffect(() => {
    if (!isClick) {
      const newTime = time - 1;
      let t1 = setTimeout(() => {
        if (newTime === -1) {
          setIsClick(true);
          setTime(30);
        } else {
          setTime(newTime);
        }
        clearTimeout(t1);
      }, 1000);
    }
  }, [isClick, time]);

  const getVerificationCode = () => {
    if (phone) {
      setIsClick(false);
      axios
        .get(`/device/get/control/code/${phone}`)
        .then((res: any) => {
          if (res.code === 200) {
            message.success("发送成功");
          } else {
            message.error(res.msg);
          }
        });
    } else {
      message.warning("请输入手机号");
    }
  };

  return (
    <div
      className="verification_code_button"
      onClick={isClick ? getVerificationCode : () => { }}
    >
      {isClick ? "获取验证码" : `${time}s后重新获取`}
    </div>
  );
};





const ControlTabel = ({ code , setCheck }) => {
  const [list, setList] = useState([])
  // const listItme = [
  //   {
  //     value: 'pa',
  //     label: '排水泵1',
  //   }, {
  //     value: 'pb',
  //     label: '排水泵2',
  //   }, {
  //     value: 'za',
  //     label: '真空泵1',
  //   }, {
  //     value: 'zb',
  //     label: '真空泵2',
  //   }, {
  //     value: 'zc',
  //     label: '真空泵3',
  //   }, {
  //     value: 'zd',
  //     label: '真空泵4',
  //   }
  // ]
  useEffect(() => {
    if (code)
      getData();
  }, [code])

  const getData = () => {
    axios.get(`/device/control/${code}`).then(res => {
      const { data } = res
      if(data) {
        data.forEach(item =>{
          if(item.condition) {
            const condition = data.find(dev => dev.propertyCode === item.condition.property)
            item.visible = condition ? condition.devValue === item.condition.value : true
          }else {
            item.visible = true
          }
        })
      }
      setList([...data])
    })
  }

  return (
    <>
      {
        list.map(item => {
          return item.visible ? <ControlLabel code={item.deviceCode} item={item} getData={getData} setCheck={setCheck} /> : <> </>
        })
      }
    </>
  );
}


const ControlLabel = ({ code, item, getData , setCheck }) => {
  const onChange = (data: any , value: string) => {
    // _dom.value = 1;
    const params  = {
      deviceCode: data.deviceCode,
      devName: data.devName,
      devId: data.devId,
      devValue: value,
      lastId: data.lastId,
      propertyCode: data.propertyCode,
      commandName: data.commandName
    }
    
    const lastClickTime = localStorage.getItem('checkTime');
    if (lastClickTime) {
      const timeElapsed = Date.now() - parseInt(lastClickTime, 10);
      if (timeElapsed < 5 * 60 * 1000) { // 5分钟
        setCheck(true)
        return axios.post(`/device/control/${code}/control`, params).then(res => {
          if (res.data) getData();
        })
      }
    }
    setCheck(false)
  }
  return <div className={"pt-table__line text__line"}>
    <div className={"line--left"}>{item.devName}</div>
    <div className={"line--right__text"}>

      {
        item.options.map(opt =>{
          return <div 
            key={opt.value}
            className={`btn_control ${opt.isSelected ? "active" : ""}`}
            onClick={() => { onChange(item , opt.value) }}
            >
              {opt.label}
          </div>
        })
      }

      {/* {
        item.map(_dom => {
          return <div className={`btn_control ${_dom.value ? "active" : ""}`} onClick={() => { onChange(_dom) }}>{_dom.label}</div>
        })
      } */}

    </div> 
  </div>
}
