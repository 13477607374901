interface Props {
  columns: any[];
  dataSource: any[];
  activeData: any;
  maxNumber?: number;
  lineClick?: (value) => void;
}

/**
 * @description                 表格
 * @param columns               每列内容
 * @param dataSource            数据
 * @param maxNumber             最大展示数量
 * @param lineClick             行的点击事件
 */

const GeneralTable = ({
  columns,
  dataSource,
  activeData = {},
  maxNumber = 10,
  lineClick = (value) => {},
}: Props) => {
  const isScroll = dataSource.length > maxNumber;
  const bodyHeight = isScroll
    ? {
        height: `${maxNumber * 40}px`,
      }
    : {};
  return (
    <table className="general_table">
      <thead className={`general_table_head ${isScroll && "scroll_head"}`}>
        <tr>
          {columns.map((item, i) => (
            <th key={i} style={item.width ? { width: item.width } : {}}>
              {item.title}
            </th>
          ))}
          {isScroll && <th></th>}
        </tr>
      </thead>
      <tbody
        className={`general_table_body ${isScroll && "scroll_body"}`}
        style={{
          overflowY: `${isScroll ? "scroll" : "hidden"}`,
          ...bodyHeight,
        }}
      >
        {dataSource.map((item, i) => (
          <tr
            key={i}
            className={`table_body_content content_${
              typeof item.state === "number"
                ? item.state
                  ? "success"
                  : "waring"
                : "success"
            }  
            ${
              item.id === activeData.id &&
              `active_content_${
                typeof item.state === "number"
                  ? item.state
                    ? "success"
                    : "waring"
                  : "success"
              }`
            }`}
            onClick={() => lineClick(item)}
          >
            {columns.map((it, index) => (
              <td key={index} style={it.width ? { width: it.width } : {}}>
                {it.render
                  ? it.render(item[it.dataIndex], i, it.dataIndex)
                  : item[it.dataIndex]
                  ? item[it.dataIndex]
                  : "-"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GeneralTable;
