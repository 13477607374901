/**
 * @description       标题
 */

export const CardTitle = {
  1: {
    title: "场站",
  },
  2: {
    title: "泵站",
  },
  3: {
    title: "管线设施",
  },
  4: {
    title: "负压",
  },
};

/**
 * @description       管线设施tab
 */

export const rightTab1 = [
  {
    value: 0,
    text: "基本信息",
  },
  {
    value: 3,
    text: "维护记录",
  },
];

/**
 * @description         泵站和场站tab
 */

export const rightTab2 = [
  {
    value: 0,
    text: "基本信息",
  },
  {
    value: 1,
    text: "实时监测",
  },
  {
    value: 2,
    text: "远程控制",
  },
  {
    value: 3,
    text: "维护记录",
  },
];

/**
 * @description         泵站和场站状态tab
 */

export const stateTab = [
  {
    value: 0,
    text: "全部",
  },
  {
    value: 1,
    text: "正常",
  },
  {
    value: 4,
    text: "离线",
  },
  {
    value: 3,
    text: "告警",
  },
];

/**
 * @description         泵站与场站详情时间tab
 */

export const detailsTimeTab = [
  {
    value: 0,
    text: "日",
  },
  {
    value: 1,
    text: "周",
  },
  {
    value: 2,
    text: "月",
  },
  {
    value: 3,
    text: "年",
  },
  {
    value: 4,
    text: "自定义",
  },
];

/**
 * @description         泵站与场站详情类型tab
 */

export const detailsTypeTab = [
  {
    value: 0,
    text: "简要数据",
  },
  {
    value: 1,
    text: "详细数据",
  },
];

/**
 * @description         泵站与场站详情时间选择器
 */

export const detailsTimePicker = {
  0: "date",
  1: "week",
  2: "month",
  3: "year",
};

/**
 * @description         镇级皮肤列表
 */

export const MinorSkinList = {
  0: {
    title: "秀屿区",
    code: "350305000000",
  },
  1: {
    title: "笏石镇",
    code: "350305100000",
  },
  2: {
    title: "东庄镇",
    code: "350305101000",
  },
  3: {
    title: "忠门镇",
    code: "350305102000",
  },
  4: {
    title: "东埔镇",
    code: "350305103000",
  },
  5: {
    title: "东峤镇",
    code: "350305104000",
  },
  6: {
    title: "埭头镇",
    code: "350305105000",
  },
  7: {
    title: "平海镇",
    code: "350305106000",
  },
  8: {
    title: "南日镇",
    code: "350305107000",
  },
  9: {
    title: "湄洲镇",
    code: "350305108000",
  },
  10: {
    title: "山亭镇",
    code: "350305109000",
  },
  11: {
    title: "月塘镇",
    code: "350305110000",
  },
};
