import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  PtChildTitle,
  PtBasicInformation,
  PtMaintainRecords,
  PtSearch,
} from "../../../../components";
import PipelineTable from "./components/pipelineTable";
import "./index.scss";

export const PipelineList = ({ id, onChange }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getGroup(2);
  }, []);

  /**
   * @description               查询所有分组信息
   * @param groupType           类型
   */

  const getGroup = (groupType, key = "") => {
    axios
      .get(`/data_group/queryAll`, {
        params: {
          groupType,
          key,
        },
      })
      .then((res: any) => {
        const { code, data } = res;
        if (code === 200) {
          const list = [];
          data.forEach(async (item) => {
            if (item.id > 0) {
              list.push({
                id: item.id,
                title: item.groupName,
                number: item.itemCount,
                children: [],
              });
            }
          });
          setData([...list]);
        }
      });
  };

  /**
   * @description         搜索
   * @param value         搜索内容
   */

  const onSearch = (value) => {
    getGroup(2, value);
  };
  return (
    <>
      <PtChildTitle title={"设施清单"} />
      <div className={"pipeline__list"}>
        <PtSearch onChange={onSearch} />
        <PipelineTable activeId={id} dataSource={data} onClick={onChange} />
      </div>
    </>
  );
};

export const PipelineRight = ({ tabKey, data }) => {
  return (
    <>
      {tabKey === 0 ? (
        <PtBasicInformation data={{}} type={0} />
      ) : (
        <PtMaintainRecords data={{}} />
      )}
    </>
  );
};
