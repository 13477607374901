import { useEffect, useState } from "react";
import { PtBasicInformation, PtMaintainRecords } from "../../../../components";
import { PtRemotely } from "../../../../components/activeContent";
import PumpStationMonitoring from "../pumpStation/pumpStationMonitoring";
import StationsMonitoring from "../stations/stationsMonitoring";

/**
 * @description               泵站、场站右侧内容
 * @param type                类型(1:场站,2:泵站,4:负压)
 * @param tabKey              tab选中内容
 * @param data                左侧选中的内容
 * @param renewState          更新左侧列表
 * @param isClickRecord       控制实时监控下监测指数和报警记录的展开
 */

const RightContent = ({ type, tabKey, data, renewState, isClickRecord }) => {
  const [openKey, setOpenKey] = useState(null);
  useEffect(() => {
    if (isClickRecord) {
      setOpenKey('报警记录');
    }
  }, [isClickRecord]);
  return (
    <>
      {(() => {
        switch (tabKey) {
          case 0:
            return <PtBasicInformation data={data} type={type} />;
          case 1:
            return type === 1 ? (
              <StationsMonitoring
                data={data}
                renewState={renewState}
                openKey={openKey}
                setOpenKey={(value) => setOpenKey(value)}
              />
            ) : (
              <PumpStationMonitoring
                data={data}
                renewState={renewState}
                type={type}
                openKey={openKey}
                setOpenKey={(value) => {
                  setOpenKey(value)
                }}
              />
            );
          case 2:
            return <PtRemotely data={data} />;
          case 3:
            return <PtMaintainRecords data={data} />;
        }
      })()}
    </>
  );
};

export default RightContent;
