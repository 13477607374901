import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import axios from "axios";
import moment from "moment";
import { PtTab, PtTable, PtContentProgress } from "../../../../components";
import { Chart, Interval, Tooltip, Axis, Legend, Annotation } from "bizcharts";
import "./index.scss";
import {
  detailsTimePicker,
  detailsTimeTab,
  detailsTypeTab,
} from "../../../data";

const { RangePicker } = DatePicker;

export const PumpStationDetails = ({ data }) => {
  const [leftKey, setLeftKey] = useState(0);
  const [timeKey, setTimeKey] = useState(0);
  const [typeKey, setTypeKey] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [testList, setTestList] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [time, setTime]: any = useState("");
  const [hintData, setHintData] = useState(null);
  const leftTab = [
    {
      value: 0,
      text: "水处理",
      disabled: false,
    },
    {
      value: 1,
      text: "液位",
      disabled: true,
    },
    {
      value: 2,
      text: "能耗",
      disabled: true,
    },
  ];

  useEffect(() => {
    axios
      .get("/device_latest/statistics", {
        params: {
          code: data.code,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          setHintData(res.data);
        }
      });
    if (time) {
      if (timeKey === 4) {
        let startTime = moment(time[0]).format("YYYY-MM-DD");
        let endTime = moment(time[1]).format("YYYY-MM-DD");
        const params = {
          code: data.code,
          startTime: startTime,
          endTime: endTime,
        };
        getList(params);
        getExcelList(params);
      } else {
        let a = dealWithTime(time, timeKey);
        const params = {
          code: data.code,
          startTime: a[0],
          endTime: a[1],
        };
        getList(params);
        getExcelList(params);
      }
    } else {
      const params = {
        code: data.code,
        type: timeKey + 1,
      };
      getList(params);
      getExcelList(params);
    }
  }, [data.code]);

  const getList = (params) => {
    axios
      .get("/monitor_station_process/init_statistics", { params })
      .then((res: any) => {
        if (res.code === 200) {
          setTestList(res.data);
        }
      });
  };

  const getExcelList = (params) => {
    axios
      .get("/monitor_station_process/init_records", { params })
      .then((res: any) => {
        if (res.code === 200) {
          setExcelList(res.data);
        }
      });
  };

  const timeChange = (date, dateString) => {
    if (dateString) {
      if (dateString.constructor === Array) {
        const params = {
          code: data.code,
          startTime: dateString[0] + " 00:00:00",
          endTime: dateString[1] + " 23:59:59",
        };
        getList(params);
        getExcelList(params);
      } else {
        let a = dealWithTime(date, timeKey);
        const params =
          timeKey === 0
            ? {
              code: data.code,
              startTime: a[0],
            }
            : {
              code: data.code,
              type: timeKey === 3 ? 0 : null,
              startTime: a[0],
              endTime: a[1],
            };
        getList(params);
        getExcelList(params);
      }
      setTime(date);
    } else {
      if (timeKey !== 4) {
        const params = {
          code: data.code,
          type: timeKey + 1,
        };
        getList(params);
        getExcelList(params);
      } else {
        setTestList([]);
      }
      setTime("");
    }
  };
  /**
   * @description 处理时间
   * @param value
   * @param key
   */
  const dealWithTime = (value, key) => {
    let startTime;
    let endTime;
    switch (key) {
      case 0:
        startTime = moment(value).format("YYYY-MM-DD") + " 00:00:00";
        endTime = moment(value).format("YYYY-MM-DD") + " 23:59:59";
        return [startTime, endTime];
      case 1:
        startTime =
          moment(value).startOf("week").format("YYYY-MM-DD") + " 00:00:00";
        endTime =
          moment(value).endOf("week").format("YYYY-MM-DD") + " 23:59:59";
        return [startTime, endTime];
      case 2:
        startTime =
          moment(value).startOf("month").format("YYYY-MM-DD") + " 00:00:00";
        endTime =
          moment(value).endOf("month").format("YYYY-MM-DD") + " 23:59:59";
        return [startTime, endTime];
      case 3:
        startTime =
          moment(value).startOf("year").format("YYYY-MM-DD") + " 00:00:00";
        endTime =
          moment(value).endOf("year").format("YYYY-MM-DD") + " 23:59:59";
        return [startTime, endTime];
    }
  };

  return (
    <>
      <div className={"expand__content__head"}>
        {data.title || data.name} - 数据详情
      </div>
      <div className={"expand__content__select"}>
        <PtTab
          data={leftTab}
          value={leftKey}
          onChange={(value) => {
            setLeftKey(value);
          }}
          auto={false}
        />
        <div className={"select--right"}>
          <PtTab
            data={detailsTimeTab}
            value={timeKey}
            onChange={(value) => {
              setTimeKey(value);
              setTime("");
              setSelectedRowKeys([]);
              if (value !== 4) {
                const params = {
                  code: data.code,
                  type: value + 1,
                };
                getList(params);
                getExcelList(params);
              } else {
                setTestList([]);
              }
            }}
            auto={false}
          />
          {timeKey === 4 ? (
            <RangePicker size={"small"} onChange={timeChange} />
          ) : (
            <DatePicker
              picker={detailsTimePicker[timeKey]}
              size={"small"}
              onChange={timeChange}
            />
          )}
          <PtTab
            data={detailsTypeTab}
            value={typeKey}
            onChange={(value) => {
              setTypeKey(value);
              if (Boolean(value)) {
                if (time) {
                  if (timeKey === 4) {
                    let startTime = moment(time[0]).format("YYYY-MM-DD");
                    let endTime = moment(time[1]).format("YYYY-MM-DD");
                    const params = {
                      code: data.code,
                      startTime: startTime,
                      endTime: endTime,
                    };
                    getList(params);
                    getExcelList(params);
                  } else {
                    let a = dealWithTime(time, timeKey);
                    const params = {
                      code: data.code,
                      startTime: a[0],
                      endTime: a[1],
                    };
                    getList(params);
                    getExcelList(params);
                  }
                } else {
                  const params = {
                    code: data.code,
                    type: timeKey + 1,
                  };
                  getList(params);
                  getExcelList(params);
                }
              }
            }}
          />
        </div>
      </div>
      {Boolean(typeKey) ? (
        <>
          <PtTable
            data={excelList}
            columns={[
              {
                title: "开始记录时间",
                key: "startTime",
              },
              {
                title: "结束记录时间",
                key: "endTime",
              },
              {
                title: "处理量",
                key: "process",
              },
            ]}
            rowSelection={{
              selectedRowKeys,
              onChange: (value) => {
                setSelectedRowKeys(value);
              },
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <div
              style={{
                width: "86px",
                height: "24px",
                lineHeight: "24px",
                background: "#02D281",
                borderRadius: "2px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                const token = localStorage.getItem("access_token");
                let xhr = new XMLHttpRequest();
                const url = axios.defaults.baseURL
                xhr.open(
                  "post",
                  `${url}/monitor_station_process/export_excel`,
                  true
                ); // 也可以使用POST方式，根据接口
                xhr.responseType = "blob"; // 返回类型blob
                xhr.setRequestHeader("Authorization", "bearer " + token);
                xhr.setRequestHeader("content-type", "application/json");
                xhr.onreadystatechange = () => { };
                xhr.onload = function () {
                  if (this.status === 200) {
                    var blob = this.response;
                    var reader = new FileReader();
                    reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                    reader.onload = function (e: any) {
                      var a: any = document.createElement("a");
                      a.download = `${data.title || data.name}历史记录.xlsx`; //下载文件名
                      a.href = e.target.result;
                      a.click();
                      window.URL.revokeObjectURL(e.target.result);
                    };
                  }
                };
                xhr.send(JSON.stringify(selectedRowKeys));
              }}
            >
              下载({selectedRowKeys.length})
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"expand__content__background"}>
            {hintData && <PumpStationCharts data={testList} />}
          </div>
          <div className={"expand__content__hint"}>
            <div className={"hint__head"}>
              <div className={"hint__head--left"}>提示</div>
            </div>
            {hintData && (
              <div>
                <div style={{ marginBottom: "18px" }}>
                  <PtContentProgress
                    data={hintData.today}
                    title={hintData.today.have.toString() + "m³"}
                    color={"rgba(0, 145, 255, 1)"}
                  />
                </div>
                <div style={{ marginBottom: "18px" }}>
                  <PtContentProgress
                    data={hintData.yest}
                    title={hintData.yest.have.toString() + "m³"}
                    color={"rgba(255, 255, 255, 0.8)"}
                  />
                </div>
                <PtContentProgress
                  data={hintData.avg}
                  title={hintData.avg.have.toString() + "m³"}
                  color={"rgba(0, 255, 203, 1)"}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

/**
 * @description  数据详情-统计图
 */

const PumpStationCharts = ({ data = [], height = "463px", scale = {} }) => {
  return (
    <Chart
      height={height}
      autoFit
      data={data}
      interactions={["active-region"]}
      padding="auto"
      scale={scale}
    >
      <Axis
        name="proc"
        line={{
          style: {
            stroke: "rgba(110,110,110,0.8)",
          },
        }}
        grid={{
          line: {
            style: {
              stroke: "rgba(110,110,110,0.5)",
              lineDash: [4, 2],
            },
          },
        }}
      />
      <Interval
        position="date*proc"
        color={[
          "waringFlag",
          (value) => {

            return "l (270) 0:#07C3FF  1:#1EE0A5";
          },
        ]}
        size={10}
      />
      <Legend visible={false} />
      <Annotation.Line
        start={["min", data.length > 0 ? data[0].waringValue : 0]}
        end={["max", data.length > 0 ? data[0].waringValue : 0]}
        style={{
          lineDash: [2, 4],
          stroke: "rgba(255, 69, 69, 1)",
        }}
      />
      <Tooltip>
        {(title, items) => {
          return (
            <div style={{ padding: "8px 0" }}>
              <div
                style={{
                  fontSize: "14px",
                  marginBottom: "12px",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "18px", marginRight: "10px" }}>
                  处理量
                </span>
                <span style={{ fontSize: "18px" }}>
                  {items[0].data.proc}
                  <span style={{ fontSize: "12px", color: "rgba(0,0,0,0.8)" }}>
                    m³
                  </span>
                </span>
              </div>
            </div>
          );
        }}
      </Tooltip>
    </Chart>
  );
};
