/*
 * @Author: your name
 * @Date: 2024-06-04 20:47:32
 * @LastEditors: Chen
 * @LastEditTime: 2024-07-25 11:12:26
 * @Description: In User Settings Edit
 * @FilePath: \smurfs-react\src\components\header\components\weather.tsx
 */
import { useEffect, useState } from "react";

const Weather = () => {
  const [weatherData, setWeatherData] = useState({} as any);

  useEffect(() => {
    getWeatherData();
  }, []);

  const getWeatherData = () => {
    fetch(
      "https://restapi.amap.com/v3/weather/weatherInfo?city=350305&key=bd55d832dded914273783000503864a9&extensions=all"
    )
      .then((res) => res.json())
      .then((res) => {
        // setWeatherData(res);
        const data = res.forecasts ? res.forecasts[0] : []
        if(data?.casts.length){
          const casts = data.casts[0]
          setWeatherData(casts);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="weather">
      {weatherData.nighttemp_float}～{weatherData.daytemp_float}℃ {weatherData.dayweather}
    </div>
  );
};

export default Weather;
