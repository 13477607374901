import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle4 from "../../general/excelStyle4";

interface Props {
  leftData: any;
  dataSource: any;
}

/**
 * @description         场站维修
 */

const StationsExcel2 = ({ leftData, dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "站点名称",
              value: dataSource.title,
            },
            {
              title: "维修日期",
              value: dataSource.time,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "设备名称",
              value: "***",
            },
            {
              title: "数量",
              value: "200",
            },
            {
              title: "型号参数",
              value: "***",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle4
          dataSource={[
            {
              title: "问题描述",
              value: "暂未填写",
              type: 1,
            },
            {
              title: "维护情况",
              value: "暂未填写",
              type: 1,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "维护人员",
              value: "",
            },
            {
              title: "审核人",
              value: "",
            },
          ]}
        />
      </div>
    </>
  );
};

export default StationsExcel2;
