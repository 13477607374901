/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Chen
 * @description: 描述页面内容
 * @Date: 2022-05-09 11:16:02
 * @LastEditTime: 2024-09-18 09:53:43
 */
import Cesiums from "./Cesium";



export default class Event extends Cesiums {
    viewer: any;
    sds: any;
    /**
     * 初始化事件
     * 初始化所有常量
     * @param sub 初始化参数
     */
    constructor(viewer: any) {
        super();
        this.viewer = viewer;
    }

    /**
     * 单击获取点位信息
     */
    init = () => {
        this.viewer.screenSpaceEventHandler.setInputAction((clickEvent: any) => {
            var ray = this.viewer.camera.getPickRay(clickEvent.position);

            var cartesian = this.viewer.scene.globe.pick(ray, this.viewer.scene);

            var cartographic = this.Cesium.Cartographic.fromCartesian(cartesian);

            var lng = this.Cesium.Math.toDegrees(cartographic.longitude);//经度值

            var lat = this.Cesium.Math.toDegrees(cartographic.latitude);//纬度值
            var height = this.viewer.scene.globe.getHeight(cartographic);

            var mapPosition = { x: lng, y: lat, z: height.height };//height的值为地形高度。
            console.log( );


        }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);

    };
    /**
     * 鼠标移动事件
     */
    mouseMove = (event: any) => {
        this.viewer.screenSpaceEventHandler.setInputAction((clickEvent: any) => {
            // ids = pickedFeature;
            event(this.viewer.scene.pick(clickEvent.endPosition))
        }, this.Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    }
    /**
     * 左键单击获取实体
     */
    clickLeft = (event: any) => {
        this.viewer.screenSpaceEventHandler.setInputAction((clickEvent: any) => {
            let pickObj = this.viewer.scene.pick(clickEvent.position);
            if (pickObj) event(pickObj, clickEvent);
        }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }
    /**
    * 右键单击获取实体
    */
    clickRight = (event: any) => {

        this.viewer.screenSpaceEventHandler.setInputAction((clickEvent: any) => {
            let pickObj = this.viewer.scene.pick(clickEvent.position);
            if (pickObj) event(pickObj, clickEvent);
        }, this.Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    }
    /**
     * @description: 滚轮滚动事件
     * @param {*}
     * @return {*}
     */
    wheel = (event: any) => {

        this.viewer.screenSpaceEventHandler.setInputAction((clickEvent: any) => {
            event()
        }, this.Cesium.ScreenSpaceEventType.WHEEL);
    }
    /**
     * 移除右击
     */
    removeClickRight = () => {

        this.viewer.screenSpaceEventHandler.removeInputAction(this.Cesium.ScreenSpaceEventType.RIGHT_CLICK); //移除事件监听
    }
}



//三维点转二维点
export function point3dToPoint2d(point3d: any) {

    let c = this.Cesium.Cartographic.fromCartesian(point3d);
    return [this.Cesium.Math.toDegrees(c.longitude), this.Cesium.Math.toDegrees(c.latitude)];
}