import React, { Component } from "react";
import { PtHeader, PtSideBar } from "../../components";
import { connect } from "react-redux";
import { MainSkin, MinorSkin, AssetsSkin, VillageSkin } from "../../skin";
import axios from "axios";
import { legendList2, personalPoint } from "../data";
import CesMap from "../../modules/ces2d/init2d";
import "./index.scss";
import { homeActionCreators } from "../../store/home";
import { DistanceDisplayCondition, GeoJsonDataSource } from "cesium";
import ModuleSkin from "../../skin/moduleSkin";
import FlowChart from "../../components/flowChart";
import MonitorModel from "../../components/monitorModel";

interface Props {
  headSuffixKey: number;
  headDrawerKey: number;
  headModuleKey: string;
  headAreaName: string;
  homeMaskVisible: boolean;
  homeFlowChartData: {
    visible: boolean;
    activeData: any;
  };
  homeMonitorShow: {
    visible: boolean;
    activeId: number | null;
  };
  changeActive: (data) => void;
  setSuTangList: (data) => void;
}

interface State {
  CesMaps: any;
  CVector: any;
  PointList: any;
  kmlLayer: any;
  EntityListId: any;
}

class HomePage extends Component<Props, State> {
  bz: any = []; // 泵站
  cz: any = []; // 场站
  zg: any = []; // 主管
  state = {
    CesMaps: null,
    CVector: null,
    PointList: [],
    kmlLayer: null,
    EntityListId: {
      state: false,
      stateKml: false,
      listId: [],
      KMLId: [],
    },
  };

  componentDidMount() {
    const token = localStorage.getItem("access_token");

    if (token) {
      this.initMap();
    } else {
      window.location.href = "/";
    }
  }

  /**
   * 初始化地图，调用封装好的OL
   * 实现地图渲染
   */
  initMap = () => {
    let CesMaps = new CesMap();

    let p = new Promise((r, e) => {
      CesMaps.Viewers("mapContainer");
      window["CesMaps"] = CesMaps;
      this.setState({
        CesMaps,
      });
      r(true);
    });
    p.then(() => {
      const { viewer } = CesMaps;
      CesMaps.setView();
      viewer.camera.changed.addEventListener(() => {
        let { height } = viewer.camera.positionCartographic;        
        const baselayer = viewer.imageryLayers.get(0);
        baselayer.show = height > 68682.34391666825;
        const layer_tdt = viewer.imageryLayers.get(1);
        viewer.entities.getById("COUNTYBORDER").show = height > 5000;
        if (layer_tdt) {
          layer_tdt.show = height < 68682.34391666825;
        }
        const layer_st = viewer.imageryLayers.get(2);
        if (layer_st) {
          layer_st.show = height < 6000;
        }
        const layer_xx = viewer.imageryLayers.get(3);
        if (layer_xx) {
          layer_xx.show = height < 6000;
        }

        this.bz.forEach(item => item.show = height < 6000)
        this.cz.forEach(item => item.show = height < 6000)
        this.zg.forEach(item => item.show = height < 6000)
        viewer.scene.fxaa = height < 5000;
        viewer.scene.postProcessStages.fxaa.enabled = height < 5000;
      });

      this.setCountyBorder();
      this.addHomePoint();
      this.addPoint();
      // this.addline();
      this.renderTown();
    });
  };
  /**
   * @description: 乡镇边界添加
   * @param {*}
   * @return {*}
   */
  setCountyBorder = () => {
    const { Color, viewer, entities, KmlData } = this.state.CesMaps;

    var entities_ = new entities(viewer);
    entities_.options({
      id: "COUNTYBORDER",
      polygon: {
        positions: [
          [117.61471002942288, 23.97366802363653],
          [117.61471002942288, 26.456195475656003],
          [120.94376282050388, 26.456195475656003],
          [120.94376282050388, 23.97366802363653],
        ],
        color: "#2d6cbe65",
        outlineColor: "#fff",
      },
    });
    let sa = entities_.add();
    sa.polygon.height = -10;

    const geojsonDs = new GeoJsonDataSource("xiuyuqu");
    const geoJson = geojsonDs.load(require("../../assets/json/xiuyuqu.json"));
    geoJson.then((res) => {
      const entities = res.entities.values;

      entities.forEach((entity, index) => {
        if (!entity.polygon) return;
        const isHas =
          ["忠门镇", "山亭镇", "湄洲镇", "东埔镇"].indexOf(entity.name) !== -1;
        if (isHas) {
          entity.polygon.material = Color(0, 0, 0, 0.5);
        } else {
          entity.addProperty("static");
          entity.addProperty("active");
          entity.polygon.material = Color(65, 137, 242, 0.6);
          // @ts-ignore
          entity.static = Color(65, 137, 242, 0.6);
          // @ts-ignore
          entity.active = Color(107, 236, 245, 0.15);
        }
        // @ts-ignore
        entity.type = "Town";
        // @ts-ignore
        entity.polygon.outline = true;
        // @ts-ignore
        entity.polygon.outlineWidth = 5;
        // @ts-ignore
        entity.polygon.distanceDisplayCondition = new DistanceDisplayCondition(
          15000,
          150000
        );
        // @ts-ignore
        entity.polygon.outlineColor = Color(107, 236, 245, 1);
      });
      viewer.dataSources.add(res);
    });
  };
  /**
   * @description: 行政村边界添加
   * @param {*}
   * @return {*}
   */
  renderTown = () => {
    const { CesMaps } = this.state;
    const { viewer, Color } = CesMaps;
    const geojsonDs = new GeoJsonDataSource("cj");
    const geoJson = geojsonDs.load(require("../../assets/json/cj.json"));
    geoJson.then((res) => {
      const entities = res.entities.values;

      entities.forEach((entity, index) => {
        if (!entity.polygon) return;
        // @ts-ignore
        entity.polygon.material = Color(65, 137, 242, 0.35);
        // @ts-ignore
        entity.static = Color(65, 137, 242, 0.6);
        // @ts-ignore
        entity.active = Color(107, 236, 245, 0.15);
        // @ts-ignore
        entity.type = "Village";
        // @ts-ignore
        entity.polygon.outline = true;
        // @ts-ignore
        entity.polygon.distanceDisplayCondition = new DistanceDisplayCondition(
          3000,
          15000
        );
        // @ts-ignore
        entity.polygon.outlineColor = Color(221, 229, 255, 1);
      });
      viewer.dataSources.add(res);
    });
    // viewer.dataSources.add(geoJson, {
    //   color: "#2d6cbe65",
    //   outlineColor: "#fff",
    // })
  };

  /**
   * @description: 绘制首页乡镇图标
   * @param {*}
   * @return {*}
   */
  addHomePoint = () => {
    let { CesMaps } = this.state;
    let { townshipLevel } = CesMaps.data;
    townshipLevel.point.forEach((item) => {
      let entities = new CesMaps.entities(CesMaps.viewer);
      entities.options({
        billboard: {
          Zindex: 80000,
          url: !item.status ? townshipLevel.imgurl : townshipLevel.imgurl1,
          scale: 1,
          distanceDisplayCondition: new DistanceDisplayCondition(15000, 150000),
        },
        position: item.postiton,
        type: "HOMETUBIAO",
        label: {
          text: item.name,
          Zindex: 80000,
          backgroundColor: "#02010007",
          scale: 0.15,
          pixelOffsetY: -5,
          distanceDisplayCondition: new DistanceDisplayCondition(15000, 150000),
        },
      });
      entities.add();
    });
  };

  /**
   * @description: 添加所有的点位信息{管井、泵站}
   * @param {*}
   * @return {*}
   */
  addPoint = () => {
    const {
      CesMaps: { entities, viewer },
    } = this.state;
    //重点展示泵code
    const pointList = [
      "338221054736",
      "338220100348",
      "300221060118",
      "383330",
      "338221050877",
      "338221063065",
    ];
    /**
     * 全部的泵站点位
     */

    axios.get(`/device/query_all_map_by_type/6`).then((res: any) => {
      if (res.code === 200) {
        res.data.forEach((it) => {
          if (it.position.x && it.position.y) {
            let entites_ = new entities(viewer);
            let imageUrl =
              require(`../../assets/icon/legendImage9.svg`).default;
            // if (it.alarmNowNum > 0) {
            //   console.log(it)
            //   imageUrl = require(`../../assets/icon/alarm.svg`).default;
            // }
            if (it.hasFlowmeter) {
              if (it.state != 1) {
                imageUrl = require(`../../assets/icon/legendImage1.svg`).default;
              } else {
                imageUrl = require(`../../assets/icon/legendImage15.svg`).default;
              }
            }
            if (it.state === 3)
              imageUrl = require(`../../assets/icon/warning2.svg`).default;
            if (it.state === 4)
              imageUrl = require(`../../assets/icon/warning1.svg`).default;
            entites_.options({
              name: it.name,
              id: it.id + "quanbu",
              data: { ...it },
              position: [it.position.x, it.position.y],
              type: "POINT_BENG",
              billboard: {
                url: imageUrl,
                Zindex: 80000,
                scale: pointList.includes(it.code) ? 1.3 : 1,
              },
              label: {
                text: it.name ? it.name : "",
                Zindex: 10000,
                backgroundColor: "#02010007",
                pixelOffsetY: -15,
              },
            });
            entites_.add();
            this.bz.push(viewer.entities.getById(it.id + "quanbu"));
            
          }
          this.bz.forEach(item => item.show = false)
        });
      }
    });

    axios.get(`/device/query_all_map_by_type/7`).then((res: any) => {
      if (res.code === 200) {
        res.data.forEach((it) => {
          if (it.position.x && it.position.y) {
            let entites_ = new entities(viewer);
            let imageUrl =
              require(`../../assets/icon/legendImage10.svg`).default;
            // if (it.alarmNowNum > 0)
            //   imageUrl = require(`../../assets/icon/alarm.svg`).default;
            if (it.state !== 1)
              imageUrl = require(`../../assets/icon/warning.svg`).default;
            entites_.options({
              name: it.name,
              id: it.id + "quanbuchang",
              data: { ...it },
              position: [it.position.x, it.position.y],
              type: "POINT_CHANG",
              billboard: {
                url: imageUrl,
                Zindex: 80000,
                scale: 1.4,
              },
              label: {
                text: it.name ? it.name : "",
                Zindex: 10000,
                backgroundColor: "#02010007",
                pixelOffsetY: -15,
              },
            });
            entites_.add();
            this.cz.push(viewer.entities.getById(it.id + "quanbuchang"));
          }
        });
        this.cz.forEach(item => item.show = false)
      }
    });
    personalPoint.forEach((it) => {
      let entites_ = new entities(viewer);
      let imageUrl = require(`../../assets/icon/legendImage9.svg`).default;
      entites_.options({
        name: it.name,
        id: it.id + "personal",
        data: { ...it },
        position: it.position,
        type: "POINT_PERSONAL_BENG",
        billboard: {
          url: imageUrl,
          Zindex: 80000,
          scale: 1,
        },
        label: {
          text: it.name,
          Zindex: 10000,
          backgroundColor: "#02010007",
          pixelOffsetY: -15,
        },
      });
      entites_.add();
      this.cz.push(viewer.entities.getById(it.id + "personal"));
    });
    this.cz.forEach(item => item.show = false)
  };

  /**
   * @description: 添加主管数据
   * @param {*}
   * @return {*}
   */
  addline = () => {
    const { entities, viewer } = this.state.CesMaps;
    const { EntityListId } = this.state;
    axios
      .get("/dxf_layer/get_all_equipment", {
        params: { groupType: 2, legend: 2 },
      })
      .then((res: any) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            const { id, geoLine } = item;
            let entitie_ = new entities(viewer);
            entitie_.options({
              name: item.serial,
              id: id + "zg_active",
              data: item,
              type: "wushui",
              polyline: {
                flow: 1,
                positions: geoLine,
                speed: 1000,
                flowColor: "#bbbbbbbb",
                staticColor: "#0d7eff",
                lineWidth: 3,
                zIndex: 10000,
              },
            });
            entitie_.add();
            this.zg.push(viewer.entities.getById(id + "zg_active"));
            EntityListId.listId.push(id + "zg_active");
            let entitie__ = new entities(viewer);
            entitie__.options({
              name: item.serial,
              id: id + "zg_static",
              data: { ...item },
              type: "wushui",
              polyline: {
                flow: 0,
                positions: geoLine,
                speed: 1000,
                flowColor: "#bbbbbb99",
                staticColor: "#0d7eff",
                lineWidth: 3,
                zIndex: 1000,
              },
            });
            entitie__.add();
            EntityListId.listId.push(id + "zg_static");
            this.zg.push(viewer.entities.getById(id + "zg_static"));
          });
          this.zg.forEach(item => item.show = false)
        }
      });
  };

  villageControl = (index) => {
    switch (index) {
      case 0:
        this.bz.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
      case 1:
        this.cz.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
      case 2:
        this.zg.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
    }
  };

  render() {
    const {
      headSuffixKey,
      headDrawerKey,
      headModuleKey,
      homeFlowChartData,
      homeMonitorShow,
    } = this.props;
    const { CesMaps } = this.state;
    return (
      <div className={"pt-home"}>
        <div className={"home-mask"} />
        <PtHeader CesMaps={CesMaps} />
        {headDrawerKey === 0 && headModuleKey === "0" ? (
          headSuffixKey === 0 ? (
            <MainSkin />
          ) : headSuffixKey > 11 ? (
            <VillageSkin CesMaps={CesMaps} control={this.villageControl} />
          ) : (
            <MinorSkin />
          )
        ) : headModuleKey === "0" ? (
          <AssetsSkin />
        ) : (
          <ModuleSkin />
        )}
        {headSuffixKey < 12 && (
          <div className="legend-box">
            <span className="">图例:</span>
            <div className="legend-list">
              {legendList2.map((item, i) => (
                <div key={i} className={"legend-content"}>
                  <img src={item.icon} alt="" />
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {homeFlowChartData.visible && (
          <FlowChart data={homeFlowChartData.activeData} />
        )}
        {homeMonitorShow.visible && <MonitorModel />}
        <PtSideBar />
      </div>
    );
  }
}

const mapState = (state) => ({
  headSuffixKey: state.getIn(["header", "suffixKey"]),
  headDrawerKey: state.getIn(["header", "drawerKey"]),
  headModuleKey: state.getIn(["header", "moduleKey"]),
  headAreaName: state.getIn(["header", "areaName"]),
  homeMaskVisible: state.getIn(["home", "maskVisible"]),
  homeFlowChartData: state.getIn(["home", "flowChartData"]),
  homeMonitorShow: state.getIn(["home", "monitorShow"]),
});

const mapDispatch = (dispatch) => ({
  changeActive(data) {
    dispatch(homeActionCreators.setAssetsSkinActiveData(data));
  },
  setSuTangList(data) {
    dispatch(homeActionCreators.setSuTangList(data));
  },
});

export default connect(mapState, mapDispatch)(HomePage);
