import { Col, Row } from "antd";

interface Props {
  dataSource: any[];
}

const ExcelStyle2 = ({ dataSource }: Props) => {
  return (
    <Row>
      {dataSource.map((item, i) => (
        <Col key={i} span={item.span}>
          <div className="excel_title2">{item.title}</div>
        </Col>
      ))}
    </Row>
  );
};

export default ExcelStyle2;
