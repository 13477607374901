const WaterData = [
    { title: "日均净水量", value: 1300, unit: "m³" },
    // { text: "管网公里数", value: 500, unit: "km" },
    // { text: "运行泵站数", value: "216", unit: "台" },
    // { text: "接入户数量", value: "4567", unit: "户" },
]

// 在状态state中
// 1——运行  -1——鼓掌  -2——停止
// 在水质waterQuality中
// 1-6分别代表水质由低到高：
const stateColorMap = {
    "1": '#02D281',
    "-1": '#F76600',
    "-2": '#FFFFFF',
    "0": '#6D7278',
};
const waterColorMap = {
    "1": '#E96F65',
    "2": '#FFC96F',
    "3": '#02D281',
    "4": '#00FFCB',
    "5": '#0091FF',
    "6": '#88B6F1',
    "0": '#6D7278',
    "-1": '#6D7278',
    "-2": '#6D7278',
};

// 场站运行状态组件中的数据
// 在状态state中
// 1——运行  -1——告警  -2——停止
// 在水质waterQuality中
// 1-6分别代表水质由低到高：
const stationStateList = [
    {
        title: '笏石镇', type: '场站', number: 30,
        state: {
            number: 30,
            numberList: [{ name: '运行', number: 27, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 3, color: '#FFFFFF' }],
            list: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, -2, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1]
        },
        waterQuality: {
            number: 30,
            numberList: [],
            list: []
        }
    },
    {
        title: '埭头镇', type: '场站', number: 30,
        state: {
            number: 30,
            numberList: [{ name: '运行', number: 27, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 3, color: '#FFFFFF' }],
            list: [1, 1, 1, 1, -2, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, -2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        waterQuality: {
            number: 30,
            numberList: [],
            list: []
        }
    },
    {
        title: '平海镇', type: '场站', number: 30,
        state: {
            number: 30,
            numberList: [{ name: '运行', number: 26, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 4, color: '#FFFFFF' }],
            list: [-2, 1, 1, 1, 1, -2, 1, 1, 1, -2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1]
        },
        waterQuality: {
            number: 30,
            numberList: [],
            list: []
        }
    },
    {
        title: '东峤镇', type: '场站', number: 30,
        state: {
            number: 30,
            numberList: [{ name: '运行', number: 29, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 1, color: '#FFFFFF' }],
            list: [-2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        waterQuality: {
            number: 30,
            numberList: [],
            list: []
        }
    },
    {
        title: '月塘镇', type: '场站', number: 30,
        state: {
            number: 30,
            numberList: [{ name: '运行', number: 27, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 3, color: '#FFFFFF' }],
            list: [1, 1, 1, 1, -2, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        waterQuality: {
            number: 30,
            numberList: [],
            list: []
        }
    },
    {
        title: '南日镇', type: '场站', number: 30,
        state: {
            number: 30,
            numberList: [{ name: '运行', number: 28, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 2, color: '#FFFFFF' }],
            list: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1]
        },
        waterQuality: {
            number: 30,
            numberList: [],
            list: []
        }
    }
]

// 场站运行组件中的图例数据
const legendList = [
    {
        title: '状态',
        list: [
            { tagColor: '#02D281', tagName: '运行' },
            { tagColor: '#F76600', tagName: '告警' },
            { tagColor: '#FFFFFF', tagName: '离线' }
        ]
    }
]
// 提升泵站运行状态组件中的数据
// 在状态state中
// 1——运行  -1——告警  -2——停止
// 在流量traffic中
// 1——正常  -1——过低  2——过高
const pumpstationStateList = [
    {
        title: '笏石镇', type: '泵站',
        state: {
            number: 27,
            numberList: [{ name: '运行', number: 22, color: '#02D281' }, {
                name: '告警',
                number: 2,
                color: '#F76600'
            }, { name: '离线', number: 3, color: '#FFFFFF' }],
            list: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, -2, 1, 1, 1, -1, -1, 1, -1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, -2, 1, 1, 0, 0, 0, 0, 0]
        },
        traffic: {
            number: 45,
            numberList: [],
            list: []
        }
    },
    {
        title: '埭头镇', type: '泵站',
        state: {
            number: 27,
            numberList: [{ name: '运行', number: 22, color: '#02D281' }, {
                name: '告警',
                number: 2,
                color: '#F76600'
            }, { name: '离线', number: 3, color: '#FFFFFF' }],
            list: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 1, 1, -2, 1, 1, 1, -1, -1, 1, -1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -2, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        traffic: {
            number: 40,
            numberList: [],
            list: []
        }
    },
    {
        title: '平海镇', type: '泵站',
        state: {
            number: 0,
            numberList: [{ name: '运行', number: 0, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 0, color: '#FFFFFF' }],
            list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        traffic: {
            number: 0,
            numberList: [],
            list: []
        }
    },
    {
        title: '东峤镇', type: '泵站',
        state: {
            number: 0,
            numberList: [{ name: '运行', number: 0, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 0, color: '#FFFFFF' }],
            list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        traffic: {
            number: 0,
            numberList: [],
            list: []
        }
    },
    {
        title: '月塘镇', type: '泵站',
        state: {
            number: 0,
            numberList: [{ name: '运行', number: 0, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 0, color: '#FFFFFF' }],
            list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        traffic: {
            number: 0,
            numberList: [],
            list: []
        }
    },
    {
        title: '南日镇', type: '泵站',
        state: {
            number: 0,
            numberList: [{ name: '运行', number: 0, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 0, color: '#FFFFFF' }],
            list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        traffic: {
            number: 0,
            numberList: [],
            list: []
        }
    },
    {
        title: '东庄镇', type: '泵站',
        state: {
            number: 0,
            numberList: [{ name: '运行', number: 0, color: '#02D281' }, {
                name: '告警',
                number: 0,
                color: '#F76600'
            }, { name: '离线', number: 0, color: '#FFFFFF' }],
            list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        traffic: {
            number: 0,
            numberList: [],
            list: []
        }
    }
]
//提升泵站运行状态组件中的图例数据
const pumplegendList = [
    {
        title: '状态',
        list: [
            { tagColor: '#02D281', tagName: '运行' },
            { tagColor: '#F76600', tagName: '告警' },
            { tagColor: '#FFFFFF', tagName: '离线' }
        ]
    }
]

const progressbarData = [
    [
        {
            id: "01",
            title: "芴石镇",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "02",
            title: "东庄镇",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "03",
            title: "平海镇",
            content: []
        },
        {
            id: "04",
            title: "东峤镇",
            content: []
        },
    ],
    [
        {
            id: "05",
            title: "埭头镇",
            content: []
        },
        {
            id: "06",
            title: "南日镇",
            content: []
        },
        {
            id: "07",
            title: "月塘镇",
            content: []
        },
    ]
]


const trafficColorMap = {
    "1": '#02B5F3',
    "-1": '#FFC96F',
    "-2": '#E96F65',
    "0": '#6D7278',
    "2": '#6D7278',
};


const wsVillageList = ["西徐村", "珠坑村", "下郑村", "顶社村", "坝津村", "岭美村", "杨林村", "刘厝村", "度田村", "岐厝村", "梅山村", "松林村", "丙仑村", "炮厝村", "温东村", "篁山村", "西田村", "四新村", "东华村", "秀山村", "来塘村", "濑垞村", "苏塘村", "大丘村", "四村村", "居委会"];

const circleData = {
    total: 100, data: [
        { type: '完成', value: 'complete', number: 60, color: '#00FFCB' },
        { type: '告警', value: 'warn', number: 30, color: '#E34438' },
        { type: '处理', value: 'handle', number: 10, color: '#FFC96F' }
    ]
};

const progressbarData2 = [
    [
        {
            id: "01",
            title: "下郑村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "02",
            title: "丙仑村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "03",
            title: "东华村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "04",
            title: "刘厝村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "05",
            title: "北埔村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "06",
            title: "四新村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "07",
            title: "四村村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "08",
            title: "坝津村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "09",
            title: "大坵村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "10",
            title: "岐厝村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "11",
            title: "吴黄村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "12",
            title: "度田村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "13",
            title: "来塘村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
    ],
    [
        {
            id: "14",
            title: "来垞村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "15",
            title: "顶社村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "16",
            title: "松林村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "17",
            title: "梅山村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "18",
            title: "温东村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "19",
            title: "炮厝村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "20",
            title: "珠坑村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "21",
            title: "秀山村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "22",
            title: "居委会",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "23",
            title: "篁山村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "24",
            title: "苏塘村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "25",
            title: "西徐村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
        {
            id: "26",
            title: "西田村",
            content: [
                {
                    title: "每月巡检率",
                    value: 89,
                    color: "#00FFCB"
                },
                {
                    title: "事件处理率",
                    value: 81,
                    color: "#00BEFF"
                }
            ]
        },
    ]
]

const minorOperatingIndexData = [
    { "name": "西徐A", "data": [20, 40], "status": 2, "isOpen": 1 },
    { "name": "度田A1", "data": [20, 40], "status": 2, "isOpen": 1 },
    { "name": "度田A4", "data": [20, 40], "status": 2, "isOpen": 1 },
    { "name": "度田B3", "data": [20, 40], "status": 2, "isOpen": 1 },
    { "name": "杨林3", "data": [20, 40], "status": 2, "isOpen": 1 },
    { "name": "杨林5", "data": [20, 40], "status": 2, "isOpen": 1 },
]

export {
    WaterData, stateColorMap, waterColorMap, stationStateList, legendList, pumpstationStateList, pumplegendList,
    progressbarData, trafficColorMap, wsVillageList, circleData, progressbarData2, minorOperatingIndexData
}