import axios from "axios";
import { useEffect, useState } from "react";
import GeneralList from "../../general/generalList";
import GeneralPersonnel from "../../general/generalPersonnel";
import GeneralTitle from "../../general/generalTitle";
import moment from "moment";

interface Props {
  activeData: any;
}

const Emergency2Right = ({ activeData }: Props) => {
  const [dataSource, setDataSource] = useState({});

  const [listData, setListData] = useState([]);

  useEffect(() => {
    getData();
    getPosition();
  }, [activeData.id]);

  const getPosition = () => {
    const time = new Date();
    axios
      .get(`/work_order/forward_app_web/query/locus`, {
        params: {
          name: activeData.name,
          starttime: `${moment(time).format("YYYY-MM-DD")} 00:00:00`,
          endtime: `${moment(time).format("YYYY-MM-DD")} 23:59:59`,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          //
        }
      });
  };

  const getData = () => {
    // axios
    //   .get(`/user/get_emer_info/${activeData.id}`)
    //   .then((res: any) => {
    //     if (res.code === 200) {
    //       setDataSource(res.data);
    //       setListData(res.data.eventList);
    //     }
    //   });
    setDataSource(activeData);
    setListData([
      {
        title: "苏塘村4号泵设备停电",
        createTime: "2023.01.16",
        status: 1,
      },
    ]);
  };

  return (
    <div className="emergency2_right">
      <GeneralTitle title={"基本信息"}>
        <GeneralPersonnel dataSource={dataSource} type={2} />
      </GeneralTitle>
      <GeneralTitle title={"应急事件处理列表"}>
        <div className="list_box">
          {listData.map((item, i) => (
            <GeneralList key={i}>
              <div className="list_left" title={item.title}>
                {item.title}
              </div>
              <div className="list_right">
                <div>{item.createTime}</div>
                <div
                  className={`state_box ${
                    item.status === 1 ? "success" : "warning"
                  }`}
                >
                  {item.status === 1 ? "已处理" : "未处理"}
                </div>
              </div>
            </GeneralList>
          ))}
        </div>
      </GeneralTitle>
    </div>
  );
};

export default Emergency2Right;
