import { useState, useEffect } from "react";

import { PtChildTitle } from "../../../../../components";
import GeneralTable from "../../general/generalTable";
import axios from "axios";

interface Props {
  activeData: any;
  setActiveData: (value) => void;
}

// const newAxios = axios.create({
//   baseURL: "https://app.cthd.cc/public",
// });

const PatrolLeft = ({ activeData, setActiveData }: Props) => {
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
    },
    {
      title: "联系方式",
      dataIndex: "phone",
      width: 194,
    },
    {
      title: "状态",
      dataIndex: "state",
      width: 60,
      render: (data) => (
        <div className={`state_box`}>{data ? "在岗" : "休假"}</div>
      ),
    },
  ];

  /**
   * @description         获取列表数据
   * @param name          关键词
   */

  const getDataSource = (name: string) => {
    axios.get("/user/get_by_role", { params: { role: 7 } }).then((res: any) => {
      console.log(res);
      if (res.code === 200) {
        setDataSource(res.data);
        setActiveData({});
      }
    });

    // newAxios.get("/locus/list").then((res) => {
    //   if (res.data.success) {
    //     setDataSource(res.data.data);
    //     setActiveData({});
    //   }
    // });
  };

  const tableClick = (value) => {
    if (value.id === activeData.id) {
      setActiveData({});
    } else {
      setActiveData(value);
    }
  };

  /**
   * @description         搜索
   * @param value         关键词
   */

  const search = (value: string) => {
    getDataSource(value);
  };

  useEffect(() => {
    getDataSource("");
  }, []);

  return (
    <div>
      <PtChildTitle title={"人员列表"} />
      {/* <PtSearch onChange={search} /> */}
      <GeneralTable
        columns={columns}
        dataSource={dataSource}
        activeData={activeData}
        lineClick={tableClick}
      />
    </div>
  );
};

export default PatrolLeft;
