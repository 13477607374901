import { useState, useEffect } from "react";
import { PtTableLine } from "../../../../../components";
import axios from "axios";

interface Props {
  dataSource: any;
  type: string;
}

const Information = ({ dataSource, type }: Props) => {
  const [data, setData] = useState({} as any);

  const getList = () => {
    if (typeof dataSource.id !== "string") {
      if (type !== "管井") {
        axios
          .get(`/device_info/query_info_by_id/${dataSource.id}`)
          .then((res: any) => {
            if (res.code === 200) {
              setData(res.data);
            }
          });
      } else {
        const newData = {
          areaDetails: "秀屿区苏塘村涵港大道",
          maintenanceUnit: "莆田市秀屿区上航水环境投资有限公司",
          principal: "潘幸",
          concatWay: "13850216827",
          constructionUnit: "莆田市秀屿区上航水环境投资有限公司",
          completionTime: "2020年",
        };
        setData(newData);
      }
    }
  };

  useEffect(() => {
    setData({});
    getList();
  }, [dataSource.id, type]);

  return (
    <div>
      <PtTableLine leftText={"地址详情"} rightText={data.areaDetails} />
      <PtTableLine
        leftText={"所属区域"}
        rightText={data.areaName ? data.areaName : ""}
      />
      {type !== "管井" && (
        <>
          <PtTableLine
            leftText={"设备编号"}
            rightText={data.code ? data.code : ""}
          />
          {type === "泵站" && (
            <>
              <PtTableLine
                leftText={"规格说明"}
                rightText={data.spec ? data.spec : ""}
              />
              <PtTableLine
                leftText={"运行模式"}
                rightText={data.runMode ? data.runMode : ""}
              />
            </>
          )}
          <PtTableLine
            leftText={"备注"}
            rightText={data.remark ? data.remark : ""}
          />
        </>
      )}
      {/* <PtTableLine leftText={"技术文档"} rightText={""} /> */}
      <PtTableLine
        leftText={"维养单位"}
        rightText={data.maintenanceUnit ? data.maintenanceUnit : ""}
      />
      <PtTableLine
        leftText={"责任人"}
        rightText={data.principal ? data.principal : ""}
      />
      <PtTableLine
        leftText={"联系方式"}
        rightText={data.concatWay ? data.concatWay : ""}
      />
      <PtTableLine
        leftText={"建设单位"}
        rightText={data.constructionUnit ? data.constructionUnit : ""}
      />
      <PtTableLine
        leftText={"竣工时间"}
        rightText={data.completionTime ? data.completionTime : ""}
      />
    </div>
  );
};

export default Information;
