import { combineReducers } from "redux-immutable";
import { reducer as header } from '../../store/head'
import { reducer as home } from '../../store/home'

const reducer = combineReducers({
    header,
    home
});

export default reducer;
