import * as constants from "./constants";

/**
 * @description 改变两侧抽屉显示隐藏
 */

export const setDrawerVisible = (data) => ({
  type: constants.setDrawerVisible,
  data,
});

/**
 * @description 改变assets皮肤下的ActiveData
 */

export const setAssetsSkinActiveData = (data) => ({
  type: constants.setAssetsSkinActiveData,
  data,
});

export const setMaskVisible = (data) => ({
  type: constants.setMaskVisible,
  data,
});

export const setSuTangList = (data) => ({
  type: constants.setSuTangList,
  data,
});

export const setFlowChartData = (data) => ({
  type: constants.setFlowChartData,
  data,
});

export const setMonitorShow = (data) => ({
  type: constants.setMonitorShow,
  data,
});
