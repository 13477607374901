/*
 * @Author: Elon-Ysuhan
 * @LastEditors: DESKTOP-K8H00JV
 * @description: 描述页面内容
 * @Date: 2022-05-11 09:52:24
 * @LastEditTime: 2022-10-15 11:40:59
 */
import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import { Provider } from "react-redux";
import store from "./utils/store";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import "moment/locale/zh-cn";
import "./utils/axios";
import "./index.less";
import "./index.scss";

// axios.get("/user/getToken").then((res) => {
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <Router />
    </ConfigProvider>
  </Provider>,
  document.getElementById("toolbar")
);
// const { data } = res;
// localStorage.setItem("access_token", data);
// axios.defaults.headers.common["Authorization"] = "bearer " + data;
// });
