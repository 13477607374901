import { Select, Tree } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { PtTab } from "../../../../components";
import { headActionCreators } from "../../../../store/head";
import CloseButton from "../general/colseButton";
import GeneralList from "../general/generalList";
// import GeneralCollapse from "../../../assetsSkin/components/general/generalCollapse";
import ChartContent from "./components/chartContent";

import "./index.scss";

const { TreeNode } = Tree;
/**
 * @description             统计分析
 */

const Statistical = () => {
  //两侧内容显示状态
  const drawerVisible = useSelector((value: any) =>
    value.getIn(["home", "drawerVisible"])
  );

  const suffixKey = useSelector((value: any) =>
    value.getIn(["header", "suffixKey"])
  );

  const headAreaName = useSelector((value: any) =>
    value.getIn(["header", "areaName"])
  );

  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(1);
  //场站
  const [station, setStation] = useState({
    count: 0,
    list: [],
  });
  const [stationChart, setStationChart] = useState({
    count: 0,
    offline: 0,
    online: 0,
    process: 0,
    chartData: [],
  });
  //泵站
  const [pumpStation, setPumpStation] = useState({
    count: 0,
    list: [],
  });

  const [pumpChart, setPumpChart] = useState({
    count: 0,
    offline: 0,
    online: 0,
    process: 0,
    chartData: [],
  });

  const [monitorData, setMonitorData] = useState({
    count: 0,
    list: [],
  });

  //事件列表
  const [eventData, setEventData] = useState([]);
  //巡检列表
  const [patrolData, setPatrolData] = useState([]);

  const [time1, setTime1] = useState(2);
  const [time2, setTime2] = useState(2);

  const [expandedKeys, setExpandedKeys] = useState([]);

  const treeSelect = (selectedKeys, e) => {
    const value = e.node.data;

    if (!value) {
      const newKeys = [...expandedKeys];
      const index = newKeys.indexOf(e.node.key);

      if (index !== -1) {
        newKeys.splice(index, 1);
      } else {
        newKeys.push(e.node.key);
      }
      setExpandedKeys(newKeys);
    }
  };

  const getData = async () => {
    const PumpProcess: any = await axios.get("/monitor_station_process/sum");

    //获取场站
    axios
      .get("/device/treeData", {
        params: {
          type: 7,
          state: 0,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          setStation(res.data);
        }
      });
    axios
      .get(`/device/getCount`, {
        params: {
          areaCode: "350305000000",
          type: 7,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          const count = res.data.offline + res.data.online;
          const newData = {
            ...res.data,
            count,
            process: 20000,
            chartData: [
              {
                percent: Number((res.data.offline / count).toFixed(2)),
                color: "#ED736F",
              },
              {
                percent: Number((res.data.online / count).toFixed(2)),
                color: "#5fcf88",
              },
            ],
          };
          setStationChart(newData);
        }
      });

    //获取泵站
    axios
      .get("/device/treeData", {
        params: {
          type: 6,
          state: 0,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          setPumpStation(res.data);
        }
      });

    axios
      .get(`/device/getCount`, {
        params: {
          areaCode: "350305000000",
          type: 6,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          const count = res.data.offline + res.data.online;
          const newData = {
            ...res.data,
            count,
            process: PumpProcess.code === 200 ? PumpProcess.data.toFixed(2) : 0,
            chartData: [
              {
                percent: Number((res.data.offline / count).toFixed(2)),
                color: "#ED736F",
              },
              {
                percent: Number((res.data.online / count).toFixed(2)),
                color: "#5fcf88",
              },
            ],
          };
          setPumpChart(newData);
        }
      });
    //获取监控列表
    axios.get("/monitor/query_all").then((res: any) => {
      if (res.code === 200) {
        const newData = {
          count: res.data.length,
          list: res.data,
        };
        setMonitorData(newData);
      }
    });

    getEvent();
    getPatrol();
  };

  const getEvent = () => {
    axios
      .get("/events/info/lsit_all", {
        params: {
          timeType: time1,
          areaCode: 350305000000,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          setEventData(res.data);
        }
      });
  };

  const getPatrol = () => {
    axios
      .get("/work_order/lsit_all", {
        params: {
          timeType: time2,
          areaCode: 350305000000,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          setPatrolData(res.data);
        }
      });
  };
  const renderTreeTitle = (item) => {
    return item.children ? (
      <span>{item.title}</span>
    ) : (
      <div className={`tree_title_box`}>
        <div className="item_l">{item.name}</div>
        <div className="item_r">
          <div
            className={`state_box ${item.state === 1 ? "success" : "warning"}`}
          >
            {item.state === 1 ? "正常" : "离线"}
          </div>
        </div>
      </div>
    );
  };

  const renderTreeNodes = (data, parent?) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={() => renderTreeTitle(item)} key={item.areaCode}>
            {renderTreeNodes(
              item.level !== 2 ? item.children : item.list,
              item
            )}
          </TreeNode>
        );
      }
      if (parent) {
        return (
          <TreeNode
            title={() => renderTreeTitle(item)}
            key={item.id}
            data-parentKey={parent.areaCode}
            data={item}
            isLeaf={true}
          />
        );
      }
    });
  };

  useEffect(() => {
    getEvent();
  }, [time1]);

  useEffect(() => {
    getPatrol();
  }, [time2]);

  useEffect(() => {
    getData();
  }, []);

  const EquipmentList = {
    1: pumpStation.list,
    2: station.list,
    3: monitorData.list,
  };

  const PatrolStatus = {
    0: "待处理",
    1: "巡检中",
    2: "已完成",
  };

  return (
    <>
      {drawerVisible && (
        <div className="statistical_box">
          <div className="statistical_head">
            <div className="head_left">
              统计分析 - 秀屿区
              {/* {suffixKey === 0 ? "秀屿区" : headAreaName} */}
            </div>
            <div className="head_right">
              <CloseButton
                onClick={() => {
                  dispatch(headActionCreators.setModuleKey("0"));
                }}
              />
            </div>
          </div>
          <div className="charts_box">
            <ChartContent
              dataSource={{
                chartData: stationChart.chartData,
                chartId: 1,
                totalData: {
                  count: stationChart.count,
                  title: "场站总数",
                },
                detailsData: {
                  total: stationChart.online,
                  processing: stationChart.process,
                  processingTitle: "设备总处理量",
                  processingUnit: "m³",
                },
              }}
            />
            <ChartContent
              dataSource={{
                chartData: pumpChart.chartData,
                chartId: 2,
                totalData: {
                  count: pumpChart.count,
                  title: "泵站总数",
                },
                detailsData: {
                  total: pumpChart.online,
                  processing: pumpChart.process,
                  processingTitle: "设备总收集量",
                  processingUnit: "m³",
                },
              }}
            />
            <ChartContent
              dataSource={{
                chartData: [
                  {
                    percent: 0,
                    color: "#ED736F",
                  },
                  {
                    percent: 1,
                    color: "#5fcf88",
                  },
                ],
                chartId: 3,
                totalData: {
                  count: monitorData.count,
                  title: "监控总数",
                },
                detailsData: {
                  total: monitorData.count,
                  processing: 100,
                  processingTitle: "设备在线率",
                  processingUnit: "%",
                },
              }}
            />
          </div>
          <div className="list_box">
            <div className="list_box_item">
              <div className="item_head">
                <div className="head_l">设备列表</div>
                <div className="head_r">
                  <PtTab
                    data={[
                      {
                        title: "泵站",
                        value: 1,
                      },
                      {
                        title: "场站",
                        value: 2,
                      },
                      {
                        title: "监控",
                        value: 3,
                      },
                    ]}
                    value={tabValue}
                    onChange={setTabValue}
                  />
                </div>
              </div>
              <div className="item_body customScrollBars">
                {tabValue === 3 ? (
                  EquipmentList[tabValue].map((item, i) => {
                    return (
                      <GeneralList key={item.id}>
                        <div>{item.name}</div>
                        <div className={`state_box ${"success"}`}>正常</div>
                      </GeneralList>
                    );
                  })
                ) : (
                  <Tree
                    blockNode
                    onSelect={treeSelect}
                    onExpand={treeSelect}
                    expandedKeys={expandedKeys}
                  >
                    {renderTreeNodes(EquipmentList[tabValue])}
                  </Tree>
                )}
              </div>
            </div>
            <div className="list_box_item">
              <div className="item_head">
                <div className="head_l">事件处理列表</div>
                <div className="head_r">
                  <div className="select_box">
                    <Select
                      size="small"
                      bordered={false}
                      getPopupContainer={(triggerNode) => triggerNode}
                      options={[
                        {
                          label: "近一周",
                          value: 2,
                        },
                        {
                          label: "近一月",
                          value: 3,
                        },
                        {
                          label: "近一年",
                          value: 4,
                        },
                      ]}
                      value={time1}
                      onChange={(value) => setTime1(value)}
                    />
                  </div>
                </div>
              </div>
              <div className="item_body customScrollBars">
                {eventData.map((item) => {
                  return (
                    <GeneralList key={item.id}>
                      <div>{item.title}</div>
                      <div>
                        <div
                          className={`state_box ${
                            item.status === 2 || item.status === 4
                              ? "success"
                              : "warning"
                          }`}
                        >
                          {item.status === 2 || item.status === 4
                            ? "已处理"
                            : "待处理"}
                        </div>
                      </div>
                    </GeneralList>
                  );
                })}
              </div>
            </div>
            <div className="list_box_item">
              <div className="item_head">
                <div className="head_l">巡检情况</div>
                <div className="head_r">
                  <div className="select_box">
                    <Select
                      size="small"
                      bordered={false}
                      getPopupContainer={(triggerNode) => triggerNode}
                      options={[
                        {
                          label: "近一周",
                          value: 2,
                        },
                        {
                          label: "近一月",
                          value: 3,
                        },
                        {
                          label: "近一年",
                          value: 4,
                        },
                      ]}
                      value={time2}
                      onChange={(value) => setTime2(value)}
                    />
                  </div>
                </div>
              </div>
              <div className="item_body inspection_body customScrollBars">
                {patrolData.map((item) => {
                  return (
                    <GeneralList key={item.id}>
                      <div className="title">{item.type} - {item.deviceName}</div>
                      <div className="time">{item.patrolStartTime}</div>
                      {/* <div
                        className={`time`}
                      >
                        {PatrolStatus[item.status]}
                        {item.deviceName}
                      </div> */}
                      <div
                        className={`state_box ${
                          item.status === 2 ? "success" : "warning"
                        }`}
                      >
                        {PatrolStatus[item.status]}
                      </div>
                    </GeneralList>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Statistical;
