import GeneralModal from "../../moduleSkin/components/general/generalModal";

import ModalImage from "../../../assets/img/modal_image.jpg";

interface Props {
  onClose: () => void;
}

const MainModal = ({ onClose }: Props) => {
  return (
    <GeneralModal
      title="莆田市秀屿区农村生活污水治理及笏石溪河道综合整治PPP项目"
      className={"main_skin_modal"}
      onClose={onClose}
      downloadVisible={false}
    >
      <div className="project_profile">
        <img src={ModalImage} alt="" />
        <div>
          本项目主要分别为“莆田市秀屿区农村生活污水收集与处理工程”（子项一）、“莆田市秀屿区笏石溪河道综合整治工程”（子项二），项目总投资14.8亿，合作期
          20年。
        </div>
        <div>
          “子项一”工程范围包括笏石镇、埭头镇、平海镇、东峤镇、南日镇、东庄镇和月塘镇等7个镇，共计147个行政村（社区），涉及66万人。
        </div>
        <div>
          主要建设内容由污水收集工程、污水处理工程、污水提升工程三部分组成。
        </div>
        <div>
          ①污水收集工程：污水收集系统主要包括各行政村内主、支管道、检查井、村内接户管铺设以及村内公共厕所等。建设污水管网（主、支管）646.32km、接户管937.60km、负压收集系统3套、公共厕所93座。
        </div>
        <div>
          ②污水提升工程：本工程新建一体化污水泵井282座，将地势较低处的农村生活污水提升至秀屿污水处理厂或本次建设一体化污水处理站进行处理。
        </div>
        <div>
          ③污水处理工程：污水处理系统包括污水处理一体化设施以及生态处理的全部构建筑物及配套工程。新建一体化污水处理场站45座。
        </div>
        <div>
          “子项二”起点为笏石溪上游顶溪村山塘，工程终点为下游清塘大道，主要建设内容包括护岸工程、水环境治理工程、景观绿化工程及其他工程四个部分。
        </div>
      </div>
    </GeneralModal>
  );
};

export default MainModal;
