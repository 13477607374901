interface Props {
  dataSource: any;
  type: number;
}

/**
 * @description               人员信息
 * @param dataSource          人员信息
 * @param type                类型(1:专家,2:应急人员)
 */

const GeneralPersonnel = ({ dataSource, type }: Props) => {
  const information1 = [
    {
      title: "专家名称：",
      value: dataSource.name,
    },
    {
      title: "专业方向：",
      value: dataSource.major,
    },
    {
      title: "联系电话：",
      value: dataSource.phone,
    },
  ];

  const information2 = [
    {
      title: "人员名称：",
      value: dataSource.name,
    },
    {
      title: "联系电话：",
      value: dataSource.phone,
    },
  ];

  const information = {
    1: information1,
    2: information2,
  };

  return (
    <div className="general_personnel">
      <div className="personnel_top">
        <img src={dataSource.headImg} alt="" />
        <div>
          {information[type].map((item, i) => (
            <div key={i} className={"information_text"}>
              <span>{item.title}</span>
              {item.value}
            </div>
          ))}
        </div>
      </div>
      {type === 1 && (
        <div className="personnel_bottom customScrollBars">
          {dataSource.introduction}
        </div>
      )}
    </div>
  );
};

export default GeneralPersonnel;
