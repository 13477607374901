import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle6 from "../../general/excelStyle6";
import { NetworkExcel2Data } from "../data";

interface Props {}

/**
 * @description                     管网维修记录
 */

const NetworkExcel2 = ({}: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "维护区域",
              value: "笏石镇苏塘村",
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "维护人员",
              value: "刘昌武",
            },
            {
              title: "维护日期",
              value: "2023.2.16",
            },
          ]}
        />
      </div>

      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "维护项目", span: 3 },
            { title: "设备名称", span: 3 },
            { title: "维护内容", span: 5 },
            { title: "维护情况", span: 5 },
            { title: "处理结果", span: 5 },
            { title: "备注", span: 3 },
          ]}
        />
        <ExcelStyle6
          dataSource={NetworkExcel2Data}
          columns={[
            { title: "维护情况", span: "189px", dataIndex: "state" },
            { title: "处理结果", span: "190px", dataIndex: "results" },
            { title: "备注", span: "114px", dataIndex: "remark" },
          ]}
        />
      </div>
    </>
  );
};

export default NetworkExcel2;
