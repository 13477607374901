import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle4 from "../../general/excelStyle4";

interface Props {}

/**
 * @description         河道违法
 */

const RiverExcel6 = ({}: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "事件来源",
              value: "***",
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "事发地点",
              value: "笏石溪",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "姓名",
              value: "刘昌武",
            },
            {
              title: "性别",
              value: "男",
            },
            {
              title: "电话",
              value: "1304758870",
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "联系方式",
              value: "***",
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "单位(地址)",
              value: "***",
            },
          ]}
        />
      </div>

      <div className="excel_content">
        <ExcelStyle4
          dataSource={[
            {
              title: "主要内容",
              value: "暂无",
              type: 1,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "记录人",
              value: "",
            },
            {
              title: "记录时间",
              value: "",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle4
          dataSource={[
            {
              title: "处理意见",
              value: "暂无",
              type: 1,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "负责人",
              value: "",
            },
            {
              title: "记录时间",
              value: "",
            },
          ]}
        />
      </div>
    </>
  );
};

export default RiverExcel6;
