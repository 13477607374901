import { Col, Row } from "antd";

interface Props {
  dataSource: any[];
  columns: any[];
}

const ExcelStyle6 = ({ dataSource, columns }: Props) => {
  return (
    <>
      {dataSource.map((res) => {
        const { title, list } = res;
        return (
          <Row key={title}>
            <Col span={3}>
              <div className="excel_text3">{title}</div>
            </Col>
            <Col span={21}>
              {list.map((item, i) => {
                const { name, children } = item;
                return (
                  <Row key={i}>
                    <Col>
                      <div className="excel_text3" style={{ width: "114px" }}>
                        {name}
                      </div>
                    </Col>
                    <Col>
                      {children.map((it, index) => {
                        return (
                          <Row key={index}>
                            <Col>
                              <div
                                className="excel_text3"
                                style={{ width: "190px" }}
                              >
                                {it.content}
                              </div>
                            </Col>

                            {columns.map((value) => {
                              return (
                                <Col key={value.dataIndex}>
                                  <div
                                    className="excel_text4"
                                    style={{ width: value.span }}
                                  >
                                    {it[value.dataIndex]}
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default ExcelStyle6;
