import axios from "axios";
import { useEffect, useState } from "react";
import { PtChildTitle, PtSearch } from "../../../../../components";
import GeneralTable from "../../general/generalTable";

interface Props {
  activeData: any;
  setActiveData: (value) => void;
}

const Emergency2Left = ({ activeData, setActiveData }: Props) => {
  const [dataSource, setDataSource] = useState([]);

  /**
   * @description         应急人员表格 每列内容
   */

  const Emergency2Columns = [
    {
      title: "姓名",
      dataIndex: "name",
    },
    {
      title: "联系方式",
      dataIndex: "phone",
      width: 194,
    },
    {
      title: "状态",
      dataIndex: "state",
      width: 60,
      render: (data) => (
        <div className={`state_box`}>{data ? "在岗" : "休假"}</div>
      ),
    },
  ];

  useEffect(() => {
    getDataSource("");
  }, []);

  /**
   * @description         获取列表数据
   * @param name          关键词
   */

  const getDataSource = (name: string) => {
    axios.get(`/user/get_emer?name=${name}`).then((res: any) => {
      if (res.code === 200) {
        setDataSource(res.data);
        setActiveData({});
      }
    });
  };

  /**
   * @description         搜索
   * @param value         关键词
   */

  const search = (value: string) => {
    getDataSource(value);
  };

  return (
    <div>
      <PtChildTitle title={"人员列表"} />
      {/* <PtSearch onChange={search} /> */}
      <GeneralTable
        columns={Emergency2Columns}
        dataSource={dataSource}
        activeData={activeData}
        lineClick={(value) => {
          setActiveData(value.id === activeData.id ? {} : value);
        }}
      />
    </div>
  );
};

export default Emergency2Left;
