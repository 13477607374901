import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { stationsData } from "../../../data";
import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle4 from "../../general/excelStyle4";
import ExcelStyle5 from "../../general/excelStyle5";
import axios from "axios";

interface Props {
  leftData: any;
  rightData: any;
}

/**
 * @description         场站巡检
 */

const StationsExcel1 = ({ leftData, rightData }: Props) => {
  const [data, setData] = useState({} as any);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);

  useEffect(() => {
    if (rightData.sysId) {
      axios
        .get(`/work_order/forward_app_web/worker_order/worker_order/detail`, {
          params: { sysid: rightData.sysId },
        })
        .then((res: any) => {
          if (res.code === 200) {
            let newData = [];
            const newData1 = [];
            const newData2 = [];
            const newData3 = [];
            const newData4 = [];

            setData(res.data.data);
            res.data.data.patrol_format_info_v3.forEach((element) => {
              newData = [...newData, ...element.list];
            });
            newData.forEach((item) => {
              switch (item.report_type) {
                case 1:
                  newData1.push(item);
                  break;
                case 2:
                  newData2.push(item);
                  break;
                case 3:
                  newData3.push(item);
                  break;
              }
            });
            setData1(newData1);
            setData2(newData2);
            setData3(newData3);
            setData4(newData4);
          }
        });
    } else {
      setData1(stationsData[2].data1);
      setData2(stationsData[2].data2);
      setData3(stationsData[2].data3);
      setData4(stationsData[2].image);
    }
  }, [rightData.sysId, rightData.id]);

  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "巡查人员",
              value: "",
            },
            {
              title: "巡查日期",
              value: data.createtime,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "人员数量",
              value: "",
            },
          ]}
        />
      </div>

      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "序号", span: 2 },
            { title: "巡查项目", span: 8 },
            { title: "巡查情况", span: 7 },
            { title: "处理情况", span: 4 },
            { title: "备注", span: 3 },
          ]}
        />
        <ExcelStyle5
          columns={[
            {
              title: "序号",
              span: 2,
              dataIndex: "index",
              render: (_, index) => <div>{index}</div>,
            },
            { title: "巡查项目", span: 8, dataIndex: "patrol_name" },
            { title: "巡查情况", span: 7, dataIndex: "detail" },
            { title: "处理情况", span: 4, dataIndex: "status_txt" },
            { title: "备注", span: 3, dataIndex: "remark" },
          ]}
          dataSource={data1}
        />
        <ExcelStyle5
          columns={[
            {
              title: "序号",
              span: 2,
              dataIndex: "index",
              render: (_, index) => <div>{index + data1.length}</div>,
            },
            { title: "巡查项目", span: 8, dataIndex: "patrol_name" },
            {
              title: "巡查情况",
              span: 11,
              dataIndex: "detail",
              // style: {
              //   flexDirection: "column",
              //   alignItems: "flex-start",
              // },
              // render: (value) => {
              //   return value
              //     .split(",")
              //     .map((item, i) => <div key={i}>{item}</div>);
              // },
            },
            { title: "备注", span: 3, dataIndex: "remark" },
          ]}
          dataSource={data2}
        />
        <ExcelStyle5
          columns={[
            {
              title: "序号",
              span: 2,
              dataIndex: "index",
              render: (_, index) => (
                <div>{index + data1.length + data2.length}</div>
              ),
            },
            { title: "巡查项目", span: 8, dataIndex: "patrol_name" },
            {
              title: "今日完成事项",
              span: 7,
              dataIndex: "today",
              style: {
                display: "block",
                padding: 0,
              },
              render: (value) => {
                return (
                  <>
                    <Row>
                      <Col span={24}>
                        <div
                          className="excel_text4"
                          style={{ borderRight: "none" }}
                        >
                          今日完成事项
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div
                          className="excel_text4"
                          style={{
                            border: "none",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          {/* {value.split(",").map((item, i) => (
                            <div key={i}>{item}</div>
                          ))} */}
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              },
            },
            {
              title: "后续计划",
              span: 7,
              dataIndex: "follow",
              style: {
                display: "block",
                padding: 0,
              },
              render: (value) => {
                return (
                  <>
                    <Row>
                      <Col span={24}>
                        <div
                          className="excel_text4"
                          style={{ borderRight: "none" }}
                        >
                          后续计划
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div
                          className="excel_text4"
                          style={{
                            border: "none",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          {/* {value.split(",").map((item, i) => (
                            <div key={i}>{item}</div>
                          ))} */}
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              },
            },
          ]}
          dataSource={data3}
        />
      </div>
      {data4.length > 0 && (
        <div className="excel_content">
          <ExcelStyle4 dataSource={data4} />
        </div>
      )}
    </>
  );
};

export default StationsExcel1;
