import axios from "axios";
import { useEffect, useState } from "react";
import { PtIcon } from "../../../../../components";

interface Props {
  activeId: number;
  dataSource: any[];
  onClick: (value) => void;
}

const PipelineTable = ({ activeId, dataSource, onClick }: Props) => {
  return (
    <div className="pipeline_table">
      {dataSource.map((item, i) => {
        return (
          <TableLine
            key={i}
            activeId={activeId}
            dataSource={item}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};

export default PipelineTable;

const TableLine = ({ activeId, dataSource, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState({
    id: 0,
    title: "",
    number: 0,
    children: [],
  });
  useEffect(() => {
    setIsOpen(false);
    getChildren();
  }, []);

  const getChildren = () => {
    axios.get(`/pipeline/query_all/${dataSource.id}`).then((res: any) => {
      if (res.code === 200) {
        const newData = { ...dataSource };
        newData.children = res.data;
        setData(newData);
      }
    });
  };

  return (
    <div className="table_line">
      <div className="table_line_title" onClick={() => setIsOpen(!isOpen)}>
        <div className="title_l">
          {dataSource.title} <span>({dataSource.number})</span>
        </div>
        <PtIcon
          className={`${isOpen ? "icon-shousuoicon" : "icon-zhankaiicon"}`}
        />
      </div>
      {isOpen && (
        <div className="line_child_list">
          {data.children.map((item, i) => (
            <div
              key={i}
              className={`child_content ${item.id === activeId && "active"}`}
              onClick={() => {
                onClick(item);
                window["CesMaps"].flyTo([
                  Number(item.geoLine[0]),
                  Number(item.geoLine[1]),
                  500,
                ]);
              }}
            >
              <div className="child_title">{item.title}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
