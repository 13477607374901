import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { PtIcon } from "../simple";
import { homeActionCreators } from "../../store/home";
import axios from "axios";
import ModelVideo from "./components/modelVideo";

const MonitorModel = () => {
  const dispatch = useDispatch();
  const monitorShow = useSelector((value: any) =>
    value.getIn(["home", "monitorShow"])
  );
  const [monitorList, setMonitorList] = useState([]);
  const [activeData, setActiveData] = useState({} as any);

  const [monitorState, setMonitorState] = useState("");

  const [videoData, setVideoData] = useState(null);

  const onClose = () => {
    if (window["EZOPENDemo"]) {
      window["EZOPENDemo"].stop();
    }
    dispatch(
      homeActionCreators.setMonitorShow({
        visible: false,
        activeId: null,
      })
    );
  };

  const getMonitorList = () => {
    axios
      .get("/monitor/get_list_by_device_id", {
        params: { deviceId: monitorShow.activeId },
      })
      .then((res: any) => {
        if (res.code === 200) setMonitorList(res.data);
      });
  };

  useEffect(() => {
    if (activeData.id) {
      setMonitorState("");
      setVideoData(null);
      if (window["EZOPENDemo"]) window["EZOPENDemo"].stop();
      axios
        .get(`/monitor/get_ys7_video_url/${activeData.id}`)
        .then((res: any) => {
          if (res.code === 200) {
            setVideoData(res.data);
          } else {
            setMonitorState(res.msg);
          }
        });
    }
  }, [activeData.id]);

  useEffect(() => {
    getMonitorList();
  }, []);

  return (
    <div className="monitor_model">
      <div className="model_head">
        <div className="head_title">监控列表</div>
        <div className="close_button" onClick={onClose}>
          <PtIcon className="icon-guanbi" />
        </div>
      </div>
      <div className="model_body">
        <div className="body_l">
          <div className="left_body">
            {monitorList.map((item) => (
              <div
                key={item.id}
                className={`left_body_child ${
                  item.id === activeData.id && "active_child"
                }`}
                onClick={() => setActiveData(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div className="body_r">
          {videoData && <ModelVideo dataSource={videoData} />}
          {monitorState && <span>{monitorState}</span>}
        </div>
      </div>
    </div>
  );
};

export default MonitorModel;
