/*
 * @Description: 
 * @Date: 2024-05-25 22:13:19
 * @LastEditors: Chen
 * @LastEditTime: 2024-07-17 14:44:48
 * @FilePath: \smurfs-react\src\components\flowChart\index.tsx
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GeneralModal from "../../skin/moduleSkin/components/general/generalModal";
import { homeActionCreators } from "../../store/home";
import "./index.scss";
import ThreeModel from "../threeModel";

interface Props {
  data: any;
}

const FlowChart = ({ data }: Props) => {
  const dispatch = useDispatch();
  const [inCOD, setIn] = useState("");
  const [outCOD, setOut] = useState("");
  const [webs, setWs] = useState<WebSocket>()

  useEffect(() => {
    const webSocketUrl = `ws://47.102.153.51:8091/api/msg/${data.code}`;
    const ws = new WebSocket(webSocketUrl);
    setWs(ws);
    ws.onopen = (e) => {
      console.log("连接成功2");
    };

    ws.onmessage = (e) => {
      const newData = JSON.parse(e.data);
      newData.forEach((element) => {
        if (element.devName === "出水COD")
          setOut(element.devValue + element.devUnit);
        if (element.devName === "进水COD")
          setIn(element.devValue + element.devUnit);
      });
    };
  }, [])

  const onClose = () => {
    webs.close();
    dispatch(
      homeActionCreators.setFlowChartData({
        visible: false,
        activeData: {},
      })
    );
  };

  return (
    <GeneralModal
      title="工作流程图"
      className={"flow_chart_modal"}
      onClose={onClose}
      downloadVisible={false}
    >
      <div className="flow_chart_box">
        <div style={
          {
            position: "absolute",
            width: '100px',
            height: '20px',
            right: '0',
            top: '30%',
            color: '#fff'
          }
        }>
          {inCOD}
        </div>
        <div style={
          {
            position: "absolute",
            width: '100px',
            height: '20px',
            left: '40px',
            top: '45%',
            color: '#fff'
          }
        }>
          {outCOD}
        </div>
        <img src={require("../../assets/img/flowChart.png").default} alt="" />
      </div>
    </GeneralModal>
  );
};

export default FlowChart;
