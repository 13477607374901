import { useEffect } from "react";

interface Props {
  dataSource: any;
}

const ModelVideo = ({ dataSource }: Props) => {
  const init = () => {
    let EZOPENDemo = new window["EZUIKit"].EZUIKitPlayer({
      id: "playWind",
      width: 766,
      height: 450,
      template: "pcLive",
      url: dataSource.url,
      accessToken: dataSource.accessToken,
    });
    window["EZOPENDemo"] = EZOPENDemo;
  };

  useEffect(() => {
    init();
  }, [dataSource]);

  return (
    <div>
      <div id="playWind"></div>
    </div>
  );
};

export default ModelVideo;
