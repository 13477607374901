import { useState } from "react";
import { PtIcon } from "../../../../components";

interface Props {
  title: string;
  children: any;
  className?: string;
  downloadVisible?: boolean;
  onDownload?: () => void;
  onClose: () => void;
}

/**
 * @description                 文件展示弹窗
 * @param title                 标题
 * @param children              内容
 * @param downloadVisible       下载按钮显示
 * @param onDownload            下载事件
 * @param children              关闭事件
 */

const GeneralModal = ({
  title,
  children,
  downloadVisible = true,
  className,
  onDownload = () => {},
  onClose,
}: Props) => {
  const [isShrink, setIsShrink] = useState(false);

  return (
    <div
      className={`general_modal ${className} ${
        isShrink ? "is_shrink_modal" : ""
      }`}
      onClick={() => {
        if (isShrink) setIsShrink(false);
      }}
    >
      <div className="modal_head">
        {title}
        <div className="close_button" onClick={onClose}>
          <PtIcon className="icon-guanbi" />
        </div>
      </div>
      <div className="modal_body">{children}</div>
      <div className="modal_footer">
        <div></div>
        <div className="footer_right">
          {downloadVisible && (
            <div className="footer_button download" onClick={onDownload}>
              下载
            </div>
          )}
          <div className="footer_button" onClick={() => setIsShrink(true)}>
            缩小
          </div>
          <div className="footer_button" onClick={onClose}>
            关闭
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralModal;
