import { useSelector } from "react-redux";
import Emergency1 from "./components/emergency1";
import Emergency2 from "./components/emergency2";
import Emergency3 from "./components/emergency3";
import ExpertsBody from "./components/experts";
import Operations from "./components/operations";
import Statistical from "./components/statistical";

import "./index.scss";
import Patrol from "./components/patrol";

const ModuleSkin = () => {
  //模块类型
  const moduleKey = useSelector((value: any) =>
    value.getIn(["header", "moduleKey"])
  );

  /**
   * @description               左侧内容
   */

  const leftContent = {
    "1-1": <Emergency1 />,
    "1-2": <Emergency2 />,
    "1-3": <Emergency3 />,
    "2": <Statistical />,
    "3-1": <Patrol />,
    "3-2": <Operations />,
    "4": <ExpertsBody />,
  };

  return <>{leftContent[moduleKey]}</>;
};

export default ModuleSkin;
