interface Props {
  className?: string;
  children: any;
  onClick?: () => void;
}

const GeneralList = ({
  className = "",
  children,
  onClick = () => {},
}: Props) => {
  return (
    <div className={`general_list ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default GeneralList;
