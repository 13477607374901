import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle4 from "../../general/excelStyle4";
import ExcelStyle5 from "../../general/excelStyle5";
import { RiverExcel4Data } from "../data";

interface Props {
  dataSource: any;
}

/**
 * @description         河道防汛
 */

const RiverExcel4 = ({ dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "站点名称",
              value: dataSource.title,
            },
            {
              title: "巡查日期",
              value: dataSource.time,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "处理工艺",
              value: "***",
            },
            {
              title: "巡检人员",
              value: "闫固咏",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "序号", span: 2 },
            { title: "巡查项目", span: 10 },
            { title: "巡查情况", span: 12 },
          ]}
        />
        <ExcelStyle5
          columns={[
            { title: "序号", span: 2, dataIndex: "index" },
            { title: "巡查项", span: 10, dataIndex: "project" },
            { title: "巡查情况", span: 12, dataIndex: "patrol" },
          ]}
          dataSource={RiverExcel4Data}
        />
        <ExcelStyle4
          dataSource={[
            {
              title: "总体评价及存在问题描述",
              value: "暂无",
              type: 1,
            },
          ]}
        />
      </div>
    </>
  );
};

export default RiverExcel4;
