import { useState, useEffect } from "react";
import { DatePicker } from "antd";
import { DistanceDisplayCondition } from "cesium";
import axios from "axios";
const { RangePicker } = DatePicker;

const newAxios = axios.create({
  baseURL: "https://app.cthd.cc/public",
});

interface Props {
  activeData: any;
}

const PatrolRight = ({ activeData }: Props) => {
  const [entityId, setEntityId] = useState(null);

  const [date, setDate] = useState([]);

  const getLocus = () => {
    const { entities, viewer } = window["CesMaps"];
    newAxios
      .get("/query/locus", {
        params:
          date.length > 0
            ? {
              name: activeData.account,
              starttime: date[0],
              endtime: date[1],
            }
            : {
              name: activeData.account,
            },
      })
      .then((res) => {
        if (res.data.success) {
          let newList = [];
          if (res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              newList.push(item.x);
              newList.push(item.y);
            });
            window["CesMaps"].flyTo([
              res.data.data[0].x,
              res.data.data[0].y,
              500,
            ]);
            let entitie_ = new entities(viewer);
            entitie_.options({
              name: activeData.name + "guiji",
              id: activeData.id + "guiji",
              data: activeData,
              type: "moren",
              polyline: {
                flow: 0,
                positions: newList,
                speed: 1000,
                flowColor: "#bbbbbb99",
                staticColor: "#000",
                lineWidth: 2,
                distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
              },
            });
            entitie_.add();

            setEntityId(viewer.entities.getById(activeData.id + "guiji"));
          }
        }
      });
  };

  useEffect(() => {
    getLocus();

    return () => {
      const { viewer } = window["CesMaps"];
      viewer.entities.remove(entityId);
    };
  }, [activeData.id, date]);

  return (
    <div>
      <div className="range_picker_box">
        <RangePicker
          bordered={false}
          size="small"
          onChange={(dates, dateStrings) => {
            setDate(dates ? dateStrings : []);
          }}
        />
      </div>
    </div>
  );
};

export default PatrolRight;
