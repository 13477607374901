import { useEffect, useState } from "react";
import { PtImgCarousel } from "../../../../../components/simple";
import GeneralList from "../../general/generalList";
import Image from "../../../../../assets/img/test.png";

interface Props {
  activeData: any;
  setActiveImage: (url: string) => void;
}

const Emergency3Right = ({ activeData, setActiveImage }: Props) => {
  const [dataSource, setDataSource] = useState({} as any);

  useEffect(() => {
    getDataSource();
  }, []);

  useEffect(() => {
    getDataSource();
  }, [activeData.id]);

  const getDataSource = () => {
    setDataSource({
      describe: activeData.describe,
      imageUrl:
        activeData.id === 1
          ? [
              {
                url: Image,
              },
            ]
          : [],
      imageNumber: activeData.id === 1 ? 1 : 0,
      time: activeData.time,
      contactPerson: "宋瑶茗",
      phone: "15725025834",
      state: activeData.state,
    });
  };

  return (
    <div className="emergency3_right">
      <GeneralList>
        <span>描述信息</span>
        <span>{dataSource.describe}</span>
      </GeneralList>
      <GeneralList>
        <div className="image_box">
          <div className="image_box_head">
            <span>现场照片</span>
            <span>{dataSource.imageNumber}</span>
          </div>
          {dataSource.imageUrl && (
            <PtImgCarousel
              data={dataSource.imageUrl}
              onClick={(url) => setActiveImage(url)}
            />
          )}
        </div>
      </GeneralList>
      <GeneralList>
        <span>发生时间</span>
        <span>{dataSource.time}</span>
      </GeneralList>
      <GeneralList>
        <span>应急人员</span>
        <span>{dataSource.contactPerson}</span>
      </GeneralList>
      <GeneralList>
        <span>联系方式</span>
        <span>{dataSource.phone}</span>
      </GeneralList>
      <GeneralList>
        <span>当前状态</span>
        <span>{dataSource.state ? "已处理" : "待处理"}</span>
      </GeneralList>
    </div>
  );
};

export default Emergency3Right;
