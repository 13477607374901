import React, { Component } from "react";
import { PtDrawer, PtCard } from "../../components";
import { connect } from "react-redux";
import {
  PtVillageWaterPurification,
  PtRealTimeEvent,
  PtOperationStatus,
  PtMinorOperatingIndex,
  PtCondition,
} from "../components/components";
import {
  WaterData,
  progressbarData,
  wsVillageList,
  circleData,
  progressbarData2,
  minorOperatingIndexData,
} from "../../utils/strConfig";
import "./index.scss";
import { MinorSkinList } from "../data";
import axios from "axios";

interface Props {
  headSuffixKey: number;
}

interface State {
  operaStatusList: any[];
  waterData: any[];
}

class MinorSkin extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      operaStatusList: [],
      waterData: [],
    };
  }
  componentDidMount() {
    this.getWaterData();
    const operaStatusList = wsVillageList.map((it) => {
      return { title: it, ...circleData };
    });
    this.setState({
      operaStatusList,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.headSuffixKey !== this.props.headSuffixKey) {
      this.getWaterData();
    }
  }

  /**
   * @description               获取污净水数据
   */

  getWaterData = () => {
    const { headSuffixKey } = this.props;
    axios
      .get(`/device/get_num_by_area_code/${MinorSkinList[headSuffixKey].code}`)
      .then((res: any) => {
        if (res.code === 200) {
          this.setState({
            waterData: res.data,
          });
        }
      });
  };

  render() {
    const { headSuffixKey } = this.props;
    const { operaStatusList, waterData } = this.state;
    return (
      <PtDrawer
        visible={true}
        left={
          <>
            <PtVillageWaterPurification
              title={`${MinorSkinList[headSuffixKey].title}污净水`}
              enTitle={"Village sewage purification"}
              staticTypeNumber={4}
              data={[...WaterData, ...waterData]}
              percentList={
                waterData.length > 0 ? waterData.map((it) => it.rate * 100) : []
              }
            />
            <PtMinorOperatingIndex data={minorOperatingIndexData} />
            <PtCondition timelyrate={97} operaStatusList={operaStatusList} />
          </>
        }
        right={
          <>
            <PtRealTimeEvent scrollY={185} />
            <PtOperationStatus
              timelyrate={97}
              progressbarData={progressbarData2}
            />
          </>
        }
      />
    );
  }
}

const mapState = (state) => ({
  headSuffixKey: state.getIn(["header", "suffixKey"]),
});

export default connect(mapState)(MinorSkin);
