import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Divider } from "antd";
import { PtIcon } from "../simple";
import moment from "moment";
import { homeActionCreators } from "../../store/home";
import { headActionCreators } from "../../store/head";
import PtAMap from "../../utils/ptMap";
import "./index.scss";

const isFullscreen = () => window.screen.height == document.body.clientHeight;

const setFullscreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
};

const handleKeyF11 = (e) => {
  // console.log(e.keyCode);
  if (e.keyCode == 122 /* f11 */) {
    e.preventDefault();
    setFullscreen();
  }
};

interface Props {
  headSuffixKey: number;
  drawerVisible: boolean;
  homeMaskVisible: boolean;
  changeVisible: (value) => void;
  changeHomeMaskVisible: (value) => void;
}

interface State {
  full: boolean;
}
class SideBar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      full: false,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", handleKeyF11);
    window.addEventListener(
      "fullscreenchange",
      (e) => {
        this.setState({
          full: isFullscreen(),
        });
      },
      false
    );
  }

  render() {
    const { full } = this.state;
    const {
      headSuffixKey,
      drawerVisible,
      changeVisible,
      homeMaskVisible,
      changeHomeMaskVisible,
    } = this.props;
    const arr = ["日", "一", "二", "三", "四", "五", "六"];
    const name = {
      0: "秀屿区",
      1: "木兰溪",
      2: "笏石镇",
      3: "东庄镇",
      4: "东峤镇",
      5: "埭头镇",
      6: "平海镇",
      7: "南日镇",
      8: "月塘镇",
    };
    const button = [
      {
        iconName: "icon-gudingshijiao",
        newName: "",
        children: [],
        onClick: () => {},
      },
      {
        iconName: "icon-a-2D",
        newName: "",
        children: [],
        onClick: () => {},
      },
      {
        iconName: "icon-earth-line",
        newName: "",
        children: [],
        onClick: () => {
          // PtAMap.showOrHideLayer()
          // changeHomeMaskVisible(!homeMaskVisible)
        },
      },
      {
        iconName: "icon-baihei",
        newName: "",
        children: [],
        onClick: () => {},
      },
      {
        iconName: "icon-shoucang2",
        newName: "",
        children: [],
        onClick: () => {},
      },
      {
        iconName: "icon-gongju",
        newName: "",
        children: [],
        onClick: () => {},
      },
      {
        iconName: "icon-shujumianban",
        newName: drawerVisible ? "active" : "",
        children: [],
        onClick: () => {
          changeVisible(!drawerVisible);
        },
      },
      {
        iconName: "icon-quanping",
        newName: full ? "active" : "",
        children: [],
        onClick: () => {
          setFullscreen();
          const newFull = isFullscreen();

          this.setState({
            full: !newFull,
          });
        },
      },
    ];

    return (
      <>
        {/* <div className={"pt-"}></div> */}
        <div className={"pt-sidebar"}>
          <div className={"pt-sidebar--left"}></div>
          <div className={"pt-sidebar--right"}>
            <span>
              <Time />
              {name[headSuffixKey]} 今天(周{arr[new Date().getDay()]})
            </span>
            <Divider type="vertical" />
            <div className={"button-box"}>
              {button.map((item, i) => {
                return item.children.length > 0 ? (
                  <div key={i}>{i}</div>
                ) : (
                  <div
                    key={i}
                    className={`button-box__child ${item.newName}`}
                    onClick={item.onClick}
                  >
                    <PtIcon className={`${item.iconName}`} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state) => ({
  headSuffixKey: state.getIn(["header", "suffixKey"]),
  drawerVisible: state.getIn(["home", "drawerVisible"]),
  homeMaskVisible: state.getIn(["home", "maskVisible"]),
});

const mapDispatch = (dispatch) => ({
  changeVisible(value) {
    dispatch(homeActionCreators.setDrawerVisible(value));
  },
  changeHomeMaskVisible(value) {
    dispatch(homeActionCreators.setMaskVisible(value));
  },
});

export default connect(mapState, mapDispatch)(SideBar);

const Time = ({ format = "YYYY年MM月DD日 HH:mm:ss", step = "s", ...rest }) => {
  const [time, setTime] = useState(moment().format(format));
  const _step = {
    d: 24 * 60 * 60 * 1000,
    h: 60 * 60 * 1000,
    m: 60 * 1000,
    s: 1000,
  }[step];
  useEffect(() => {
    setInterval(() => setTime(moment().format(format)), _step || 1000);
  }, []);
  return <span {...rest}>{time}</span>;
};
