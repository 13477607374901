import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PtCard, PtDrawer } from "../../../../components";
import CloseButton from "../general/colseButton";
import GeneralModal from "../general/generalModal";
import GreeningExcel1 from "./components/greeningExcel1";
import GreeningExcel2 from "./components/greeningExcel2";
import Monitoring from "./components/monitoring";
import MonitoringExcel from "./components/monitoringExcel";
import NetworkExcel1 from "./components/networkExcel1";
import NetworkExcel2 from "./components/networkExcel2";
import OperationsLeft from "./components/operationsLeft";
import OperationsRight from "./components/operationsRight";
import PumpStationExcel1 from "./components/pumpStationExcel1";
import PumpStationExcel2 from "./components/pumpStationExcel2";
import PumpStationExcel3 from "./components/pumpStationExcel3";
import RiverExcel1 from "./components/riverExcel1";
import RiverExcel2 from "./components/riverExcel2";
import RiverExcel3 from "./components/riverExcel3";
import RiverExcel4 from "./components/riverExcel4";
import RiverExcel5 from "./components/riverExcel5";
import RiverExcel6 from "./components/riverExcel6";
import StationsExcel1 from "./components/stationsExcel1";
import StationsExcel2 from "./components/stationsExcel2";
import axios from "axios";
import "./index.scss";
import { DistanceDisplayCondition } from "cesium";

const newAxios = axios.create({
  baseURL: "https://app.cthd.cc/public",
});

/**
 * @description         运维管理
 */

const Operations = () => {
  //两侧内容显示状态
  const drawerVisible = useSelector((value: any) =>
    value.getIn(["home", "drawerVisible"])
  );

  /**
   * @description       左侧选中数据
   */

  const [leftActiveData, setLeftActiveData] = useState({});

  const [leftData, setLeftData] = useState({});

  /**
   * @description       左侧选中数据的类型
   */

  const [leftType, setLeftType] = useState("");

  const [leftTabValue, setLeftTabValue] = useState("01");

  /**
   * @description       右侧选中数据
   */

  const [rightActiveData, setRightActiveData] = useState({} as any);

  /**
   * @description       右侧数据类型
   */

  const [rightType, setRightType] = useState(1);

  const [entityId, setEntityId] = useState(null);

  /**
   * @description       弹窗内容
   */

  const excelContent = {
    泵站: {
      1: (
        <PumpStationExcel1
          leftData={leftActiveData}
          rightData={rightActiveData}
        />
      ),
      2: (
        <PumpStationExcel2
          leftData={leftActiveData}
          dataSource={rightActiveData}
        />
      ),
      3: (
        <PumpStationExcel3
          leftData={leftActiveData}
          dataSource={rightActiveData}
        />
      ),
    },
    场站: {
      1: (
        <StationsExcel1 leftData={leftActiveData} rightData={rightActiveData} />
      ),
      2: (
        <StationsExcel2
          leftData={leftActiveData}
          dataSource={{
            title: (leftActiveData as any).title,
            time: (rightActiveData as any).title,
          }}
        />
      ),
      3: <></>,
    },
    管井: {
      1: <NetworkExcel1 rightData={rightActiveData} />,
      2: <NetworkExcel2 />,
    },
    绿化: {
      1: (
        <GreeningExcel1
          dataSource={{
            title: (leftActiveData as any).title,
            time: (rightActiveData as any).title,
          }}
        />
      ),
      2: (
        <GreeningExcel2
          dataSource={{
            title: (leftActiveData as any).title,
            time: (rightActiveData as any).title,
          }}
        />
      ),
    },
    河道: {
      1: (
        <RiverExcel1
          dataSource={{
            title: (leftActiveData as any).title,
            time: (rightActiveData as any).title,
          }}
        />
      ),
      2: (
        <RiverExcel2
          dataSource={{
            title: (leftActiveData as any).title,
            time: (rightActiveData as any).title,
          }}
        />
      ),
      3: (
        <RiverExcel3
          dataSource={{
            title: (leftActiveData as any).title,
            time: (rightActiveData as any).title,
          }}
        />
      ),
      4: (
        <RiverExcel4
          dataSource={{
            title: (leftActiveData as any).title,
            time: (rightActiveData as any).title,
          }}
        />
      ),
      5: <RiverExcel5 />,
      6: <RiverExcel6 />,
    },
  };

  const getLocus = () => {
    const { entities, viewer } = window["CesMaps"];
    newAxios
      .get("/query/locus", {
        params: {
          name: rightActiveData.processorName,
          starttime: rightActiveData.patrolStartTime,
          endtime: rightActiveData.patrolEndTime,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let newList = [];
          if (res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              newList.push(item.x);
              newList.push(item.y);
            });
            window["CesMaps"].flyTo([
              res.data.data[0].x,
              res.data.data[0].y,
              500,
            ]);
            let entitie_ = new entities(viewer);
            entitie_.options({
              name: rightActiveData.name + "guiji",
              id: rightActiveData.id + "guiji",
              data: rightActiveData,
              type: "moren",
              polyline: {
                flow: 0,
                positions: newList,
                speed: 1000,
                flowColor: "#bbbbbb99",
                staticColor: "#000",
                lineWidth: 2,
                distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
              },
            });
            entitie_.add();

            setEntityId(viewer.entities.getById(rightActiveData.id + "guiji"));
          }
        }
      });
  };

  useEffect(() => {
    const { viewer } = window["CesMaps"];
    if (rightActiveData.processorName) {
      getLocus();
    } else {
      viewer.entities.remove(entityId);
    }
    return () => {
      viewer.entities.remove(entityId);
    };
  }, [rightActiveData]);

  return (
    <div className="operations_box">
      <PtDrawer
        visible={drawerVisible}
        left={
          <PtCard title={"运维管理"}>
            <OperationsLeft
              tabValue={leftTabValue}
              setTabValue={setLeftTabValue}
              activeData={leftActiveData}
              setActiveData={(value, type, data) => {
                setLeftActiveData(value);
                setLeftType(type);
                setLeftData(data);
                setRightActiveData({});
                setRightType(1);
              }}
            />
          </PtCard>
        }
        right={
          (leftActiveData as any).id ? (
            <PtCard
              title={
                (leftActiveData as any).title || (leftActiveData as any).name
              }
              suffix={
                <>
                  <CloseButton
                    onClick={() => {
                      setLeftActiveData({});
                      setLeftType("");
                      setLeftData({});
                      setRightActiveData({});
                      setRightType(1);
                    }}
                  />
                </>
              }
            >
              <OperationsRight
                leftType={leftType}
                leftData={leftData}
                leftTabValue={leftTabValue}
                leftActiveData={leftActiveData}
                activeData={rightActiveData}
                setActiveData={(value, type) => {
                  setRightActiveData(value);
                  setRightType(type);
                }}
              />
            </PtCard>
          ) : (
            <></>
          )
        }
      />
      {(rightActiveData as any).id ? (
        <GeneralModal
          title={(leftActiveData as any).title || (leftActiveData as any).name}
          downloadVisible={
            leftType === "监控" && rightType === 1 ? false : true
          }
          onClose={() => setRightActiveData({})}
        >
          <div className="show_time_box">
            时间：{(rightActiveData as any).createTime}
          </div>
          <div className="excel_box customScrollBars">
            {excelContent[leftType][rightType]}
          </div>
        </GeneralModal>
      ) : null}
    </div>
  );
};

export default Operations;
