import { PtIcon } from "../../../../components";

interface Props {
  style?: any;
  onClick: () => void;
}

/**
 * @description             关闭按钮
 */

const CloseButton = ({ style = {}, onClick }: Props) => {
  return (
    <div className="close_button" style={style} onClick={onClick}>
      <PtIcon className="icon-guanbi" />
    </div>
  );
};

export default CloseButton;
