import React, { Component } from 'react';
import { PtIcon } from '..';
import './index.scss'

interface Props {
    data: any[];
    columns?: any[];
    rowSelection?: {
        selectedRowKeys: any[];
        onChange?: (value) => void;
    }
}

interface State {

}

class PtTable extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { columns, data, rowSelection } = this.props
        return (
            <div className={"pt-table"}>
                <div className={"pt-table__head"}>
                    {
                        rowSelection ?
                            rowSelection.selectedRowKeys.length === data.length ?
                                <PtIcon
                                    className={"icon-gouxuan"}
                                    onClick={() => {
                                        rowSelection.onChange([])
                                    }}
                                /> :
                                <div
                                    className={"pt-table__check"}
                                    onClick={() => {
                                        let newArr = []
                                        data.map((item, i) => {
                                            newArr.push(item.id ? item.id : i)
                                        })
                                        rowSelection.onChange(newArr)
                                    }}
                                ></div>
                            : null
                    }
                    {
                        columns.map((item, i) => (
                            <div
                                key={i}
                                style={{
                                    width: rowSelection ?
                                        `calc(${100 / columns.length}% - ${37 / columns.length}px)`
                                        :
                                        `${100 / columns.length}%`
                                }}
                            >
                                {item.title}
                            </div>
                        ))
                    }
                </div>
                <div className={"pt-table__content"}>
                    {
                        data.map((item, i) => (
                            <div
                                key={i}
                                className="pt-table__content__line"
                            >
                                {
                                    rowSelection ?
                                        rowSelection.selectedRowKeys.includes(item.id ? item.id : i) ?
                                            <PtIcon className={"icon-gouxuan"}
                                                onClick={() => {
                                                    const newArr = [...rowSelection.selectedRowKeys]
                                                    let index = newArr.indexOf(item.id ? item.id : i)
                                                    newArr.splice(index, 1)
                                                    rowSelection.onChange(newArr)
                                                }}
                                            /> :
                                            <div className={"pt-table__check"}
                                                onClick={() => {
                                                    const newArr = [...rowSelection.selectedRowKeys]
                                                    newArr.push(item.id ? item.id : i)
                                                    rowSelection.onChange(newArr)
                                                }}
                                            ></div> : null
                                }
                                {
                                    columns.map((it, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: rowSelection ?
                                                    `calc(${100 / columns.length}% - ${37 / columns.length}px)`
                                                    :
                                                    `${100 / columns.length}%`
                                            }}
                                        >
                                            {item[it.key]}
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default PtTable;