import React from "react";
import "./index.scss";

interface Props {
  type: string;
  data: any;
  index: string;
}

interface States {}

export default class HPop extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, index, type } = this.props;
    return (
      <div className={`statePopup ${type}`} id={`${index}`}>
        <div className={"box"}>
          <span className={"title"}>
            {data.areaName}-{type === "sStatePopup" ? "场站" : "泵站"}
          </span>
          <span className={"number"}>{data.number}</span>
        </div>
        <div>
          <div className={"box"}>
            <div className={"title"}>
              <div className={"tag"} style={{ backgroundColor: `#02D281` }} />
              运行
            </div>
            <span className={"number"}>{data.olineCount}</span>
          </div>
        </div>
        <div>
          <div className={"box"}>
            <div className={"title"}>
              <div className={"tag"} style={{ backgroundColor: `#F76600` }} />
              告警
            </div>
            <span className={"number"}>{data.waringCount}</span>
          </div>
        </div>
        <div>
          <div className={"box"}>
            <div className={"title"}>
              <div className={"tag"} style={{ backgroundColor: `#FFFFFF` }} />
              离线
            </div>
            <span className={"number"}>{data.offlineCount}</span>
          </div>
        </div>
      </div>
    );
  }
}
