/*
 * @Author: your name
 * @Date: 2024-09-04 16:08:13
 * @LastEditors: Chen
 * @LastEditTime: 2024-09-11 16:26:22
 * @Description: In User Settings Edit
 * @FilePath: \smurfs-react\src\skin\mainSkin\components\processInfo.tsx
 */
import { DatePicker, Radio, Select } from "antd"
import { PtCard, PtTab } from "../../../components"
import { useEffect, useState } from "react"
import "./../index.scss"
import axios from "axios"
import { Axis, Chart, Interval, Tooltip } from "bizcharts"
import moment from "moment"

const typeList: any = [
	{
		label: "场站",
		value: 7
	},
	{
		label: "泵站",
		value: 6
	},
	{
		label: "负压",
		value: 14
	}
]

const detailsTimeTab = [
	{
		value: 1,
		text: "日"
	},
	{
		value: 2,
		text: "月"
	},
	{
		value: 3,
		text: "年"
	}
]
const detailsTimePicker = {
	1: "date",
	2: "month",
	3: "year"
}

const ProcessInfo = () => {
	const [type, setType] = useState(6)
	const [dateType, setDateType] = useState<any>(1)
	const [dateValue, setDateValue] = useState<any>(moment())
	const [date, setDate] = useState<any>(moment().format('YYYY-MM-DD'))
	const [chartData, setData] = useState([])

	useEffect(() => {
		getList()
	}, [type, dateType, date])

	const getList = () => {
		if (!date) return
		axios
			.get(`/monitor_station_process/statics/town`, { params: { deviceType: type, scopeType: dateType, startTime: date } })
			.then((res: any) => {
				if (res.code === 200) {
					const data = res.data.map(item =>{
						return {
							...item,
							name: item.name.replace('镇' , '')
						}
					})
					setData(data)
				}
			})
	}
	const changeDate = (value: string) => {
		setDateType(value)
		setDateValue("")
		setDate("")
	}

	const changeDateValue = (value: any, dateString: string) => {
		console.log(dateString)
		setDateValue(value)
		setDate(dateString)
	}

	return (
		<PtCard
			title={"设备处理信息"}
			entitle={"Device processing information"}
			suffix={
				<>
					<div></div>
					<div className="select_box">
						<Select
							size="small"
							bordered={false}
							options={typeList}
							getPopupContainer={(triggerNode) => triggerNode}
							value={type}
							onChange={(value) => setType(value)}
						/>
					</div>
				</>
			}
		>
			<div className="date_type">
				<PtTab data={detailsTimeTab} value={dateType} onChange={changeDate} auto={false} />

				<div className="date_range">
					<DatePicker
						allowClear={false}
						value={dateValue}
						picker={detailsTimePicker[dateType]}
						size={"small"}
						onChange={changeDateValue}
					/>
				</div>
			</div>
			<Chart
				width={"100%"}
				height={100}
				autoFit
				data={chartData}
				interactions={["active-region"]}
				padding={[10, 10, 20, 10]}
				pure={true}
			>
				<Interval position="name*process" color={"#02d281"} size={5} />
				<Axis
					name="name"
					label={{
						style: {
							fill: "#ffffff",
							fontSize: 14,
							fontWeight: "bold",
							textBaseline: "top"
						},
						autoHide: false
					}}
					title={{ text: "村" }}
				/>
				<Axis name="process" visible={false} />
				<Tooltip>
					{(title, items) => {
						return (
							<div style={{ padding: "8px 0", minWidth: "120px" }}>
								<div
									style={{
										fontSize: "14px",
										marginBottom: "12px"
									}}
								>
									{title}
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center"
									}}
								>
									<div style={{ fontSize: "14px", width: "60px" }}>
										{type === 7 ? "处理量" : "收集量"}
									</div>
									<span style={{ fontSize: "14px" }}>
										{items[0].value}
										{/* <span style={{ fontSize: "12px", color: "rgba(0,0,0,0.8)" }}>m³</span> */}
									</span>
								</div>
							</div>
						)
					}}
				</Tooltip>
			</Chart>
		</PtCard>
	)
}

export default ProcessInfo
