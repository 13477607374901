/**
 * @description         图例内容1
 */
export const legendList1 = [
  {
    name: "泵站",
    icon: require("../assets/icon/legendImage1.svg").default,
  },
  {
    name: "场站",
    icon: require("../assets/icon/legendImage2.svg").default,
  },
  {
    name: "主管",
    icon: require("../assets/icon/legendImage3.svg").default,
  },
  {
    name: "接户管",
    icon: require("../assets/icon/legendImage4.svg").default,
  },
  {
    name: "管井",
    icon: require("../assets/icon/legendImage5.svg").default,
  },
  {
    name: "已接户",
    icon: require("../assets/icon/legendImage6.svg").default,
  },
  {
    name: "化粪池",
    icon: require("../assets/icon/legendImage7.svg").default,
  },
  {
    name: "截流井",
    icon: require("../assets/icon/legendImage8.svg").default,
  },
];
/**
 * @description         图例内容2
 */
export const legendList2 = [
  {
    name: "泵站",
    icon: require("../assets/icon/legendImage9.svg").default,
  },
  {
    name: "场站",
    icon: require("../assets/icon/legendImage10.svg").default,
  },
  {
    name: "监测设施",
    icon: require("../assets/icon/legendImage11.svg").default,
  },
  {
    name: "管理站点",
    icon: require("../assets/icon/legendImage12.svg").default,
  },
  {
    name: "污水主管",
    icon: require("../assets/icon/legendImage13.svg").default,
  },
];
/**
 * @description         图例内容3
 */
export const legendList3 = [
  {
    name: "离线",
    icon: require("../assets/icon/offline.svg").default,
  },
  {
    name: "告警",
    icon: require("../assets/icon/alarm.svg").default,
  },
];

/**
 * @description           私人泵
 */

export const personalPoint = [
  {
    id: 1,
    name: "笏立鞋业",
    position: [119.081256851862, 25.3410960100022],
  },
  {
    id: 2,
    name: "笏立鞋业",
    position: [119.081359291408, 25.3408258682383],
  },
  {
    id: 3,
    name: "荔源鞋业",
    position: [119.084288364636, 25.3366496095199],
  },
];
