export const typeList = [
  // {
  //     text: "管线设施",
  //     value: 3
  // },
  // {
  //     text: "泵站设备",
  //     value: 2
  // },
  // {
  //     text: "场站设备",
  //     value: 1
  // },
  {
    text: "设备",
    value: 1,
  },
  {
    text: "管井",
    value: 2,
  },
];

export const pipeLineContent = [
  {
    title: "管线代码",
    value: "N/A",
  },
  {
    title: "起点点号",
    value: "N/A",
  },
  {
    title: "终点点号",
    value: "N/A",
  },
  {
    title: "起点高程",
    value: "N/A",
  },
  {
    title: "终点高程",
    value: "N/A",
  },
  {
    title: "起点埋深",
    value: "N/A",
  },
  {
    title: "终点埋深",
    value: "N/A",
  },
  {
    title: "埋设类型",
    value: "N/A",
  },
  {
    title: "材质",
    value: "N/A",
  },
  {
    title: "管径",
    value: "N/A",
  },
  {
    title: "电压值",
    value: "N/A",
  },
  {
    title: "压力",
    value: "N/A",
  },
  {
    title: "流向",
    value: "N/A",
  },
  {
    title: "总孔数",
    value: "N/A",
  },
  {
    title: "占用孔数",
    value: "N/A",
  },
  {
    title: "电缆条数",
    value: "N/A",
  },
  {
    title: "保护材质",
    value: "N/A",
  },
  {
    title: "线型",
    value: "N/A",
  },
  {
    title: "所在道路",
    value: "N/A",
  },
  {
    title: "权属单位",
    value: "N/A",
  },
  {
    title: "建设年代",
    value: "N/A",
  },
  {
    title: "测量单位",
    value: "N/A",
  },
  {
    title: "测量日期",
    value: "N/A",
  },
  {
    title: "使用状态",
    value: "N/A",
  },
  {
    title: "备注",
    value: "N/A",
  },
]