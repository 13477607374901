import { CSSProperties, useEffect, useState } from "react";
import { PtIcon } from "../../../components";
import "./index.scss";

interface Props {
  dataSource: any[];
  activeData: any;
  style?: CSSProperties;
  onClick: (data: any) => void;
}

const VillageTable = ({ dataSource, activeData, style, onClick }: Props) => {
  return (
    <div className="village-table" style={style}>
      {dataSource.map((item, i) => {
        return (
          <TableContent
            key={i}
            dataSource={item}
            activeData={activeData}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};

export default VillageTable;

const TableContent = ({ dataSource, activeData, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name, dataList } = dataSource;

  useEffect(() => {
    setIsOpen(false);
  }, [dataSource]);
  useEffect(() => {
    if (activeData.id) {
      try {
        dataList.forEach((element) => {
          if (
            (element.id ? element.id : element.correlationId) === activeData.id
          ) {
            setIsOpen(true);
          }
        });
      } catch (error) {}
    }
  }, [activeData, dataList]);
  return (
    <div className="table_content">
      <div className="content_title" onClick={() => setIsOpen(!isOpen)}>
        <div className="title_left">
          {name} <span>({dataList ? dataList.length : 0})</span>
        </div>
        <PtIcon
          className={`${isOpen ? "icon-shousuoicon" : "icon-zhankaiicon"}`}
        />
      </div>
      {isOpen && (
        <div className={`content_child_list`}>
          {dataList &&
            dataList.map((item, i) => (
              <div
                key={i}
                className={`content_child ${
                  (item.id ? item.id : item.correlationId) === activeData.id &&
                  "active"
                }_${item.state === 1 ? "normal" : "abnormal"}`}
                onClick={() => onClick(item)}
              >
                <div className="child_title">
                  {item.serial || item.name || item.title}
                </div>
                <div
                  className={`child_state ${
                    item.state === 1 ? "normal" : "abnormal"
                  } `}
                >
                  {item.state === 1 ? "正常" : "离线"}
                  
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
