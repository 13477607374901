/*
 * @Author: your name
 * @Date: 2024-06-04 20:47:32
 * @LastEditors: Chen
 * @LastEditTime: 2024-08-02 15:32:51
 * @Description: In User Settings Edit
 * @FilePath: \smurfs-react\src\skin\moduleSkin\components\operations\components\operationsLeft.tsx
 */
import axios from "axios";
import { useState, useEffect } from "react";
import { PtChildTitle, PtTab } from "../../../../../components";
import { operationsLeftTab } from "../../../data";
import GeneralCollapse from "../../general/generalCollapse";

interface Props {
  tabValue: string;
  activeData: any;
  setTabValue: (value) => void;
  setActiveData: (value, type, data) => void;
}

const OperationsLeft = ({
  tabValue,
  activeData,
  setTabValue,
  setActiveData,
}: Props) => {
  /**
   * @description       数据
   */

  const [dataSource, setDataSource] = useState([]);

  const getData = () => {
    console.log(tabValue);
    
    axios
      .get(`/work_order/left_menu/${tabValue}`)
      .then((res: any) => {
        if (res.code === 200) {
          console.log(res);
          const {data} = res
          
          if(data.list) {
            data.list.forEach(item =>{
              item.id = item.areaId
              item.total = item.children.length
              item.list = item.children
              item.type = tabValue === '01' ? '泵站' : tabValue === '02' ? '绿化' : '河道' 
              item.sysId = item.areaId
              item.children = item.children.forEach(child =>{
                child.id = child.areaId
                child.sysId = child.areaId
                child.state = 1
                child.type = item.title
              })
            })            
            setDataSource(data.list)
          }
        }
      });
  };


  useEffect(() => {
    setActiveData({}, "", {});
    getData();
  }, [tabValue]);

  return (
    <div className="operations_left">
      <PtChildTitle
        title="设施清单"
        rightContent={
          <PtTab
            data={operationsLeftTab}
            value={tabValue}
            onChange={setTabValue}
          />
        }
      />
      {/* <PtSearch onChange={(value) => {}} /> */}
      <div className="collapse_box customScrollBars">
        {dataSource.map((item, i) => {
          return (
            <GeneralCollapse
              key={i}
              dataSource={item}
              activeData={activeData}
              rightVisible={false}
              onClick={(value, type, data) => setActiveData(value, type, data)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OperationsLeft;
