import { useState } from "react";
import { useSelector } from "react-redux";
import { PtCard, PtDrawer } from "../../../../components";
import CloseButton from "../general/colseButton";
import ExpertsLeft from "./components/expertsLeft";
import ExpertsRight from "./components/expertsRight";

import "./index.scss";

/**
 * @description           专家库
 */

const ExpertsBody = () => {
  //两侧内容显示状态
  const drawerVisible = useSelector((value: any) =>
    value.getIn(["home", "drawerVisible"])
  );

  const [activeData, setActiveData] = useState({});

  return (
    <div>
      <PtDrawer
        visible={drawerVisible}
        left={
          <PtCard title={"专家库"}>
            <ExpertsLeft
              activeData={activeData}
              setActiveData={setActiveData}
            />
          </PtCard>
        }
        right={
          (activeData as any).id ? (
            <PtCard
              title={"专家信息"}
              suffix={<CloseButton onClick={() => setActiveData({})} />}
            >
              <ExpertsRight activeData={activeData} />
            </PtCard>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default ExpertsBody;
