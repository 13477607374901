import axios from "axios";
import { useEffect, useState } from "react";
import { PtChildTitle, PtSearch } from "../../../../../components";
import { ExpertsColumns, ExpertsDataSource } from "../../../data";
import GeneralTable from "../../general/generalTable";

interface Props {
  activeData: any;
  setActiveData: (value) => void;
}

const ExpertsLeft = ({ activeData, setActiveData }: Props) => {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getDataSource("");
  }, []);
  /**
   * @description         获取列表数据
   * @param name          关键词
   */

  const getDataSource = (name: string) => {
    // axios
    //   .get(`/user/get_export?name=${name}`)
    //   .then((res: any) => {
    //     if (res.code === 200) {
    //       setDataSource(res.data);
    //       setActiveData({});
    //     }
    //   });
  };

  /**
   * @description         搜索
   * @param value         关键词
   */

  const search = (value: string) => {
    getDataSource(value);
  };

  return (
    <div>
      <PtChildTitle title={"专家列表"} />
      {/* <PtSearch onChange={search} /> */}
      <GeneralTable
        columns={ExpertsColumns}
        dataSource={ExpertsDataSource}
        activeData={activeData}
        lineClick={(value) => {
          setActiveData(value.id === activeData.id ? {} : value);
        }}
      />
    </div>
  );
};

export default ExpertsLeft;
