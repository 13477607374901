import { Button, Form, Input, Modal, message } from "antd";
import axios from "axios";
import "./index.scss";
import { useEffect, useState } from "react";

/**
 * @description       登录页面
 */

const Login = () => {
  const [isPhone, setIsPhone] = useState(false);

  const [phoneData, setPhoneData] = useState({
    code: "",
    phone: "",
    imgCode: "",
  });

  const [codeModalVisible, setCodeModalVisible] = useState(false);

  const [codeImg, setCodeImg] = useState("");

  /**
   * @description       登录
   */
  const signIn = (value) => {
    const { account, password } = value;
    axios
      .post("/user/login", {
        account,
        password: encryptionPassword(password),
      })
      .then((res: any) => {
        if (res.code === 200) {
          const { data } = res;
          message.success("登录成功");
          localStorage.setItem("access_token", data.access_token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `bearer ${data.access_token}`;
          window.location.href = "/home";
        } else {
          message.error(res.msg);
        }
      });
  };

  const getImageCode = async () => {
    if (phoneData.phone) {
      let res: any = await axios.get(
        `/user/sms_login/get_image/${phoneData.phone}`
      );
      if (res.code === 200) {
        setCodeImg(res.data);
        setCodeModalVisible(true);
      } else {
        message.error("用户不存在");
      }
    } else {
      message.warning("请输入电话号码");
    }
  };

  const sendPhoneCode = async () => {
    const res: any = await axios.get(
      `/user/sms_login/get_sms/${phoneData.phone}/${phoneData.imgCode}`
    );
    if (res.code === 200) {
      message.success("验证码已发送");
      setPhoneData({ ...phoneData, imgCode: "" });
      setCodeModalVisible(false);
    } else {
      message.error(res.msg);
    }
  };

  const loginWithPhone = async () => {
    const res: any = await axios.post(
      `/user/sms_login/login/${phoneData.phone}/${phoneData.code}`
    );

    if (res.code === 200) {
      const { data } = res;
      message.success("登录成功");
      localStorage.setItem("access_token", data.access_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `bearer ${data.access_token}`;
      window.location.href = "/home";
    } else {
      message.error(res.msg);
    }
  };

  /**
   * @description     加密
   */
  const encryptionPassword = (value: string) => {
    return window.btoa(value);
  };

  return (
    <div className="login_box">
      <div className="form_box">
        {!isPhone ? (
          <Form onFinish={signIn}>
            <Form.Item
              name="account"
              rules={[{ required: true, message: "请输入账号" }]}
            >
              <Input placeholder="账号" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password placeholder="密码" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                登 录
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <>
            <Modal
              title={"请输入以下图形验证码"}
              visible={codeModalVisible}
              width={360}
              onOk={sendPhoneCode}
              onCancel={() => {
                setCodeModalVisible(false);
                setPhoneData({ ...phoneData, imgCode: "" });
              }}
              okText={"提交"}
              cancelText={"取消"}
              getContainer={false}
            >
              <div className="modal_input">
                <Input
                  value={phoneData.imgCode}
                  onChange={(e) =>
                    setPhoneData({ ...phoneData, imgCode: e.target.value })
                  }
                />
                <img src={codeImg} alt="" width={100} height={32} />
              </div>
            </Modal>
            <div className="phone_box">
              <div className="input_box">
                <Input
                  bordered={false}
                  placeholder="请输入电话号码"
                  value={phoneData.phone}
                  onChange={(e) => {
                    setPhoneData({ ...phoneData, phone: e.target.value });
                  }}
                />
                <div className="send_button" onClick={getImageCode}>
                  发送
                </div>
              </div>

              <div className="input_box">
                <Input
                  bordered={false}
                  placeholder="请输入手机验证码"
                  value={phoneData.code}
                  onChange={(e) => {
                    setPhoneData({ ...phoneData, code: e.target.value });
                  }}
                />
              </div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                onClick={loginWithPhone}
              >
                登 录
              </Button>
            </div>
          </>
        )}

        <div className="switch_button" onClick={() => setIsPhone(!isPhone)}>
          {!isPhone ? "手机验证码登陆" : "账号密码登录"}
        </div>
      </div>
    </div>
  );
};

export default Login;
