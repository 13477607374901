import { legendList1, legendList3 } from "../../../../page/data";

interface Props {
  headAreaName: string;
  legendShow: any;
  statisticalData: any;
  onClick: (index: number) => void;
}

const VillageLegend = ({
  headAreaName,
  legendShow,
  statisticalData,
  onClick,
}: Props) => {
  return (
    <div className={`legend-box village`}>
      <div>
        <span className="village-title">当前区域:</span>
        <span style={{ marginLeft: "10px" }}>{headAreaName}</span>
      </div>
      <div className="village-top">
        <div className="top-l">
          <span className="village-title">图例:</span>
          <div className="list-content">
            {legendList1.map((item, i) => (
              <div
                key={i}
                className={`legend-content ${legendShow[i] && "legend_show"}`}
                onClick={() => onClick(i)}
              >
                <img src={item.icon} alt="" />
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="top-r">
          {legendList3.map((item, i) => (
            <div key={i} className={"legend-content"}>
              <img src={item.icon} alt="" />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="village-bottom">
        <span className="village-title">统计:</span>
        <div className="list-content">
          {statisticalData.map((item, i) => (
            <div className="statistical-content" key={i}>
              <div>
                {item.title} <span>({item.unit})</span>
              </div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VillageLegend;
