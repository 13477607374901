import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PtCard, PtDrawer } from "../../../../components";
import CloseButton from "../general/colseButton";
import Emergency2Left from "./components/emergency2left";
import Emergency2Right from "./components/emergency2right";

import "./index.scss";

interface Props {}

/**
 * @description           应急人员
 */

const Emergency2 = ({}: Props) => {
  //两侧内容显示状态
  const drawerVisible = useSelector((value: any) =>
    value.getIn(["home", "drawerVisible"])
  );
  const [activeData, setActiveData] = useState({});

  return (
    <div>
      <PtDrawer
        visible={drawerVisible}
        left={
          <PtCard title={"应急人员"}>
            <Emergency2Left
              activeData={activeData}
              setActiveData={setActiveData}
            />
          </PtCard>
        }
        right={
          (activeData as any).id ? (
            <PtCard
              title={"人员信息"}
              suffix={<CloseButton onClick={() => setActiveData({})} />}
            >
              <Emergency2Right activeData={activeData} />
            </PtCard>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default Emergency2;
