/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from "react";
import { useSelector } from "react-redux";
import { PtCard, PtDrawer } from "../../../../components";
import GeneralModal from "../general/generalModal";
import Emergency1Left from "./components/emergency1left";
import "./index.scss";

/**
 * @description           应急预案
 */

const Emergency1 = () => {
  //两侧内容显示状态
  const drawerVisible = useSelector((value: any) =>
    value.getIn(["home", "drawerVisible"])
  );
  const [activeData, setActiveData] = useState({});

  /**
   * @description       文件下载
   */

  const documentDownload = () => {
    const download = document.createElement("a");
    download.href = (activeData as any).url;
    download.click();
  };

  return (
    <div className="emergency1_box">
      <PtDrawer
        visible={drawerVisible}
        left={
          <PtCard title={"应急预案"}>
            <Emergency1Left
              activeData={activeData}
              setActiveData={setActiveData}
            />
          </PtCard>
        }
        right={<></>}
      />
      {(activeData as any).id && (
        <GeneralModal
          title={(activeData as any).title}
          onDownload={documentDownload}
          onClose={() => setActiveData({})}
        >
          <div className="iframe_box">
            <iframe
              src={`https://pt.cthd.cc/pdf2/web/viewer.html?file=${
                (activeData as any).pdfUrl
              }`}
            />
          </div>
        </GeneralModal>
      )}
    </div>
  );
};

export default Emergency1;
