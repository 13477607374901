import { useEffect, useState } from "react";
import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle6 from "../../general/excelStyle6";
import { NetworkExcel1Data } from "../data";
import axios from "axios";
import ExcelStyle3 from "../../general/excelStyle3";

interface Props {
  rightData: any;
}

/**
 * @description                 管网巡检记录
 */

const NetworkExcel1 = ({ rightData }: Props) => {
  const [data, setData] = useState({} as any);
  useEffect(() => {
    if (rightData.sysId) {
      axios
        .get(`/work_order/forward_app_web/worker_order/worker_order/detail`, {
          params: { sysid: rightData.sysId },
        })
        .then((res: any) => {
          if (res.code === 200) {
            setData(res.data.data);
          }
        });
    } else {
      const newData = {
        area_code_txt: "秀屿区苏塘村",
        createtime: rightData.createTime,
        patrol_format_info_v3: NetworkExcel1Data,
      };
      setData(newData);
    }
  }, [rightData.sysId]);

  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "维护区域",
              value: data.area_code_txt,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "维护人员",
              value: "",
            },
            {
              title: "维护日期",
              value: data.createtime,
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "维护项目", span: 3 },
            { title: "设备名称", span: 3 },
            { title: "维护内容", span: 5 },
            { title: "维护情况", span: 5 },
            { title: "处理结果", span: 5 },
            { title: "备注", span: 3 },
          ]}
        />
        <ExcelStyle3
          columns={[
            { title: "设备名称", span: "114px", dataIndex: "patrol_name" },
            { title: "维护内容", span: "190px", dataIndex: "detail" },
            { title: "维护情况", span: "189px", dataIndex: "status_txt" },
            { title: "处理结果", span: "190px", dataIndex: "results" },
            { title: "备注", span: "114px", dataIndex: "remark" },
          ]}
          dataSource={
            data.patrol_format_info_v3 ? data.patrol_format_info_v3 : []
          }
        />
      </div>
    </>
  );
};

export default NetworkExcel1;
