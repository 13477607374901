import { Col, Row } from "antd";

interface Props {
  dataSource: any[];
}

const ExcelStyle1 = ({ dataSource }: Props) => {
  const titleSpan = {
    1: 3,
    2: 6,
    3: 9,
  };

  const textSpan = {
    1: 21,
    2: 18,
    3: 15,
  };

  return (
    <Row>
      {dataSource.map((item, i) => (
        <Col span={24 / dataSource.length} key={i}>
          <Row>
            <Col span={titleSpan[dataSource.length]}>
              <div className="excel_title">{item.title}</div>
            </Col>
            <Col span={textSpan[dataSource.length]}>
              <div className="excel_text">{item.value}</div>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default ExcelStyle1;
