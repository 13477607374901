interface Props {
  title: string;
  children: any;
}

/**
 * @description                  通用标题盒子
 */

const GeneralTitle = ({ title, children }: Props) => {
  return (
    <div className="general_title">
      <div className="title_content">{title}</div>
      <div className="list_box customScrollBars">{children}</div>
    </div>
  );
};

export default GeneralTitle;
