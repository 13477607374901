import ExcelStyle1 from "../../general/excelStyle1";
import ExcelStyle2 from "../../general/excelStyle2";
import ExcelStyle3 from "../../general/excelStyle3";
import { PumpStationExcel2Data } from "../data";

interface Props {
  leftData: any;
  dataSource: any;
}

/**
 * @description         泵站维修记录
 */

const PumpStationExcel2 = ({ leftData, dataSource }: Props) => {
  return (
    <>
      <div className="excel_content">
        <ExcelStyle1
          dataSource={[
            {
              title: "维护人员",
              value: leftData.id === "1" ? "刘昌武" : "",
            },
            {
              title: "巡查日期",
              value: dataSource.createTime,
            },
          ]}
        />
        <ExcelStyle1
          dataSource={[
            {
              title: "型号参数",
              value: "***",
            },
          ]}
        />
      </div>
      <div className="excel_content">
        <ExcelStyle2
          dataSource={[
            { title: "维护项目", span: 3 },
            { title: "设备名称", span: 3 },
            { title: "维护内容", span: 6 },
            { title: "维护情况", span: 3 },
            { title: "异常故障处理情况", span: 6 },
            { title: "备注", span: 3 },
          ]}
        />
        <ExcelStyle3
          columns={[
            { title: "设备名称", span: "114px", dataIndex: "name" },
            { title: "维护内容", span: "228px", dataIndex: "details" },
            { title: "维护情况", span: "113px", dataIndex: "state" },
            { title: "异常故障处理情况", span: "228px", dataIndex: "results" },
            { title: "备注", span: "114px", dataIndex: "remark" },
          ]}
          dataSource={leftData.id === "1" ? PumpStationExcel2Data : []}
        />
      </div>
    </>
  );
};

export default PumpStationExcel2;
